import React, { useEffect, useState } from "react";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import Crop from "../../shared/crop";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import ErrorText from "../../shared/error";

const initialValue = {
  id: "",
  name: "",
  email: "",
  city: "",
  pincode: "",
  country: "",
  state: "",
  gst_no: "",
  iec_code: "",
  address_one: "",
  address_two: "",
  contact_person: "",
  contact_no: "",
  logo: null,
  signature: null,
};

const Profile = () => {
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [signaturePreview, setSignaturePreview] = useState("");
  const [formValue, setFormValue] = useState(initialValue);

  const onEdit = () => {
    axios.post(API_URL.LOGIN_USER, {}).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          city: res.data.city,
          pincode: res.data.pincode,
          country: res.data.country,
          state: res.data.state,
          gst_no: res.data.gst_no,
          iec_code: res.data.iec_code,
          address_one: res.data.address_one,
          address_two: res.data.address_two,
          contact_person: res.data.contact_person,
          contact_no: res.data.contact_no,
          logo: res.data.logo,
          signature: res.data.signature,
        });
      }
    });
  };

  const onSave = () => {
    setSubmitting(true);
    setError(false);
    if (
      formValue.name ||
      (formValue.email &&
        formValue.city &&
        formValue.pincode &&
        formValue.country &&
        formValue.state &&
        formValue.gst_no &&
        formValue.iec_code &&
        formValue.address_one &&
        formValue.contact_person &&
        formValue.contact_no)
    ) {
      const pUrl = API_URL.UPDATE_USER;
      let body = { ...formValue };
      if (!imagePreview) {
        delete body.logo;
      }
      if (!signaturePreview) {
        delete body.signature;
      }
      axios.post(pUrl, body).then(
        (res) => {
          if (res.data.status === 1) {
            toast.success("Profile Updated Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          } else {
            toast.error(res.data.data, {
              autoClose: 3000,
              position: "top-right",
            });
          }
          setError(false);
          setSubmitting(false);
        },
        (error) => {
          toast.error(error.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
          setError(false);
          setSubmitting(false);
        }
      );
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    onEdit();
  }, []);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>Profile</h5>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name">
                      Name<span className="required-text">*</span>
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formValue.name}
                      className={`form-control ${
                        isError && formValue.name === "" && "error"
                      }`}
                      onChange={(e) => setFormData("name", e.target.value)}
                    />
                    {isError && formValue.name === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="email">
                      Email<span className="required-text">*</span>
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      value={formValue.email}
                      className={`form-control ${
                        isError && formValue.email === "" && "error"
                      }`}
                      onChange={(e) => setFormData("email", e.target.value)}
                    />
                    {isError && formValue.email === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="city">
                      City<span className="required-text">*</span>
                    </label>
                    <input
                      id="city"
                      name="city"
                      type="text"
                      value={formValue.city}
                      className={`form-control ${
                        isError && formValue.city === "" && "error"
                      }`}
                      onChange={(e) => setFormData("city", e.target.value)}
                    />
                    {isError && formValue.city === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="state">
                      State<span className="required-text">*</span>
                    </label>
                    <input
                      id="state"
                      name="state"
                      type="text"
                      value={formValue.state}
                      className={`form-control ${
                        isError && formValue.state === "" && "error"
                      }`}
                      onChange={(e) => setFormData("state", e.target.value)}
                    />
                    {isError && formValue.state === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="country">
                      Country<span className="required-text">*</span>
                    </label>
                    <input
                      id="country"
                      name="country"
                      type="text"
                      value={formValue.country}
                      className={`form-control ${
                        isError && formValue.country === "" && "error"
                      }`}
                      onChange={(e) => setFormData("country", e.target.value)}
                    />
                    {isError && formValue.country === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="pincode">
                      Pin Code<span className="required-text">*</span>
                    </label>
                    <input
                      id="pincode"
                      name="pincode"
                      type="text"
                      value={formValue.pincode}
                      className={`form-control ${
                        isError && formValue.pincode === "" && "error"
                      }`}
                      onChange={(e) => setFormData("pincode", e.target.value)}
                    />
                    {isError && formValue.pincode === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="gst_no">
                      GST No.<span className="required-text">*</span>
                    </label>
                    <input
                      id="gst_no"
                      name="gst_no"
                      type="text"
                      value={formValue.gst_no}
                      className={`form-control ${
                        isError && formValue.gst_no === "" && "error"
                      }`}
                      onChange={(e) => setFormData("gst_no", e.target.value)}
                    />
                    {isError && formValue.gst_no === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="iec_code">
                      IEC Code<span className="required-text">*</span>
                    </label>
                    <input
                      id="iec_code"
                      name="iec_code"
                      type="text"
                      value={formValue.iec_code}
                      className={`form-control ${
                        isError && formValue.iec_code === "" && "error"
                      }`}
                      onChange={(e) => setFormData("iec_code", e.target.value)}
                    />
                    {isError && formValue.iec_code === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="address_one">
                      Address One<span className="required-text">*</span>
                    </label>
                    <input
                      id="address_one"
                      name="address_one"
                      type="text"
                      value={formValue.address_one}
                      className={`form-control ${
                        isError && formValue.address_one === "" && "error"
                      }`}
                      onChange={(e) =>
                        setFormData("address_one", e.target.value)
                      }
                    />
                    {isError && formValue.address_one === "" && <ErrorText />}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="address_two">Address Two</label>
                    <input
                      id="address_two"
                      name="address_two"
                      type="text"
                      value={formValue.address_two}
                      className={`form-control`}
                      onChange={(e) =>
                        setFormData("address_two", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="contact_person">
                      Contact Person<span className="required-text">*</span>
                    </label>
                    <input
                      id="contact_person"
                      name="contact_person"
                      type="text"
                      value={formValue.contact_person}
                      className={`form-control ${
                        isError && formValue.contact_person === "" && "error"
                      }`}
                      onChange={(e) =>
                        setFormData("contact_person", e.target.value)
                      }
                    />
                    {isError && formValue.contact_person === "" && (
                      <ErrorText />
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="contact_no">
                      Contact Number<span className="required-text">*</span>
                    </label>
                    <input
                      id="contact_no"
                      name="contact_no"
                      type="text"
                      value={formValue.contact_no}
                      className={`form-control ${
                        isError && formValue.contact_no === "" && "error"
                      }`}
                      onChange={(e) =>
                        setFormData("contact_no", e.target.value)
                      }
                    />
                    {isError && formValue.contact_no === "" && <ErrorText />}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 profile-crop-img">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Logo</label>
                  <Crop
                    imagePreview={imagePreview ? imagePreview : formValue.logo}
                    onChangeCropper={async ({
                      fileCropped,
                      fileCroppedBase64,
                    }) => {
                      setFormValue({ ...formValue, logo: fileCroppedBase64 });
                      setImagePreview(fileCroppedBase64);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Signature</label>
                  <Crop
                    imagePreview={
                      signaturePreview ? signaturePreview : formValue.signature
                    }
                    onChangeCropper={async ({
                      fileCropped,
                      fileCroppedBase64,
                    }) => {
                      setFormValue({
                        ...formValue,
                        signature: fileCroppedBase64,
                      });
                      setSignaturePreview(fileCroppedBase64);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group text-end">
              <button
                type="submit"
                onClick={onSave}
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <div className="d-flex justify-content-between align-items-center">
                  {isSubmitting && (
                    <Oval
                      height={20}
                      width={20}
                      color="#FAF9F6"
                      wrapperClass="me-2"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                  <div>Submit</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
