import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import LoginComponent from "../pages/login";
import Layout from "../layout/Layout";
import Dashboard from "../pages/dashboard";
import Category from "../pages/category";
import AddCategory from "../pages/category/add";

import Size from "../pages/size";
import AddSize from "../pages/size/add";

import Series from "../pages/series";
import AddSeries from "../pages/series/add";

import SalesMan from "../pages/salesman";
import AddSalesMan from "../pages/salesman/add";

import Party from "../pages/party";
import AddParty from "../pages/party/add";

import Product from "../pages/product";
import AddProduct from "../pages/product/add";

import Banks from "../pages/banks";
import AddBanks from "../pages/banks/add";

import Transport from "../pages/transport";
import AddTransport from "../pages/transport/add";

import Profile from "../pages/settings/profile";

import Company from "../pages/company";
import AddCompany from "../pages/company/add";

import Purchase from "../pages/purchase";
import AddPurchase from "../pages/purchase/add";
import ViewPurchase from "../pages/purchase/view";

import Performa from "../pages/performa";
import AddPerforma from "../pages/performa/add";
import ViewPerforma from "../pages/performa/view";

import Invoices from "../pages/invoice";
import AddInvoice from "../pages/invoice/add";
import ViewInvoices from "../pages/invoice/view";

import AddDispatch from "../pages/dispatch/add";
import ViewDispatch from "../pages/dispatch/view";

const Router = () => {
  const Paths = [
    { path: "", component: <Dashboard /> },
    { path: "dashboard", component: <Dashboard /> },

    { path: "category", component: <Category /> },
    { path: "category/add", component: <AddCategory /> },
    { path: "category/edit/:id", component: <AddCategory /> },

    { path: "size", component: <Size /> },
    { path: "size/add", component: <AddSize /> },
    { path: "size/edit/:id", component: <AddSize /> },

    { path: "series", component: <Series /> },
    { path: "series/add", component: <AddSeries /> },
    { path: "series/edit/:id", component: <AddSeries /> },

    { path: "sales-man", component: <SalesMan /> },
    { path: "sales-man/add", component: <AddSalesMan /> },
    { path: "sales-man/edit/:id", component: <AddSalesMan /> },

    { path: "party", component: <Party /> },
    { path: "party/add", component: <AddParty /> },
    { path: "party/edit/:id", component: <AddParty /> },

    { path: "company", component: <Company /> },
    { path: "company/add", component: <AddCompany /> },
    { path: "company/edit/:id", component: <AddCompany /> },

    { path: "purchase", component: <Purchase /> },
    { path: "purchase/add", component: <AddPurchase /> },
    { path: "purchase/edit/:id", component: <AddPurchase /> },
    { path: "purchase/view/:id", component: <ViewPurchase /> },
    
    { path: "performa", component: <Performa /> },
    { path: "performa/add", component: <AddPerforma /> },
    { path: "performa/edit/:id", component: <AddPerforma /> },
    { path: "performa/view/:id", component: <ViewPerforma /> },
    
    { path: "invoices", component: <Invoices /> },
    { path: "invoices/add", component: <AddInvoice /> },
    { path: "invoices/edit/:id", component: <AddInvoice /> },
    { path: "invoices/view/:id", component: <ViewInvoices /> },
    
    { path: "dispatch/edit/:id", component: <AddDispatch /> },
    { path: "dispatch/view/:id", component: <ViewDispatch /> },
    
    { path: "product", component: <Product /> },
    { path: "product/add", component: <AddProduct /> },
    { path: "product/edit/:id", component: <AddProduct /> },

    { path: "transport", component: <Transport /> },
    { path: "transport/add", component: <AddTransport /> },
    { path: "transport/edit/:id", component: <AddTransport /> },

    { path: "banks", component: <Banks /> },
    { path: "banks/add", component: <AddBanks /> },
    { path: "banks/edit/:id", component: <AddBanks /> },

    { path: "profile", component: <Profile /> }
  ];

  return (
    <Routes>
      <Route path="/" element={<LoginComponent />}></Route>
      <Route path="/login" element={<LoginComponent />} />
      <Route path="admin" element={<Outlet />}>
        {Paths.map((element) => {
          return (
            <Route
              key={element}
              path={element.path}
              element={<Layout component={element.component} />}
            />
          );
        })}
      </Route>
      <Route exact path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
};

export default React.memo(Router);
