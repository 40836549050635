import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import ErrorText from "../../shared/error";
import Select from "react-select";
import Crop from "../../shared/crop";
import axios from "axios";

const initialValue = {
  id: null,
  name: "",
  unit: "",
  hsn_code: "",
  image: "",
  category_id: null,
  size_id: null,
  series_id: null,
  company_id: null,
};

const unitOptions = [
  { value: "Box", label: "Box" },
  { value: "Pcs", label: "Pcs" },
  { value: "Nos", label: "Nos" },
  { value: "Bag", label: "Bag" },
  { value: "Kg", label: "Kg" },
  { value: "Pkt", label: "Pkt" },
  { value: "Bottle", label: "Bottle" },
];

const selectStyles = {
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginTop: 2,
    zIndex: 999,
  }),
};

const Add = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [previewimage, setPreviewImage] = useState("");
  const [sizeDrp, setSizeDrpData] = useState([]);
  const [categoryDrp, setCategoryDrp] = useState([]);
  const [seriesDrp, setSeriesDrpData] = useState([]);
  const [companyDrp, setCompanyDrp] = useState([]);
  const [formValue, setFormValue] = useState(initialValue);

  const getCategory = () => {
    axios.post(API_URL.CATEGORY_LIST, {}).then((res) => {
      let cd = res.data.data.map((caw) => {
        return {
          label: caw.name,
          value: caw.id,
        };
      });
      setCategoryDrp(cd);
    });
  };

  const getSizeData = () => {
    axios
      .post(API_URL.SIZE_LIST, { category_id: formValue.category_id })
      .then((res) => {
        let cd = res.data.data.map((caw) => {
          return {
            label: caw.name,
            value: caw.id,
          };
        });
        setSizeDrpData(cd);
      });
  };

  const getSeriesData = () => {
    axios.post(API_URL.SERIES_LIST, {}).then((res) => {
      let cd = res.data.data.map((caw) => {
        return {
          label: caw.name,
          value: caw.id,
        };
      });
      setSeriesDrpData(cd);
    });
  };

  const getCompanyData = () => {
    axios.post(API_URL.COMPANY_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: raw.name,
            value: raw.id,
          };
        });
        setCompanyDrp(pt);
      }
    });
  };

  const resetForm = () => {
    setFormValue(initialValue);
  };

  const onEdit = () => {
    axios.post(API_URL.PRODUCT_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          name: res.data.data.name,
          unit: res.data.data.unit,
          series_id: res.data.data.series_id,
          size_id: res.data.data.size_id,
          hsn_code: res.data.data.hsn_code,
          company_id: res.data.data.company_id,
          category_id: res.data.data.category_id,
        });
        setPreviewImage(res.data.data.image);
      }
    });
  };

  const onSave = () => {
    setSubmitting(true);
    setError(false);
    if (formValue.name) {
      const url = id ? API_URL.PRODUCT_UPDATE : API_URL.PRODUCT_SAVE;
      axios.post(url, formValue).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Product Updated Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          } else {
            resetForm();
            toast.success("Product Created Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          }
          setTimeout(() => {
            navigate("/admin/product", { replace: true });
          }, 100);
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
        }
        setSubmitting(false);
      });
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    getCategory();
    getCompanyData();
    getSeriesData();
  }, []);

  useEffect(() => {
    if (formValue.category_id) {
      getSizeData();
    }
  }, [formValue.category_id]);

  useEffect(() => {
    id && onEdit();
  }, [id]);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Product</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/product"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name">
                        Name<span className="required-text">*</span>
                      </label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={formValue.name}
                        onChange={(e) => setFormData("name", e.target.value)}
                        className={`form-control ${
                          isError && formValue.name === "" && "error"
                        }`}
                        placeholder="Enter Name"
                      />
                      {isError && formValue.name === "" && <ErrorText />}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="hsn_code">
                        HSN Code<span className="required-text">*</span>
                      </label>
                      <input
                        id="hsn_code"
                        name="hsn_code"
                        type="text"
                        value={formValue.hsn_code}
                        onChange={(e) =>
                          setFormData("hsn_code", e.target.value)
                        }
                        className={`form-control ${
                          isError && formValue.hsn_code === "" && "error"
                        }`}
                        placeholder="Enter Name"
                      />
                      {isError && formValue.hsn_code === "" && <ErrorText />}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="unit">
                        Unit<span className="required-text">*</span>
                      </label>
                      <div
                        className={`${isError && !formValue.unit && "error1"}`}
                      >
                        <Select
                          id="unit"
                          name="unit"
                          options={unitOptions}
                          styles={selectStyles}
                          value={
                            unitOptions.find(
                              (r) => r.value === formValue.unit
                            ) || null
                          }
                          onChange={(e) => setFormData("unit", e.value)}
                        />
                      </div>
                      {isError && !formValue.unit && <ErrorText />}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="company_id">
                        Company<span className="required-text">*</span>
                      </label>
                      <div
                        className={`${
                          isError && !formValue.company_id && "error1"
                        }`}
                      >
                        <Select
                          id="company_id"
                          name="company_id"
                          options={companyDrp}
                          value={companyDrp.find(
                            (d) => d.value === formValue.company_id
                          )}
                          styles={selectStyles}
                          onChange={(e) => setFormData("company_id", e.value)}
                        />
                      </div>
                      {isError && !formValue.company_id && <ErrorText />}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="category_id">
                        Category<span className="required-text">*</span>
                      </label>
                      <div
                        className={`${
                          isError && !formValue.category_id && "error1"
                        }`}
                      >
                        <Select
                          id="category_id"
                          name="category_id"
                          options={categoryDrp}
                          value={
                            categoryDrp.find(
                              (d) => d.value === formValue.category_id
                            ) || null
                          }
                          styles={selectStyles}
                          onChange={(e) => setFormData("category_id", e.value)}
                        />
                      </div>
                      {isError && !formValue.category_id && <ErrorText />}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="size_id">
                        Size<span className="required-text">*</span>
                      </label>
                      <div
                        className={`${
                          isError && !formValue.size_id && "error1"
                        }`}
                      >
                        <Select
                          id="size_id"
                          name="size_id"
                          options={sizeDrp}
                          value={
                            sizeDrp.find(
                              (d) => d.value === formValue.size_id
                            ) || null
                          }
                          styles={selectStyles}
                          onChange={(e) => setFormData("size_id", e.value)}
                        />
                      </div>
                      {isError && !formValue.size_id && <ErrorText />}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="series_id">Series</label>
                      <Select
                        id="series_id"
                        name="series_id"
                        options={seriesDrp}
                        value={
                          seriesDrp.find(
                            (d) => d.value === formValue.series_id
                          ) || null
                        }
                        styles={selectStyles}
                        onChange={(e) => setFormData("series_id", e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Image</label>
                    <Crop
                      imagePreview={imagePreview}
                      cropShape="round"
                      guid={""}
                      onChangeCropper={async ({
                        fileCropped,
                        fileCroppedBase64,
                      }) => {
                        setFormData("image", fileCroppedBase64);
                        setImagePreview(fileCroppedBase64);
                      }}
                    />
                  </div>
                </div>

                {id && (
                  <div className="col-md-12">
                    <label>Preview</label>
                    <div>
                      <a
                        href={formValue.image}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={previewimage}
                          height={150}
                          width={150}
                          alt="product_image"
                        />
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-12">
                <div className="form-group text-end">
                  <button
                    type="button"
                    onClick={resetForm}
                    className="btn btn-secondary me-2"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    onClick={onSave}
                    disabled={isSubmitting}
                    className="btn btn-primary"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      {isSubmitting && (
                        <Oval
                          height={20}
                          width={20}
                          color="#FAF9F6"
                          wrapperClass="me-2"
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffffff"
                          strokeWidth={5}
                          strokeWidthSecondary={5}
                        />
                      )}
                      <div>Submit</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Add;
