import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import ErrorText from "../../shared/error";
import axios from "axios";

const initialValue = {
  id: null,
  name: "",
};

const AddCategory = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [formValue, setFormValue] = useState(initialValue);
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const resetForm = () => {
    setFormValue(initialValue);
  };

  const onEdit = () => {
    axios.post(API_URL.CATEGORY_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          name: res.data.data.name,
        });
      }
    });
  };

  const onSave = () => {
    setSubmitting(true);
    setError(false);
    if (formValue.name) {
      const pUrl = id ? API_URL.CATEGORY_UPDATE : API_URL.CATEGORY_SAVE;
      axios.post(pUrl, formValue).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Category Updated Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          } else {
            resetForm();
            toast.success("Category Created Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          }
          setTimeout(() => {
            navigate("/admin/category", { replace: true });
          }, 100);
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
        }
        setSubmitting(false);
      });
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    id && onEdit();
  }, [id]);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Category</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/category"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="name">
                    Name<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formValue.name}
                    onChange={(e) => setFormData("name", e.target.value)}
                    className={`form-control ${
                      isError && formValue.name === "" && "error"
                    }`}
                    placeholder="Enter Name"
                  />
                  {isError && formValue.name === "" && <ErrorText />}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={resetForm}
                className="btn btn-secondary me-2"
              >
                Reset
              </button>
              <button
                type="submit"
                onClick={onSave}
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <div className="d-flex justify-content-between align-items-center">
                  {isSubmitting && (
                    <Oval
                      height={20}
                      width={20}
                      color="#FAF9F6"
                      wrapperClass="me-2"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                  <div>Submit</div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
