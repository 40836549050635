import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import ErrorText from "../../shared/error";
import axios from "axios";

const selectStyles = {
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginTop: 2,
    zIndex: 999,
  }),
};

const initialValue = {
  id: null,
  name: "",
  category_id: null,
  thickness: "",
  pcs_per_box: "",
  box_per_pallet: "",
  box_per_pallet_one: "",
  box_per_pallet_two: "",
  pallet_per_container: "",
  pallet_per_container_one: "",
  pallet_per_container_two: "",
  weight: "",
  sqmt_area: "",
  notes: "",
};

const AddSize = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [formValue, setFormValue] = useState(initialValue);
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [categoryDrp, setCategoryDrp] = useState([]);

  const getCategory = () => {
    axios.post(API_URL.CATEGORY_LIST, {}).then((res) => {
      let cd = res.data.data.map((caw) => {
        return {
          label: caw.name,
          value: caw.id,
        };
      });
      setCategoryDrp(cd);
    });
  };

  const resetForm = () => {
    setFormValue(initialValue);
  };

  const onEdit = () => {
    axios.post(API_URL.SIZE_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          name: res.data.data.name,
          category_id: res.data.data.category_id,
          thickness: res.data.data.thickness,
          pcs_per_box: res.data.data.pcs_per_box,
          box_per_pallet_one: res.data.data.box_per_pallet_one,
          box_per_pallet_two: res.data.data.box_per_pallet_two,
          pallet_per_container_one: res.data.data.pallet_per_container_one,
          pallet_per_container_two: res.data.data.pallet_per_container_two,
          weight: res.data.data.weight,
          sqmt_area: res.data.data.sqmt_area,
          notes: res.data.data.notes,
        });
      }
    });
  };

  const onSave = () => {
    setSubmitting(true);
    setError(false);
    if (
      formValue.name &&
      formValue.category_id &&
      formValue.pcs_per_box &&
      formValue.box_per_pallet_one &&
      formValue.pallet_per_container_one &&
      formValue.sqmt_area &&
      formValue.thickness &&
      formValue.weight
    ) {
      const pUrl = id ? API_URL.SIZE_UPDATE : API_URL.SIZE_SAVE;
      axios.post(pUrl, formValue).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Size Updated Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          } else {
            resetForm();
            toast.success("Size Created Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          }
          setTimeout(() => {
            navigate("/admin/size", { replace: true });
          }, 100);
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
        }
        setSubmitting(false);
      });
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    id && onEdit();
  }, [id]);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Size</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/size"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="category_id">
                    Category<span className="required-text">*</span>
                  </label>
                  <div
                    className={`${
                      isError && !formValue.category_id && "error1"
                    }`}
                  >
                    <Select
                      id="category_id"
                      name="category_id"
                      options={categoryDrp}
                      styles={selectStyles}
                      value={
                        categoryDrp.find(
                          (d) => d.value === formValue.category_id
                        ) || null
                      }
                      onChange={(e) => setFormData("category_id", e.value)}
                    />
                  </div>
                  {isError && !formValue.category_id && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name">
                    Name<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formValue.name}
                    onChange={(e) => setFormData("name", e.target.value)}
                    className={`form-control ${
                      isError && formValue.name === "" && "error"
                    }`}
                    placeholder="Enter Name"
                  />
                  {isError && formValue.name === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="thickness">
                    Thickness<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="thickness"
                    type="text"
                    value={formValue.thickness}
                    onChange={(e) => setFormData("thickness", e.target.value)}
                    className={`form-control ${
                      isError && !formValue.thickness && "error"
                    }`}
                    placeholder="Thickness"
                  />
                  {isError && !formValue.thickness && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="pcs_per_box">
                    Pcs Per Box<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="pcs_per_box"
                    type="text"
                    value={formValue.pcs_per_box}
                    onChange={(e) => setFormData("pcs_per_box", e.target.value)}
                    className={`form-control ${
                      isError && !formValue.pcs_per_box && "error"
                    }`}
                    placeholder="Pcs Per Box"
                  />
                  {isError && !formValue.pcs_per_box && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="box_per_pallet_one">
                    Box Per Pallet<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="box_per_pallet_one"
                    type="text"
                    value={formValue.box_per_pallet_one}
                    onChange={(e) =>
                      setFormData("box_per_pallet_one", e.target.value)
                    }
                    className={`form-control ${
                      isError && !formValue.box_per_pallet_one && "error"
                    }`}
                    placeholder="Box Per Pallet"
                  />
                  {isError && !formValue.box_per_pallet_one && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="pallet_per_container_one">
                    Pallet Per Container<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="pallet_per_container_one"
                    type="text"
                    value={formValue.pallet_per_container_one}
                    onChange={(e) =>
                      setFormData("pallet_per_container_one", e.target.value)
                    }
                    className={`form-control ${
                      isError && !formValue.pallet_per_container_one && "error"
                    }`}
                    placeholder="Pallet Per Container"
                  />
                  {isError && !formValue.pallet_per_container_one && (
                    <ErrorText />
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="box_per_pallet_two">Box Per Pallet 2</label>
                  <input
                    id="name"
                    name="box_per_pallet_two"
                    type="text"
                    value={formValue.box_per_pallet_two}
                    onChange={(e) =>
                      setFormData("box_per_pallet_two", e.target.value)
                    }
                    className={"form-control"}
                    placeholder="Box Per Pallet 2"
                  />
                  {isError && !formValue.box_per_pallet_two && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="pallet_per_container_two">
                    Pallet Per Container 2
                  </label>
                  <input
                    id="name"
                    name="pallet_per_container_two"
                    type="text"
                    value={formValue.pallet_per_container_two}
                    onChange={(e) =>
                      setFormData("pallet_per_container_two", e.target.value)
                    }
                    className="form-control"
                    placeholder="Pallet Per Container 2"
                  />
                  {isError && !formValue.pallet_per_container_two && (
                    <ErrorText />
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="weight">
                    Weight<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="weight"
                    type="text"
                    value={formValue.weight}
                    onChange={(e) => setFormData("weight", e.target.value)}
                    className={`form-control ${
                      isError && !formValue.weight && "error"
                    }`}
                    placeholder="Weight"
                  />
                  {isError && !formValue.weight && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="sqmt_area">
                    SQMT<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="sqmt_area"
                    type="text"
                    value={formValue.sqmt_area}
                    onChange={(e) => setFormData("sqmt_area", e.target.value)}
                    className={`form-control ${
                      isError && !formValue.sqmt_area && "error"
                    }`}
                    placeholder="SQMT"
                  />
                  {isError && !formValue.sqmt_area && <ErrorText />}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="notes">
                    Notes<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="notes"
                    type="text"
                    value={formValue.notes}
                    onChange={(e) => setFormData("notes", e.target.value)}
                    className={`form-control ${
                      isError && !formValue.notes && "error"
                    }`}
                    placeholder="Notes"
                  />
                  {isError && !formValue.notes && <ErrorText />}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={resetForm}
                className="btn btn-secondary me-2"
              >
                Reset
              </button>
              <button
                type="submit"
                onClick={onSave}
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <div className="d-flex justify-content-between align-items-center">
                  {isSubmitting && (
                    <Oval
                      height={20}
                      width={20}
                      color="#FAF9F6"
                      wrapperClass="me-2"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                  <div>Submit</div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSize;
