import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import CurrencyInput from "react-currency-input-field";
import ErrorText from "../../shared/error";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment/moment";

const initialValue = {
  id: null,
  party_id: null,
  consignee_id: null,
  f_year: "",
  invoice_no: "",
  permission_no: "",
  booking_no: "",
  invoice_gst_no: "",
  file_no: "ARN NO.AD240424001314S Dated  01/04/2024, Valid Up to Date : 31/03/2025",
  date: new Date(),
  items: [
    {
      srno: 1,
      product_id: null,
      size: {},
      pallets: null,
      container: null,
      qty_per_box: null,
      qty_per_sq_mt: null,
      basic_rate_box: null,
      total: null,
    },
  ],
  tax: "0.1",
  exchange_rate: "",
  igst: "0",
  remarks: "",
  boxes: "",
  roundOff: "",
  notes: [{ text: "" }],
  extra_note: "",
  port_of_loading: "MUNDRA-INDIA",
  port_of_discharge: "SHUWAIKH-KUWAIT",
  country_of_origin: "INDIA",
  country_of_destination: "KUWAIT",
  payment_term_one: "100 % Payment Against B/L COPY",
  payment_term_two: "F.O.B. - MUNDRA (GUJARAT) INDIA",
  bank_id: "",
  sales_man_id: "",
  company_id: "",
  container_size: "",
  maximum_permissible_weight: "",
  weighbridge_registration_no: "AS PER ATTACHED SHEET",
  weighbridge_address: "MORBI",
  supply_meant_for: "[SUPPLY MEANT FOR EXPORT UNDER BOND OR LETTER OF UNDERTAKING WITHOUT PAYMENT OF INTEGRATED TAX (IGST)]",
  we_shall_claim: '"WE SHALL CLAIM RODTEP SCHEME AS ELIGIBLE AND APPLICABLE"& EXPORT UNDER DBK.AND EXPORT UNDER EPCG.',
};

const selectStyles = {
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginTop: 2,
    zIndex: 999,
  }),
};

const InvoiceDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [partyDrp, setPartyDrp] = useState([]);
  const [bankDrp, setBankDrp] = useState([]);
  const [salesManDrp, setSalesManDrp] = useState([]);
  const [companyDrp, setCompanyDrp] = useState([]);
  const [containerSizeDrp] = useState([
    {
      label: "20 FCL",
      value: "20 FCL",
    },
    {
      label: "40 FCL",
      value: "40 FCL",
    },
  ]);
  const [productDrp, setProductDrp] = useState([]);
  const [formValue, setFormValue] = useState(initialValue);

  const getLatestPoNo = () => {
    axios.post(API_URL.INVOICE_No, {}).then((res) => {
      if (res?.data) {
        setFormValue((prevStat) => ({
          ...prevStat,
          invoice_no: res.data.data,
          f_year: res.data.year,
        }));
      }
    });
  };

  const getPartyData = () => {
    axios.post(API_URL.PARTY_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: raw.name,
            value: raw.id,
          };
        });
        setPartyDrp(pt);
      }
    });
  };
  
  const getCompanyData = () => {
    axios.post(API_URL.COMPANY_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: raw.name,
            permission_no: raw.permission_no,
            value: raw.id,
          };
        });
        setCompanyDrp(pt);
      }
    });
  };

  const getProductData = () => {
    axios.post(API_URL.PRODUCT_BYSIZE_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: `${raw.name}`,
            value: raw.id,
            size: raw.size,
          };
        });
        setProductDrp(pt);
      }
    });
  };

  const getBankData = () => {
    axios.post(API_URL.BANKS_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: raw.bank_name,
            value: raw.id,
          };
        });
        setBankDrp(pt);
      }
    });
  };
  
  const getSalesManData = () => {
    axios.post(API_URL.SALES_MAN_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: raw.name,
            value: raw.id,
          };
        });
        setSalesManDrp(pt);
      }
    });
  };

  const resetForm = () => {
    setFormValue(initialValue);
  };

  const onEdit = () => {
    axios.post(API_URL.INVOICE_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          party_id: res.data.data.party_id,
          consignee_id: res.data.data.consignee_id,
          invoice_no: res.data.data.invoice_no,
          f_year: res.data.data.f_year,
          permission_no: res.data.data.permission_no,
          booking_no: res.data.data.booking_no,
          invoice_gst_no: res.data.data.invoice_gst_no,
          file_no: res.data.data.file_no,
          date: moment(res.data.data.date, "YYYY-MM-DD").toDate(),
          items: res.data.data.items.map((element, index) => {
            return {
              srno: index + 1,
              product_id: element.product_id,
              size: element.product.size,
              pallets: element.pallet,
              container: element.container,
              qty_per_box: element.qty_per_box,
              qty_per_sq_mt: element.qty_sqmt,
              basic_rate_box: element.rate,
              total: element.amount,
            };
          }),
          tax: res.data.data.tax_rate,
          exchange_rate: res.data.data.exchange_rate,
          igst: res.data.data.igst,
          remarks: res.data.data.remark,
          boxes: res.data.data.boxes_note,
          roundOff: res.data.data.round_off,
          notes: res.data.data.notes || [],
          extra_note: res.data.data.extra_note,
          port_of_loading: res.data.data.port_of_loading,
          port_of_discharge: res.data.data.port_of_discharge,
          country_of_origin: res.data.data.country_of_origin,
          country_of_destination: res.data.data.country_of_destination,
          payment_term_one: res.data.data.payment_term_one,
          payment_term_two: res.data.data.payment_term_two,
          bank_id: res.data.data.bank_id,
          sales_man_id: res.data.data.sales_man_id,
          company_id: res.data.data.company_id,
          container_size: res.data.data.container_size,
          maximum_permissible_weight: res.data.data.maximum_permissible_weight,
          weighbridge_registration_no:
            res.data.data.weighbridge_registration_no,
          weighbridge_address: res.data.data.weighbridge_address,
          supply_meant_for: res.data.data.supply_meant_for,
          we_shall_claim: res.data.data.we_shall_claim,
        });
        if (!res.data.data.notes) {
          addNoteRecord();
        }
      }
    });
  };

  const onSave = () => {
    setSubmitting(true);
    setError(false);
    if (
      formValue.party_id &&
      formValue.bank_id &&
      formValue.company_id &&
      formValue.invoice_no &&
      formValue.invoice_gst_no &&
      formValue.port_of_loading &&
      formValue.port_of_discharge &&
      formValue.country_of_origin &&
      formValue.country_of_destination
    ) {
      const url = id ? API_URL.INVOICE_UPDATE : API_URL.INVOICE_SAVE;
      let body = {
        party_id: formValue.party_id,
        consignee_id: formValue.consignee_id,
        date: moment(formValue.date).format("YYYY-MM-DD"),
        f_year: formValue.f_year,
        invoice_no: formValue.invoice_no,
        permission_no: formValue.permission_no,
        booking_no: formValue.booking_no,
        invoice_gst_no: formValue.invoice_gst_no,
        file_no: formValue.file_no,
        sub_total: getSubTotal(),
        total_amount: getFob(),
        items: formValue.items.map((element) => {
          return {
            pallet: element.pallets,
            container: element.container,
            qty_per_box: element.qty_per_box,
            qty_sqmt: element.qty_per_sq_mt,
            rate: element.basic_rate_box,
            amount: element.total,
            product_id: element.product_id,
          };
        }),
        extra_note: formValue.extra_note,
        round_off: formValue.roundOff,
        tax_rate: formValue.tax,
        exchange_rate: formValue.exchange_rate,
        igst: formValue.igst,
        total_rs: formValue.exchange_rate * getFob(),
        boxes_note: formValue.boxes,
        remark: formValue.remarks,
        total_qty: getTotalQty(),
        total_container: getTotalContainer(),
        total_pallets: getTotalPallets(),
        total_sqmt: getTotalSQMeter(),
        notes: formValue.notes,
        port_of_loading: formValue.port_of_loading,
        port_of_discharge: formValue.port_of_discharge,
        country_of_origin: formValue.country_of_origin,
        country_of_destination: formValue.country_of_destination,
        payment_term_one: formValue.payment_term_one,
        payment_term_two: formValue.payment_term_two,
        bank_id: formValue.bank_id,
        sales_man_id: formValue.sales_man_id,
        company_id: formValue.company_id,
        container_size: formValue.container_size,
        maximum_permissible_weight: formValue.maximum_permissible_weight,
        weighbridge_registration_no: formValue.weighbridge_registration_no,
        weighbridge_address: formValue.weighbridge_address,
        supply_meant_for: formValue.supply_meant_for,
        we_shall_claim: formValue.we_shall_claim,
      };
      if (id) {
        body["id"] = id;
      }
      axios.post(url, body).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Invoice Updated Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
            setSubmitting(false);
            setTimeout(() => {
              window.location.reload();
            }, 100);
          } else {
            resetForm();
            toast.success("Invoice Created Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
            setSubmitting(false);
            setTimeout(() => {
              navigate(`/admin/invoices/edit/${res.data.data.id}`, {
                replace: true,
              });
            }, 100);
          }
        } else {
          setSubmitting(false);
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
        }
      });
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const addRecord = () => {
    let copyData = { ...formValue };
    copyData.items.push({
      srno: 0,
      product_id: null,
      size: {},
      pallets: null,
      container: null,
      qty_per_box: null,
      qty_per_sq_mt: null,
      basic_rate_box: null,
      total: null,
    });
    setFormValue(copyData);
  };

  const removeRecord = (index) => {
    let copyData = { ...formValue };
    copyData.items.splice(index, 1);
    setFormValue(copyData);
  };

  const addNoteRecord = () => {
    let copyData = { ...formValue };
    copyData.notes.push({
      text: "",
    });
    setFormValue(copyData);
  };

  const removeNoteRecord = (index) => {
    let copyData = { ...formValue };
    copyData.notes.splice(index, 1);
    setFormValue(copyData);
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  const getTotalPallets = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.pallets ? parseInt(element.pallets) : 0);
    });
    return sum;
  };

  const getTotalContainer = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.container ? parseFloat(element.container) : 0);
    });
    return sum.toFixed(2);
  };

  const getTotalQty = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.qty_per_box ? parseInt(element.qty_per_box) : 0);
    });
    return sum;
  };

  const getTotalSQMeter = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum =
        sum + (element.qty_per_sq_mt ? parseFloat(element.qty_per_sq_mt) : 0);
    });
    return sum.toFixed(2);
  };

  const getSubTotal = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.total ? parseFloat(element.total) : 0);
    });
    return sum.toFixed(2);
  };

  const getFob = () => {
    let sum = 0;
    sum =
      parseFloat(getSubTotal()) -
      (formValue.roundOff ? parseFloat(formValue.roundOff) : 0);
    return sum.toFixed(2);
  };

  useEffect(() => {
    !id && getLatestPoNo();
    getPartyData();
    getCompanyData();
    getBankData();
    getSalesManData();
  }, []);

  useEffect(() => {
    getProductData();
  }, []);

  useEffect(() => {
    id && onEdit();
  }, [id]);

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={(event) => event.preventDefault()}>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="party_id">
                  Party<span className="required-text">*</span>
                </label>
                <div
                  className={`${isError && !formValue.party_id && "error1"}`}
                >
                  <Select
                    id="party_id"
                    name="party_id"
                    options={partyDrp}
                    value={
                      partyDrp.find((d) => d.value === formValue.party_id) ||
                      null
                    }
                    styles={selectStyles}
                    onChange={(e) => setFormData("party_id", e.value)}
                  />
                </div>
                {isError && !formValue.party_id && <ErrorText />}
              </div>
            </div>
            
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="consignee_id">
                  If Other Consignee
                </label>
                <div>
                  <Select
                    id="consignee_id"
                    name="consignee_id"
                    options={partyDrp}
                    value={
                      partyDrp.find((d) => d.value === formValue.consignee_id) ||
                      null
                    }
                    styles={selectStyles}
                    onChange={(e) => setFormData("consignee_id", e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="sales_man_id">
                Sales Man
                </label>
                <div>
                  <Select
                    id="sales_man_id"
                    name="sales_man_id"
                    options={salesManDrp}
                    value={
                      salesManDrp.find((d) => d.value === formValue.sales_man_id) || null
                    }
                    styles={selectStyles}
                    onChange={(e) => setFormData("sales_man_id", e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="invoice_no">
                  Invoice No.<span className="required-text">*</span>
                </label>
                <input
                  id="invoice_no"
                  name="invoice_no"
                  type="text"
                  value={formValue.invoice_no}
                  onChange={(e) => setFormData("invoice_no", e.target.value)}
                  className={`form-control ${
                    isError && formValue.invoice_no === "" && "error"
                  }`}
                  placeholder="Enter PO No."
                />
                {isError && formValue.invoice_no === "" && <ErrorText />}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <div>
                  <DatePicker
                    id="date"
                    name="date"
                    showIcon={true}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    dateFormat={"dd/MM/yyyy"}
                    className="form-control"
                    onChange={(e) => {
                      setFormData("date", e);
                    }}
                    selected={formValue.date}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="invoice_gst_no">
                  Invoice Gst No.<span className="required-text">*</span>
                </label>
                <input
                  id="invoice_gst_no"
                  name="invoice_gst_no"
                  type="text"
                  value={formValue.invoice_gst_no}
                  onChange={(e) =>
                    setFormData("invoice_gst_no", e.target.value)
                  }
                  className={`form-control ${
                    isError && formValue.invoice_gst_no === "" && "error"
                  }`}
                  placeholder="Enter Type"
                />
                {isError && !formValue.invoice_gst_no && <ErrorText />}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="bank_id">
                  Bank<span className="required-text">*</span>
                </label>
                <div className={`${isError && !formValue.bank_id && "error1"}`}>
                  <Select
                    id="bank_id"
                    name="bank_id"
                    options={bankDrp}
                    value={
                      bankDrp.find((d) => d.value === formValue.bank_id) || null
                    }
                    styles={selectStyles}
                    onChange={(e) => setFormData("bank_id", e.value)}
                  />
                </div>
                {isError && !formValue.bank_id && <ErrorText />}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="file_no">
                  LUT File No.<span className="required-text">*</span>
                </label>
                <input
                  id="file_no"
                  name="file_no"
                  type="text"
                  value={formValue.file_no}
                  onChange={(e) => setFormData("file_no", e.target.value)}
                  className={`form-control ${
                    isError && formValue.file_no === "" && "error"
                  }`}
                  placeholder="Enter Type"
                />
                {isError && !formValue.file_no && <ErrorText />}
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="permission_no">
                  Permission No.<span className="required-text">*</span>
                </label>
                <input
                  id="permission_no"
                  name="permission_no"
                  type="text"
                  value={formValue.permission_no}
                  onChange={(e) => setFormData("permission_no", e.target.value)}
                  className={`form-control ${
                    isError && formValue.permission_no === "" && "error"
                  }`}
                  placeholder="Enter Type"
                />
                {isError && !formValue.permission_no && <ErrorText />}
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="booking_no">
                  Booking No.<span className="required-text">*</span>
                </label>
                <input
                  id="booking_no"
                  name="booking_no"
                  type="text"
                  value={formValue.booking_no}
                  onChange={(e) => setFormData("booking_no", e.target.value)}
                  className={`form-control ${
                    isError && formValue.booking_no === "" && "error"
                  }`}
                  placeholder="Enter Type"
                />
                {isError && !formValue.booking_no && <ErrorText />}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="container_size">
                  Container Size<span className="required-text">*</span>
                </label>
                <div
                  className={`${
                    isError && !formValue.container_size && "error1"
                  }`}
                >
                  <Select
                    id="container_size"
                    name="container_size"
                    options={containerSizeDrp}
                    value={
                      containerSizeDrp.find(
                        (d) => d.value === formValue.container_size
                      ) || null
                    }
                    styles={selectStyles}
                    onChange={(e) => setFormData("container_size", e.value)}
                  />
                </div>
                {isError && formValue.container_size === "" && <ErrorText />}
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="maximum_permissible_weight">
                  Max Permissible Weight<span className="required-text">*</span>
                </label>
                <input
                  id="maximum_permissible_weight"
                  name="maximum_permissible_weight"
                  type="text"
                  value={formValue.maximum_permissible_weight}
                  onChange={(e) =>
                    setFormData("maximum_permissible_weight", e.target.value)
                  }
                  className={`form-control ${
                    isError &&
                    formValue.maximum_permissible_weight === "" &&
                    "error"
                  }`}
                  placeholder="Enter Port of loading"
                />
                {isError && formValue.maximum_permissible_weight === "" && (
                  <ErrorText />
                )}
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="weighbridge_registration_no">
                  Weigh Bridge Reg. No.<span className="required-text">*</span>
                </label>
                <input
                  id="weighbridge_registration_no"
                  name="weighbridge_registration_no"
                  type="text"
                  value={formValue.weighbridge_registration_no}
                  onChange={(e) =>
                    setFormData("weighbridge_registration_no", e.target.value)
                  }
                  className={`form-control ${
                    isError &&
                    formValue.weighbridge_registration_no === "" &&
                    "error"
                  }`}
                  placeholder="Enter Port of loading"
                />
                {isError && formValue.weighbridge_registration_no === "" && (
                  <ErrorText />
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="weighbridge_address">
                  Weigh Bridge Address<span className="required-text">*</span>
                </label>
                <input
                  id="weighbridge_address"
                  name="weighbridge_address"
                  type="text"
                  value={formValue.weighbridge_address}
                  onChange={(e) =>
                    setFormData("weighbridge_address", e.target.value)
                  }
                  className={`form-control ${
                    isError && formValue.weighbridge_address === "" && "error"
                  }`}
                  placeholder="Enter Port of loading"
                />
                {isError && formValue.weighbridge_address === "" && (
                  <ErrorText />
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="port_of_loading">
                  Port Of Loading<span className="required-text">*</span>
                </label>
                <input
                  id="port_of_loading"
                  name="port_of_loading"
                  type="text"
                  value={formValue.port_of_loading}
                  onChange={(e) =>
                    setFormData("port_of_loading", e.target.value)
                  }
                  className={`form-control ${
                    isError && formValue.port_of_loading === "" && "error"
                  }`}
                  placeholder="Enter Port of loading"
                />
                {isError && formValue.port_of_loading === "" && <ErrorText />}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="port_of_discharge">
                  Port Of Discharge<span className="required-text">*</span>
                </label>
                <input
                  id="port_of_discharge"
                  name="port_of_discharge"
                  type="text"
                  value={formValue.port_of_discharge}
                  onChange={(e) =>
                    setFormData("port_of_discharge", e.target.value)
                  }
                  className={`form-control ${
                    isError && formValue.port_of_discharge === "" && "error"
                  }`}
                  placeholder="Enter Port of discharge"
                />
                {isError && formValue.port_of_discharge === "" && <ErrorText />}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="country_of_origin">
                  Country Of Origin<span className="required-text">*</span>
                </label>
                <input
                  id="country_of_origin"
                  name="country_of_origin"
                  type="text"
                  value={formValue.country_of_origin}
                  onChange={(e) =>
                    setFormData("country_of_origin", e.target.value)
                  }
                  className={`form-control ${
                    isError && formValue.country_of_origin === "" && "error"
                  }`}
                  placeholder="Enter country of origin"
                />
                {isError && formValue.country_of_origin === "" && <ErrorText />}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="country_of_destination">
                  Country Of Destination
                  <span className="required-text">*</span>
                </label>
                <input
                  id="country_of_destination"
                  name="country_of_destination"
                  type="text"
                  value={formValue.country_of_destination}
                  onChange={(e) =>
                    setFormData("country_of_destination", e.target.value)
                  }
                  className={`form-control ${
                    isError &&
                    formValue.country_of_destination === "" &&
                    "error"
                  }`}
                  placeholder="Enter country of destination"
                />
                {isError && formValue.country_of_destination === "" && (
                  <ErrorText />
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="payment_term_one">
                  Payment Term 1
                </label>
                <input
                  id="payment_term_one"
                  name="payment_term_one"
                  type="text"
                  value={formValue.payment_term_one}
                  onChange={(e) =>
                    setFormData("payment_term_one", e.target.value)
                  }
                  className={`form-control`}
                  placeholder="Payment Term 1"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="payment_term_two">
                  Payment Term 2
                </label>
                <input
                  id="payment_term_two"
                  name="payment_term_two"
                  type="text"
                  value={formValue.payment_term_two}
                  onChange={(e) =>
                    setFormData("payment_term_two", e.target.value)
                  }
                  className={`form-control`}
                  placeholder="Payment Term 2"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="company_id">
                  Manufacturer<span className="required-text">*</span>
                </label>
                <div className={`${isError && !formValue.company_id && "error1"}`}>
                  <Select
                    id="company_id"
                    name="company_id"
                    options={companyDrp}
                    value={
                      companyDrp.find((d) => d.value === formValue.company_id) || null
                    }
                    styles={selectStyles}
                    onChange={(e) => {
                      setFormValue({ ...formValue, company_id: e.value, permission_no: e.permission_no });
                    }}
                  />
                </div>
                {isError && !formValue.company_id && <ErrorText />}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="supply_meant_for">Supply Meant For...</label>
                <input
                  id="supply_meant_for"
                  name="supply_meant_for"
                  type="text"
                  value={formValue.supply_meant_for}
                  onChange={(e) =>
                    setFormData("supply_meant_for", e.target.value)
                  }
                  className={`form-control`}
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="we_shall_claim">We Shall Claim...</label>
                <input
                  id="we_shall_claim"
                  name="we_shall_claim"
                  type="text"
                  value={formValue.we_shall_claim}
                  onChange={(e) =>
                    setFormData("we_shall_claim", e.target.value)
                  }
                  className={`form-control`}
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div>
            <h5>Products</h5>
            <div className="plus-plus-header">
              <div style={{ width: "5%" }}>Srno</div>
              <div style={{ width: "30%" }}>Products</div>
              <div style={{ width: "10%" }}>Pallets</div>
              <div style={{ width: "10%" }}>Container</div>
              <div style={{ width: "10%" }}>Qty/Box</div>
              <div style={{ width: "10%" }}>Qty/SQ MT</div>
              <div style={{ width: "10%" }}>Basic Rate SQ MT</div>
              <div style={{ width: "10%" }}>Total</div>
              <div style={{ width: "5%" }}></div>
            </div>
            {formValue.items.map((element, key) => {
              return (
                <div className="plus-plus-section" key={key}>
                  <div className="item" style={{ width: "5%" }}>
                    <input
                      id="srno"
                      name="srno"
                      className="form-control text-center"
                      type="text"
                      disabled
                      value={key + 1}
                      onChange={(e) => {
                        let copyData = { ...formValue };
                        copyData.items[key].srno = e.target.value;
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div className="item" style={{ width: "30%" }}>
                    <Select
                      id="product_id"
                      name="product_id"
                      options={productDrp}
                      isDisabled={!formValue.party_id}
                      value={
                        productDrp.find(
                          (d) => d.value === element.product_id
                        ) || null
                      }
                      styles={selectStyles}
                      onChange={(e) => {
                        let copyData = { ...formValue };
                        copyData.items[key].product_id = e.value;
                        copyData.items[key].size = e.size;
                        if (e.size && e.size.notes) {
                          let sel = copyData.notes.find(
                            (d) => d.text === e.size.notes
                          );
                          if (!sel) {
                            copyData.notes.unshift({ text: e.size.notes });
                          }
                        }
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div className="item" style={{ width: "10%" }}>
                    <input
                      id="pallete"
                      name="pallete"
                      className="form-control text-center"
                      type="text"
                      disabled={!element.product_id}
                      value={element.pallets}
                      onChange={(e) => {
                        let copyData = { ...formValue };
                        let value = e.target.value.replace(/\D/g, "");
                        copyData.items[key].pallets = value;
                        if (value && copyData.items[key].size) {
                          let tBox =
                            (parseInt(value) *
                              copyData.items[key].size.box_per_pallet) /
                            copyData.items[key].size.pallet_per_container;
                          copyData.items[key].container = (
                            parseInt(value) /
                            copyData.items[key].size.pallet_per_container
                          ).toFixed(2);
                          copyData.items[key].qty_per_box = tBox;
                          if (
                            copyData.items[key].qty_per_box &&
                            copyData.items[key].size
                          ) {
                            copyData.items[key].qty_per_sq_mt = (
                              parseFloat(copyData.items[key].qty_per_box) *
                              parseFloat(copyData.items[key].size.sqmt_area)
                            ).toFixed(2);
                          } else {
                            copyData.items[key].qty_per_sq_mt = "";
                          }
                        } else {
                          copyData.items[key].container = "";
                          copyData.items[key].qty_per_box = "";
                        }
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div className="item" style={{ width: "10%" }}>
                    <input
                      id="container"
                      name="container"
                      className="form-control text-center"
                      type="text"
                      value={element.container}
                      onChange={(e) => {
                        let copyData = { ...formValue };
                        copyData.items[key].container = e.target.value;
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div className="item" style={{ width: "10%" }}>
                    <input
                      id="qty_per_box"
                      name="qty_per_box"
                      className="form-control text-center"
                      type="number"
                      disabled={!element.product_id}
                      value={element.qty_per_box}
                      onChange={(e) => {
                        let copyData = { ...formValue };
                        let value = e.target.value.replace(/\D/g, "");
                        copyData.items[key].qty_per_box = value;
                        if (value && copyData.items[key].size) {
                          copyData.items[key].qty_per_sq_mt = (
                            parseFloat(value) *
                            parseFloat(copyData.items[key].size.sqmt_area)
                          ).toFixed(2);
                        } else {
                          copyData.items[key].qty_per_sq_mt = "";
                        }
                        if (copyData.items[key].qty_per_sq_mt && copyData.items[key].basic_rate_box) {
                          copyData.items[key].total = (
                            parseFloat(copyData.items[key].qty_per_sq_mt) *
                            copyData.items[key].basic_rate_box
                          ).toFixed(2);
                        }
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div className="item" style={{ width: "10%" }}>
                    <input
                      id="qty_per_sq_mt"
                      name="qty_per_sq_mt"
                      className="form-control text-center"
                      type="text"
                      disabled
                      value={element.qty_per_sq_mt}
                      onChange={(e) => {
                        let copyData = { ...formValue };
                        copyData.items[key].qty_per_sq_mt = e.target.value;
                        if (copyData.items[key].qty_per_sq_mt && copyData.items[key].basic_rate_box) {
                          copyData.items[key].total = (
                            parseFloat(copyData.items[key].qty_per_sq_mt) *
                            copyData.items[key].basic_rate_box
                          ).toFixed(2);
                        }
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div className="item" style={{ width: "10%" }}>
                    <CurrencyInput
                      id="basic_rate_box"
                      name="basic_rate_box"
                      className="form-control text-center"
                      disabled={!element.qty_per_box}
                      decimalsLimit={2}
                      value={element.basic_rate_box}
                      onValueChange={(value, name, values) => {
                        let copyData = { ...formValue };
                        copyData.items[key].basic_rate_box = value;
                        if (copyData.items[key].qty_per_sq_mt && copyData.items[key].basic_rate_box) {
                          copyData.items[key].total = (
                            parseFloat(copyData.items[key].qty_per_sq_mt) *
                            copyData.items[key].basic_rate_box
                          ).toFixed(2);
                        }
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div className="item" style={{ width: "10%" }}>
                    <input
                      id="total"
                      name="total"
                      className="form-control text-end"
                      type="text"
                      disabled
                      value={element.total}
                      onChange={(e) => {
                        let copyData = { ...formValue };
                        copyData.items[key].total = e.target.value;
                        setFormValue(copyData);
                      }}
                    />
                  </div>
                  <div
                    className="item d-flex align-items-center justify-content-center"
                    style={{ width: "5%" }}
                  >
                    <FaCircleMinus
                      onClick={() => {
                        removeRecord(key);
                      }}
                      size={28}
                      color="#6c757d"
                    />
                  </div>
                </div>
              );
            })}
            <div className="plus-plus-section">
              <div className="item py-2" style={{ width: "35%" }}>
                <h6 className="mb-0 text-center">Total</h6>
              </div>
              <div className="item py-2" style={{ width: "10%" }}>
                <h6 className="mb-0 text-center">{getTotalPallets()}</h6>
              </div>
              <div className="item py-2" style={{ width: "10%" }}>
                <h6 className="mb-0 text-center">{getTotalContainer()}</h6>
              </div>
              <div className="item py-2" style={{ width: "10%" }}>
                <h6 className="mb-0 text-center">{getTotalQty()}</h6>
              </div>
              <div className="item py-2" style={{ width: "10%" }}>
                <h6 className="mb-0 text-center">{getTotalSQMeter()}</h6>
              </div>
              <div className="item py-2" style={{ width: "10%" }}></div>
              <div className="item py-2 px-2" style={{ width: "10%" }}>
                <h6 className="mb-0 text-end">{getSubTotal()}</h6>
              </div>
              <div
                className="item d-flex align-items-center justify-content-center"
                style={{ width: "5%" }}
              >
                <FaCirclePlus onClick={addRecord} size={28} color="#7539ff" />
              </div>
            </div>
            <div className="plus-plus-section">
              <div className="item py-2 px-2" style={{ width: "15%" }}>
                <h6 className="mb-0">Notes</h6>
              </div>
              <div className="item" style={{ width: "40%" }}>
                <input
                  type="text"
                  value={formValue.extra_note}
                  onChange={(e) => {
                    setFormData("extra_note", e.target.value);
                  }}
                  className="form-control form-control-sm"
                  name="extra_note"
                />
              </div>
              <div className="item py-2" style={{ width: "30%" }}>
                <h6 className="mb-0 text-center">Sub Total</h6>
              </div>
              <div className="item py-2" style={{ width: "15%" }}>
                <h6 className="mb-0 text-center">{getSubTotal()}</h6>
              </div>
            </div>
            <div className="plus-plus-section">
              <div className="item py-2 px-2" style={{ width: "15%" }}>
                <h6 className="mb-0">Remarks</h6>
              </div>
              <div className="item" style={{ width: "40%" }}>
                <input
                  type="text"
                  value={formValue.remarks}
                  onChange={(e) => {
                    setFormData("remarks", e.target.value);
                  }}
                  className="form-control form-control-sm"
                  name="remarks"
                />
              </div>
              <div className="item py-2" style={{ width: "30%" }}>
                <h6 className="mb-0 text-center">Round Off</h6>
              </div>
              <div
                className="item d-flex justify-content-center"
                style={{ width: "15%" }}
              >
                <input
                  type="text"
                  value={formValue.roundOff}
                  onChange={(e) => {
                    setFormData("roundOff", e.target.value);
                  }}
                  style={{ width: "100px" }}
                  className="form-control form-control-sm text-center"
                  name="roundOff"
                />
              </div>
            </div>
            <div className="plus-plus-section">
              <div className="item py-2 px-2" style={{ width: "15%" }}>
                <h6 className="mb-0">
                  Exchange Rate<span className="required-text">*</span>
                </h6>
              </div>
              <div className="item" style={{ width: "40%", display: "flex" }}>
                <div style={{ width: "20%" }}>
                  <input
                    type="number"
                    value={formValue.exchange_rate}
                    onChange={(e) => {
                      setFormData("exchange_rate", e.target.value);
                    }}
                    className="form-control form-control-sm"
                    name="exchange_rate"
                  />
                </div>
                <div
                  className=""
                  style={{
                    width: "15%",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "15px",
                  }}
                >
                  <h6 className="mb-0">IGST</h6>
                </div>
                <div style={{ width: "15%" }}>
                  <input
                    type="number"
                    value={formValue.igst}
                    onChange={(e) => {
                      setFormData("igst", e.target.value);
                    }}
                    className="form-control form-control-sm"
                    name="igst"
                  />
                </div>
                <div
                  className=""
                  style={{
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "15px",
                  }}
                >
                  <h6 className="mb-0">
                    GST Amount:{" "}
                    {formValue.igst
                      ? (
                          (formValue.exchange_rate *
                            getFob() *
                            formValue.igst) /
                          100
                        ).toFixed(2)
                      : 0}
                  </h6>
                </div>
              </div>
              <div className="item py-2" style={{ width: "30%" }}>
                <h6 className="mb-0 text-center">Total (FOB)</h6>
              </div>
              <div className="item py-2" style={{ width: "15%" }}>
                <h6 className="mb-0 text-center">{getFob()}</h6>
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-md-10">
              {/* <h5 className="mb-2">Notes</h5>
              {formValue.notes.map((element, key) => {
                return (
                  <div className="row mt-1" key={key}>
                    <div className="col-md-10">
                      <input
                        id={"notes" + key}
                        name={"notes" + key}
                        type="text"
                        value={element.text}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.notes[key].text = e.target.value;
                          setFormValue(copyData);
                        }}
                        className="form-control"
                        placeholder="Enter Notes...."
                      />
                    </div>
                    <div className="col-md-2 d-flex align-items-center">
                      {key !== formValue.notes.length - 1 && (
                        <FaCircleMinus
                          onClick={() => {
                            removeNoteRecord(key);
                          }}
                          size={28}
                          color="#6c757d"
                        />
                      )}
                      {key === formValue.notes.length - 1 && (
                        <FaCirclePlus
                          onClick={addNoteRecord}
                          size={28}
                          color="#7539ff"
                        />
                      )}
                    </div>
                  </div>
                );
              })} */}
            </div>
            <div className="col-md-2">
              <div className="form-group text-end">
                <button
                  type="button"
                  onClick={resetForm}
                  className="btn btn-secondary me-2"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  onClick={onSave}
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {isSubmitting && (
                      <Oval
                        height={20}
                        width={20}
                        color="#FAF9F6"
                        wrapperClass="me-2"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    )}
                    <div>Submit</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvoiceDetail;
