import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import CurrencyInput from "react-currency-input-field";
import ErrorText from "../../shared/error";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment/moment";

const initialValue = {
  id: null,
  party_id: null,
  f_year: "",
  contact_person: "",
  pi_no: "",
  pi_number: "",
  pi_type: "",
  po_number: "",
  date: new Date(),
  items: [
    {
      srno: 1,
      product_id: null,
      size: {},
      pallets: null,
      container: null,
      qty_per_box: null,
      qty_per_sq_mt: null,
      basic_rate_box: null,
      total: null,
    },
  ],
  freight: "",
  insurance: "",
  containerNote: "1 X 20 FCL Container",
  remarks: "Payment will be accepted in USD Only.",
  boxes: "Consignee Box",
  roundOff: "",
  notes: [
    {
      text: "'MADE IN INDIA' Will be engraved on back side of the tile and same will be written on box",
    },
    {
      text: "Any third party inspaction involved on request shall be charge extra",
    },
    {
      text: "2 to 3% brakages is acceptable in transportation normally in ceramic product",
    },
    {
      text: "Some scratches may occur after dispatch also due to handling and transportation",
    },
    { text: "REGULAR PALLET" },
  ],
  extra_note: "Rates are with regular pallet packing",
  port_of_loading: "MUNDRA",
  port_of_discharge: "",
  country_of_origin: "INDIA",
  country_of_destination: "",
  bank_id: "",
};

const selectStyles = {
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginTop: 2,
    zIndex: 999,
  }),
};

const AddPerforma = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [partyDrp, setCompanyDrp] = useState([]);
  const [bankDrp, setBankDrp] = useState([]);
  const [productDrp, setProductDrp] = useState([]);
  const [formValue, setFormValue] = useState(initialValue);

  const getLatestPoNo = () => {
    axios.post(API_URL.PERFORMA_PO_No, {}).then((res) => {
      if (res?.data) {
        setFormValue((prevStat) => ({
          ...prevStat,
          pi_no: res.data.data,
          f_year: res.data.year,
        }));
      }
    });
  };

  const getPartyData = () => {
    axios.post(API_URL.PARTY_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: raw.name,
            value: raw.id,
          };
        });
        setCompanyDrp(pt);
      }
    });
  };

  const getProductData = () => {
    axios.post(API_URL.PRODUCT_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: `${raw.name} - ${raw.size.name} - ${raw.company.name}`,
            value: raw.id,
            size: raw.size,
          };
        });
        setProductDrp(pt);
      }
    });
  };

  const getBankData = () => {
    axios.post(API_URL.BANKS_LIST, {}).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: raw.bank_name,
            value: raw.id,
          };
        });
        setBankDrp(pt);
      }
    });
  };

  const resetForm = () => {
    setFormValue(initialValue);
  };

  const onEdit = () => {
    axios.post(API_URL.PERFORMA_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          party_id: res.data.data.party_id,
          contact_person: res.data.data.contact_person,
          pi_no: res.data.data.pi_no,
          f_year: res.data.data.f_year,
          pi_number: res.data.data.pi_number,
          pi_type: res.data.data.pi_type,
          po_number: res.data.data.po_number,
          date: moment(res.data.data.date, "YYYY-MM-DD").toDate(),
          items: res.data.data.items.map((element, index) => {
            return {
              srno: index + 1,
              product_id: element.product_id,
              size: element.product.size,
              pallets: element.pallet,
              container: element.container,
              qty_per_box: element.qty_per_box,
              qty_per_sq_mt: element.qty_sqmt,
              basic_rate_box: element.rate,
              total: element.amount,
            };
          }),
          freight: res.data.data.freight,
          insurance: res.data.data.insurance,
          containerNote: res.data.data.container_note,
          remarks: res.data.data.remark,
          boxes: res.data.data.boxes_note,
          roundOff: res.data.data.round_off,
          notes: res.data.data.notes || [],
          extra_note: res.data.data.extra_note,
          port_of_loading: res.data.data.port_of_loading,
          port_of_discharge: res.data.data.port_of_discharge,
          country_of_origin: res.data.data.country_of_origin,
          country_of_destination: res.data.data.country_of_destination,
          bank_id: res.data.data.bank_id,
        });
        if (!res.data.data.notes) {
          addNoteRecord();
        }
      }
    });
  };

  const onSave = () => {
    setSubmitting(true);
    setError(false);
    if (
      formValue.party_id &&
      formValue.contact_person &&
      formValue.bank_id &&
      formValue.pi_no &&
      formValue.pi_type &&
      formValue.port_of_loading &&
      formValue.port_of_discharge &&
      formValue.country_of_origin &&
      formValue.country_of_destination
    ) {
      const url = id ? API_URL.PERFORMA_UPDATE : API_URL.PERFORMA_SAVE;
      let body = {
        party_id: formValue.party_id,
        contact_person: formValue.contact_person,
        date: moment(formValue.date).format("YYYY-MM-DD"),
        f_year: formValue.f_year,
        pi_no: formValue.pi_no,
        pi_number: formValue.pi_number,
        pi_type: formValue.pi_type,
        po_number: formValue.po_number,
        sub_total: getSubTotal(),
        total_amount: getFob(),
        items: formValue.items.map((element) => {
          return {
            pallet: element.pallets,
            container: element.container,
            qty_per_box: element.qty_per_box,
            qty_sqmt: element.qty_per_sq_mt,
            rate: element.basic_rate_box,
            amount: element.total,
            product_id: element.product_id,
          };
        }),
        extra_note: formValue.extra_note,
        round_off: formValue.roundOff,
        freight: formValue.freight,
        insurance: formValue.insurance,
        container_note: formValue.containerNote,
        boxes_note: formValue.boxes,
        remark: formValue.remarks,
        total_qty: getTotalQty(),
        total_container: getTotalContainer(),
        total_sqmt: getTotalSQMeter(),
        total_pallets: getTotalPallets(),
        notes: formValue.notes,
        port_of_loading: formValue.port_of_loading,
        port_of_discharge: formValue.port_of_discharge,
        country_of_origin: formValue.country_of_origin,
        country_of_destination: formValue.country_of_destination,
        bank_id: formValue.bank_id,
      };
      if (id) {
        body["id"] = id;
      }
      axios.post(url, body).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Performa Updated Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          } else {
            resetForm();
            toast.success("Performa Created Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          }
          setTimeout(() => {
            navigate("/admin/performa", { replace: true });
          }, 100);
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
        }
        setSubmitting(false);
      });
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const addRecord = () => {
    let copyData = { ...formValue };
    copyData.items.push({
      srno: 0,
      product_id: null,
      size: {},
      pallets: null,
      container: null,
      qty_per_box: null,
      qty_per_sq_mt: null,
      basic_rate_box: null,
      total: null,
    });
    setFormValue(copyData);
  };

  const removeRecord = (index) => {
    let copyData = { ...formValue };
    copyData.items.splice(index, 1);
    setFormValue(copyData);
  };

  const addNoteRecord = () => {
    let copyData = { ...formValue };
    copyData.notes.push({
      text: "",
    });
    setFormValue(copyData);
  };

  const removeNoteRecord = (index) => {
    let copyData = { ...formValue };
    copyData.notes.splice(index, 1);
    setFormValue(copyData);
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  const getTotalPallets = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.pallets ? parseInt(element.pallets) : 0);
    });
    return sum;
  };

  const getTotalContainer = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.container ? parseFloat(element.container) : 0);
    });
    return sum.toFixed(2);
  };

  const getTotalQty = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.qty_per_box ? parseInt(element.qty_per_box) : 0);
    });
    return sum;
  };

  const getTotalSQMeter = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum =
        sum + (element.qty_per_sq_mt ? parseFloat(element.qty_per_sq_mt) : 0);
    });
    return sum.toFixed(2);
  };

  const getSubTotal = () => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element.total ? parseFloat(element.total) : 0);
    });
    return sum.toFixed(2);
  };

  const getTaxAmount = () => {
    return (
      (formValue.freight ? parseFloat(formValue.freight) : 0) +
      (formValue.insurance ? parseFloat(formValue.insurance) : 0)
    ).toFixed(2);
  };

  const getFob = () => {
    let sum = 0;
    sum =
      parseFloat(getSubTotal()) +
      parseFloat(getTaxAmount()) -
      (formValue.roundOff ? parseFloat(formValue.roundOff) : 0);
    return sum.toFixed(2);
  };

  const getPIPONumber = () => {
    if (formValue.pi_no && formValue.pi_type) {
      setFormValue((prevStat) => ({
        ...prevStat,
        pi_number: `${formValue.pi_no}/${formValue.f_year}`,
        po_number: `${formValue.f_year}/${formValue.pi_no}-${formValue.pi_type}`,
      }));
    } else {
      setFormValue((prevStat) => ({
        ...prevStat,
        pi_number: "",
        po_number: "",
      }));
    }
  };

  useEffect(() => {
    getPIPONumber();
  }, [formValue.pi_no, formValue.pi_type]);

  useEffect(() => {
    !id && getLatestPoNo();
    getPartyData();
    getBankData();
  }, []);

  useEffect(() => {
    getProductData();
  }, []);

  useEffect(() => {
    id && onEdit();
  }, [id]);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Performa</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/performa"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="party_id">
                    Party<span className="required-text">*</span>
                  </label>
                  <div
                    className={`${isError && !formValue.party_id && "error1"}`}
                  >
                    <Select
                      id="party_id"
                      name="party_id"
                      options={partyDrp}
                      value={
                        partyDrp.find((d) => d.value === formValue.party_id) ||
                        null
                      }
                      styles={selectStyles}
                      onChange={(e) => setFormData("party_id", e.value)}
                    />
                  </div>
                  {isError && !formValue.party_id && <ErrorText />}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="contact_person">
                  Contact Person<span className="required-text">*</span>
                  </label>
                  <input
                    id="contact_person"
                    name="contact_person"
                    type="text"
                    value={formValue.contact_person}
                    onChange={(e) => setFormData("contact_person", e.target.value)}
                    className={`form-control ${
                      isError && formValue.contact_person === "" && "error"
                    }`}
                    placeholder="Enter Contact Person."
                  />
                  {isError && formValue.contact_person === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="pi_no">
                    No.<span className="required-text">*</span>
                  </label>
                  <input
                    id="pi_no"
                    name="pi_no"
                    type="text"
                    value={formValue.pi_no}
                    onChange={(e) => setFormData("pi_no", e.target.value)}
                    className={`form-control ${
                      isError && formValue.pi_no === "" && "error"
                    }`}
                    placeholder="Enter PO No."
                  />
                  {isError && formValue.pi_no === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="pi_type">
                    Type<span className="required-text">*</span>
                  </label>
                  <input
                    id="pi_type"
                    name="pi_type"
                    type="text"
                    value={formValue.pi_type}
                    onChange={(e) => setFormData("pi_type", e.target.value)}
                    className={`form-control ${
                      isError && formValue.pi_type === "" && "error"
                    }`}
                    placeholder="Enter Type"
                  />
                  {isError && !formValue.pi_type && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="po_number">
                    PO No.<span className="required-text">*</span>
                  </label>
                  <input
                    id="po_number"
                    name="po_number"
                    type="text"
                    disabled
                    value={formValue.po_number}
                    className={`form-control`}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="pi_number">
                    PI No.<span className="required-text">*</span>
                  </label>
                  <input
                    id="pi_number"
                    name="pi_number"
                    type="text"
                    disabled
                    value={formValue.pi_number}
                    className={`form-control`}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="port_of_loading">
                    Port Of Loading<span className="required-text">*</span>
                  </label>
                  <input
                    id="port_of_loading"
                    name="port_of_loading"
                    type="text"
                    value={formValue.port_of_loading}
                    onChange={(e) =>
                      setFormData("port_of_loading", e.target.value)
                    }
                    className={`form-control ${
                      isError && formValue.port_of_loading === "" && "error"
                    }`}
                    placeholder="Enter Port of loading"
                  />
                  {isError && formValue.port_of_loading === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="port_of_discharge">
                    Port Of Discharge<span className="required-text">*</span>
                  </label>
                  <input
                    id="port_of_discharge"
                    name="port_of_discharge"
                    type="text"
                    value={formValue.port_of_discharge}
                    onChange={(e) =>
                      setFormData("port_of_discharge", e.target.value)
                    }
                    className={`form-control ${
                      isError && formValue.port_of_discharge === "" && "error"
                    }`}
                    placeholder="Enter Port of discharge"
                  />
                  {isError && formValue.port_of_discharge === "" && (
                    <ErrorText />
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="country_of_origin">
                    Country Of Origin<span className="required-text">*</span>
                  </label>
                  <input
                    id="country_of_origin"
                    name="country_of_origin"
                    type="text"
                    value={formValue.country_of_origin}
                    onChange={(e) =>
                      setFormData("country_of_origin", e.target.value)
                    }
                    className={`form-control ${
                      isError && formValue.country_of_origin === "" && "error"
                    }`}
                    placeholder="Enter country of origin"
                  />
                  {isError && formValue.country_of_origin === "" && (
                    <ErrorText />
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="country_of_destination">
                    Country Of Destination
                    <span className="required-text">*</span>
                  </label>
                  <input
                    id="country_of_destination"
                    name="country_of_destination"
                    type="text"
                    value={formValue.country_of_destination}
                    onChange={(e) =>
                      setFormData("country_of_destination", e.target.value)
                    }
                    className={`form-control ${
                      isError &&
                      formValue.country_of_destination === "" &&
                      "error"
                    }`}
                    placeholder="Enter country of destination"
                  />
                  {isError && formValue.country_of_destination === "" && (
                    <ErrorText />
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="bank_id">
                    Bank<span className="required-text">*</span>
                  </label>
                  <div
                    className={`${isError && !formValue.bank_id && "error1"}`}
                  >
                    <Select
                      id="bank_id"
                      name="bank_id"
                      options={bankDrp}
                      value={
                        bankDrp.find((d) => d.value === formValue.bank_id) ||
                        null
                      }
                      styles={selectStyles}
                      onChange={(e) => setFormData("bank_id", e.value)}
                    />
                  </div>
                  {isError && !formValue.bank_id && <ErrorText />}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="date">Date</label>
                  <div>
                    <DatePicker
                      id="date"
                      name="date"
                      showIcon={true}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      dateFormat={"dd/MM/yyyy"}
                      className="form-control"
                      onChange={(e) => {
                        setFormData("date", e);
                      }}
                      selected={formValue.date}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h5>Products</h5>
              <div className="plus-plus-header">
                <div style={{ width: "5%" }}>Srno</div>
                <div style={{ width: "30%" }}>Products</div>
                <div style={{ width: "10%" }}>Pallets</div>
                <div style={{ width: "10%" }}>Container</div>
                <div style={{ width: "10%" }}>Qty/Box</div>
                <div style={{ width: "10%" }}>Qty/SQ MT</div>
                <div style={{ width: "10%" }}>Basic Rate SQ MT</div>
                <div style={{ width: "10%" }}>Total</div>
                <div style={{ width: "5%" }}></div>
              </div>
              {formValue.items.map((element, key) => {
                return (
                  <div className="plus-plus-section" key={key}>
                    <div className="item" style={{ width: "5%" }}>
                      <input
                        id="srno"
                        name="srno"
                        className="form-control text-center"
                        type="text"
                        disabled
                        value={key + 1}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].srno = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "30%" }}>
                      <Select
                        id="product_id"
                        name="product_id"
                        options={productDrp}
                        isDisabled={!formValue.party_id}
                        value={
                          productDrp.find(
                            (d) => d.value === element.product_id
                          ) || null
                        }
                        styles={selectStyles}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].product_id = e.value;
                          copyData.items[key].size = e.size;
                          if (e.size && e.size.notes) {
                            let sel = copyData.notes.find(
                              (d) => d.text === e.size.notes
                            );
                            if (!sel) {
                              copyData.notes.unshift({ text: e.size.notes });
                            }
                          }
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="pallete"
                        name="pallete"
                        className="form-control text-center"
                        type="text"
                        disabled={!element.product_id}
                        value={element.pallets}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          let value = e.target.value.replace(/\D/g, "");
                          copyData.items[key].pallets = value;
                          if (value && copyData.items[key].size) {
                            let tBox =
                              (parseInt(value) *
                                copyData.items[key].size.box_per_pallet) /
                              copyData.items[key].size.pallet_per_container;
                            copyData.items[key].container = (
                              parseInt(value) /
                              copyData.items[key].size.pallet_per_container
                            ).toFixed(2);
                            copyData.items[key].qty_per_box = tBox;
                            if (
                              copyData.items[key].qty_per_box &&
                              copyData.items[key].size
                            ) {
                              copyData.items[key].qty_per_sq_mt = (
                                parseFloat(copyData.items[key].qty_per_box) *
                                parseFloat(copyData.items[key].size.sqmt_area)
                              ).toFixed(2);
                            } else {
                              copyData.items[key].qty_per_sq_mt = "";
                            }
                          } else {
                            copyData.items[key].container = "";
                            copyData.items[key].qty_per_box = "";
                          }
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="container"
                        name="container"
                        className="form-control text-center"
                        type="text"
                        value={element.container}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].container = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="qty_per_box"
                        name="qty_per_box"
                        className="form-control text-center"
                        type="number"
                        disabled={!element.product_id}
                        value={element.qty_per_box}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          let value = e.target.value.replace(/\D/g, "");
                          copyData.items[key].qty_per_box = value;
                          if (value && copyData.items[key].size) {
                            copyData.items[key].qty_per_sq_mt = (
                              parseFloat(value) *
                              parseFloat(copyData.items[key].size.sqmt_area)
                            ).toFixed(2);
                          } else {
                            copyData.items[key].qty_per_sq_mt = "";
                          }
                          if (copyData.items[key].qty_per_box && copyData.items[key].basic_rate_box) {
                            copyData.items[key].total = (
                              parseFloat(copyData.items[key].qty_per_box) *
                              copyData.items[key].basic_rate_box
                            ).toFixed(2);
                          }
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="qty_per_sq_mt"
                        name="qty_per_sq_mt"
                        className="form-control text-center"
                        type="text"
                        disabled
                        value={element.qty_per_sq_mt}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].qty_per_sq_mt = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <CurrencyInput
                        id="basic_rate_box"
                        name="basic_rate_box"
                        className="form-control text-center"
                        disabled={!element.qty_per_box}
                        decimalsLimit={2}
                        value={element.basic_rate_box}
                        onValueChange={(value, name, values) => {
                          let copyData = { ...formValue };
                          copyData.items[key].basic_rate_box = value;
                          if (copyData.items[key].qty_per_box && copyData.items[key].basic_rate_box) {
                            copyData.items[key].total = (
                              parseFloat(copyData.items[key].qty_per_box) *
                              copyData.items[key].basic_rate_box
                            ).toFixed(2);
                          }
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="total"
                        name="total"
                        className="form-control text-end"
                        type="text"
                        disabled
                        value={element.total}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].total = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div
                      className="item d-flex align-items-center justify-content-center"
                      style={{ width: "5%" }}
                    >
                      <FaCircleMinus
                        onClick={() => {
                          removeRecord(key);
                        }}
                        size={28}
                        color="#6c757d"
                      />
                    </div>
                  </div>
                );
              })}
              <div className="plus-plus-section">
                <div className="item py-2" style={{ width: "35%" }}>
                  <h6 className="mb-0 text-center">Total</h6>
                </div>
                <div className="item py-2" style={{ width: "10%" }}>
                  <h6 className="mb-0 text-center">{getTotalPallets()}</h6>
                </div>
                <div className="item py-2" style={{ width: "10%" }}>
                  <h6 className="mb-0 text-center">{getTotalContainer()}</h6>
                </div>
                <div className="item py-2" style={{ width: "10%" }}>
                  <h6 className="mb-0 text-center">{getTotalQty()}</h6>
                </div>
                <div className="item py-2" style={{ width: "10%" }}>
                  <h6 className="mb-0 text-center">{getTotalSQMeter()}</h6>
                </div>
                <div className="item py-2" style={{ width: "10%" }}></div>
                <div className="item py-2 px-2" style={{ width: "10%" }}>
                  <h6 className="mb-0 text-end">{getSubTotal()}</h6>
                </div>
                <div
                  className="item d-flex align-items-center justify-content-center"
                  style={{ width: "5%" }}
                >
                  <FaCirclePlus onClick={addRecord} size={28} color="#7539ff" />
                </div>
              </div>
              <div className="plus-plus-section">
                <div className="item py-2 px-2" style={{ width: "55%" }}></div>
                <div className="item py-2" style={{ width: "30%" }}>
                  <h6 className="mb-0 text-center">Sub Total</h6>
                </div>
                <div className="item py-2" style={{ width: "15%" }}>
                  <h6 className="mb-0 text-center">{getSubTotal()}</h6>
                </div>
              </div>
              <div className="plus-plus-section">
                <div className="item py-2 px-2" style={{ width: "15%" }}>
                  <h6 className="mb-0">Total Container</h6>
                </div>
                <div className="item" style={{ width: "40%" }}>
                  <input
                    type="text"
                    value={formValue.containerNote}
                    onChange={(e) => {
                      setFormData("containerNote", e.target.value);
                    }}
                    className="form-control form-control-sm"
                    name="containerNote"
                  />
                </div>
                <div className="item py-2" style={{ width: "30%" }}>
                  <h6 className="mb-0 text-center">Insurance</h6>
                </div>
                <div
                  className="item d-flex justify-content-center"
                  style={{ width: "15%" }}
                >
                  <input
                    type="text"
                    value={formValue.insurance}
                    onChange={(e) => {
                      setFormData("insurance", e.target.value);
                    }}
                    style={{ width: "100px" }}
                    className="form-control form-control-sm text-center"
                    name="insurance"
                  />
                </div>
              </div>
              <div className="plus-plus-section">
                <div className="item py-2 px-2" style={{ width: "15%" }}>
                  <h6 className="mb-0">Boxes</h6>
                </div>
                <div className="item" style={{ width: "40%" }}>
                  <input
                    type="text"
                    value={formValue.boxes}
                    onChange={(e) => {
                      setFormData("boxes", e.target.value);
                    }}
                    className="form-control form-control-sm"
                    name="boxes"
                  />
                </div>
                <div className="item py-2" style={{ width: "30%" }}>
                  <h6 className="mb-0 text-center">Freight</h6>
                </div>
                <div
                  className="item d-flex justify-content-center"
                  style={{ width: "15%" }}
                >
                  <input
                    type="text"
                    value={formValue.freight}
                    onChange={(e) => {
                      setFormData("freight", e.target.value);
                    }}
                    style={{ width: "100px" }}
                    className="form-control form-control-sm text-center"
                    name="freight"
                  />
                </div>
              </div>

              <div className="plus-plus-section">
                <div className="item py-2 px-2" style={{ width: "15%" }}>
                  <h6 className="mb-0">Remarks</h6>
                </div>
                <div className="item" style={{ width: "40%" }}>
                  <input
                    type="text"
                    value={formValue.remarks}
                    onChange={(e) => {
                      setFormData("remarks", e.target.value);
                    }}
                    className="form-control form-control-sm"
                    name="remarks"
                  />
                </div>
                <div className="item py-2" style={{ width: "30%" }}>
                  <h6 className="mb-0 text-center">Round Off</h6>
                </div>
                <div
                  className="item d-flex justify-content-center"
                  style={{ width: "15%" }}
                >
                  <input
                    type="text"
                    value={formValue.roundOff}
                    onChange={(e) => {
                      setFormData("roundOff", e.target.value);
                    }}
                    style={{ width: "100px" }}
                    className="form-control form-control-sm text-center"
                    name="roundOff"
                  />
                </div>
              </div>
              <div className="plus-plus-section">
                <div className="item py-2 px-2" style={{ width: "15%" }}>
                  <h6 className="mb-0">Notes</h6>
                </div>
                <div className="item" style={{ width: "40%" }}>
                  <input
                    type="text"
                    value={formValue.extra_note}
                    onChange={(e) => {
                      setFormData("extra_note", e.target.value);
                    }}
                    className="form-control form-control-sm"
                    name="extra_note"
                  />
                </div>
                <div className="item py-2" style={{ width: "30%" }}>
                  <h6 className="mb-0 text-center">Total (FOB)</h6>
                </div>
                <div className="item py-2" style={{ width: "15%" }}>
                  <h6 className="mb-0 text-center">{getFob()}</h6>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-md-10">
                <h5 className="mb-2">Notes</h5>
                {formValue.notes.map((element, key) => {
                  return (
                    <div className="row mt-1" key={key}>
                      <div className="col-md-10">
                        <input
                          id={"notes" + key}
                          name={"notes" + key}
                          type="text"
                          value={element.text}
                          onChange={(e) => {
                            let copyData = { ...formValue };
                            copyData.notes[key].text = e.target.value;
                            setFormValue(copyData);
                          }}
                          className="form-control"
                          placeholder="Enter Notes...."
                        />
                      </div>
                      <div className="col-md-2 d-flex align-items-center">
                        {key !== 0 && (
                          <FaCircleMinus
                            onClick={() => {
                              removeNoteRecord(key);
                            }}
                            size={28}
                            color="#6c757d"
                          />
                        )}
                        {key === 0 && (
                          <FaCirclePlus
                            onClick={addNoteRecord}
                            size={28}
                            color="#7539ff"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-md-2">
                <div className="form-group text-end">
                  <button
                    type="button"
                    onClick={resetForm}
                    className="btn btn-secondary me-2"
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    onClick={onSave}
                    disabled={isSubmitting}
                    className="btn btn-primary"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      {isSubmitting && (
                        <Oval
                          height={20}
                          width={20}
                          color="#FAF9F6"
                          wrapperClass="me-2"
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffffff"
                          strokeWidth={5}
                          strokeWidthSecondary={5}
                        />
                      )}
                      <div>Submit</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPerforma;
