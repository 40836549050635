import React, { useEffect, useState } from "react";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import ErrorText from "../../shared/error";
import DatePicker from "react-datepicker";
import { Oval } from "react-loader-spinner";
import axios from "axios";
import moment from "moment/moment";

const ManufacturingDetails = ({ invoiceId }) => {
  const [companyData, setCompanyData] = useState([]);
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const getCompanyDetail = () => {
    axios
      .post(API_URL.INVOICE_COMPANY, { invoice_id: invoiceId })
      .then((res) => {
        if (res.data.status === 1) {
          res.data.data.forEach((r) => {
            r.invoice_date = r.invoice_date
              ? moment(r.invoice_date, "YYYY-MM-DD").toDate()
              : null;
            r.epcg_date = r.epcg_date
              ? moment(r.epcg_date, "YYYY-MM-DD").toDate()
              : null;
          });
          setCompanyData(res.data.data);
        }
      });
  };

  const updateValue = (fieldName, fieldValue, k) => {
    let copyData = [...companyData];
    copyData[k][fieldName] = fieldValue;
    setCompanyData(copyData);
  };
  const saveDetails = () => {
    setSubmitting(true);
    setError(false);
    let copyData = [];
    let isValid = true;
    companyData.forEach((r) => {
      if (!r.invoice_no) {
        isValid = false;
      }
      copyData.push({
        company_id: r.company_id,
        invoice_no: r.invoice_no,
        epcg_lic_number: r.epcg_lic_number,
        invoice_date: r.invoice_date
          ? moment(r.invoice_date).format("YYYY-MM-DD")
          : null,
        epcg_date: r.epcg_date
          ? moment(r.epcg_date).format("YYYY-MM-DD")
          : null,
      });
    });
    if (!isValid) {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    } else {
      let body = {
        invoice_id: invoiceId,
        manufacturing_details: copyData,
      };
      axios.post(API_URL.INVOICE_COMPANY_SAVE, body).then((res) => {
        if (res.data.status === 1) {
          toast.success("Manufacturing details updated successfully.", {
            autoClose: 3000,
            position: "top-right",
          });
          setSubmitting(false);
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
          setSubmitting(false);
        }
      });
    }
  };

  useEffect(() => {
    invoiceId && getCompanyDetail();
  }, [invoiceId]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Manufacturer</th>
                <th>GST Number</th>
                <th>
                  Invoice No.<span className="required-text">*</span>
                </th>
                <th>Invoice Dt.</th>
                <th>EPCG Lic.Number</th>
                <th>EPCG Date</th>
              </tr>
            </thead>
            <tbody>
              {companyData.map((row, k) => {
                return (
                  <tr key={k}>
                    <td>{row.company_name}</td>
                    <td>{row.company_gst}</td>
                    <td>
                      <div className="form-group mb-0">
                        <input
                          name="invoice_no"
                          type="text"
                          value={row.invoice_no ? row.invoice_no : ""}
                          className={`form-control ${
                            isError && row.invoice_no === "" && "error"
                          }`}
                          onChange={(e) =>
                            updateValue("invoice_no", e.target.value, k)
                          }
                          placeholder="Invoice No."
                        />
                        {isError && !row.invoice_no && <ErrorText />}
                      </div>
                    </td>
                    <td>
                      <div className="form-group mb-0">
                        <DatePicker
                          id="date"
                          name="date"
                          showIcon={true}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          placeholderText="Invoice Date"
                          dateFormat={"dd/MM/yyyy"}
                          className="form-control"
                          onChange={(e) => {
                            updateValue("invoice_date", e, k);
                          }}
                          selected={row.invoice_date ? row.invoice_date : null}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group mb-0">
                        <input
                          name="epcg_lic_number"
                          type="text"
                          value={row.epcg_lic_number ? row.epcg_lic_number : ""}
                          className="form-control"
                          onChange={(e) =>
                            updateValue("epcg_lic_number", e.target.value, k)
                          }
                          placeholder="EPCG Lic.Number"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group mb-0">
                        <DatePicker
                          id="date"
                          name="date"
                          showIcon={true}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          placeholderText="EPCG Date"
                          dateFormat={"dd/MM/yyyy"}
                          className="form-control"
                          onChange={(e) => {
                            updateValue("epcg_date", e, k);
                          }}
                          selected={row.epcg_date ? row.epcg_date : null}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="text-end">
            <button
              type="submit"
              onClick={saveDetails}
              disabled={isSubmitting}
              className="btn btn-primary"
            >
              <div className="d-flex justify-content-between align-items-center">
                {isSubmitting && (
                  <Oval
                    height={20}
                    width={20}
                    color="#FAF9F6"
                    wrapperClass="me-2"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ffffff"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                )}
                <div>Submit</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManufacturingDetails;
