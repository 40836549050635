import React from 'react'
import { Oval } from "react-loader-spinner";

const Loader = () => {
    return (
        <>
            <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </>
    )
}

export default Loader