import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Logo } from "../../shared/logo";

const Sidebar = () => {
  const location = useLocation();
  const myLogo = sessionStorage.getItem("logo");
  const [toggle, setToggle] = useState(false);
  const [settings, setSetting] = useState(false);

  return (
    <div className="sidebar hidden-print" id="sidebar">
      <div className="sidebar-header p-0">
        <Link to="/">
          <img src={Logo} className="img-fluid" alt="2" />
        </Link>
      </div>
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li
              className={
                location.pathname.includes("/admin/dashboard") ? "active" : ""
              }
            >
              <Link to="/admin/dashboard">
                <i className="fe fe-home"></i> <span>Dashboard</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/profile") ? "active" : ""
              }
            >
              <Link to="/admin/profile">
                <i className="fe fe-user"></i> <span>Profile</span>
              </Link>
            </li>
            <li className="menu-title">
              <span>Invoices</span>
            </li>
            <li
              className={
                location.pathname.includes("/admin/invoices") ? "active" : ""
              }
            >
              <Link to="/admin/invoices">
                <i className="fe fe-file"></i> <span>Invoices</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/performa") ? "active" : ""
              }
            >
              <Link to="/admin/performa">
                <i className="fe fe-file"></i> <span>Performa</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/purchase") ? "active" : ""
              }
            >
              <Link to="/admin/purchase">
                <i className="fe fe-file-text"></i> <span>Purchase</span>
              </Link>
            </li>
            <li className="menu-title">
              <span>Main</span>
            </li>
            <li
              className={
                location.pathname.includes("admin/product") ? "active" : ""
              }
            >
              <Link to="/admin/product">
                <i className="fe fe-package"></i> <span>Product</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("admin/party") ? "active" : ""
              }
            >
              <Link to="/admin/party">
                <i className="fe fe-users"></i> <span>Buyers</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("admin/company") ? "active" : ""
              }
            >
              <Link to="/admin/company">
                <i className="fe fe-globe"></i> <span>Company</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/category") ? "active" : ""
              }
            >
              <Link to="/admin/category">
                <i className="fe fe-package"></i> <span>Category</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/size") ? "active" : ""
              }
            >
              <Link to="/admin/size">
                <i className="fe fe-package"></i> <span>Size</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/series") ? "active" : ""
              }
            >
              <Link to="/admin/series">
                <i className="fe fe-package"></i> <span>Series</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/sales-man") ? "active" : ""
              }
            >
              <Link to="/admin/sales-man">
                <i className="fe fe-package"></i> <span>Sales Man</span>
              </Link>
            </li>
            <li
              className={
                location.pathname.includes("/admin/banks") ? "active" : ""
              }
            >
              <Link to="/admin/banks">
                <i className="fe fe-codepen"></i> <span>Banks</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
