import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InvoiceDetail from './invoiceDetail';
import ManufacturingDetails from './manufacturingDetails';
import ContainerDetails from './containerDetails';

const AddInvoice = () => {
  let { id } = useParams();
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Invoice</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/invoices"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
        <div className="custom-tabs">
        <Tabs
            defaultActiveKey="invoice_details"
            id="uncontrolled-tab-example"
            className=""
            >
            <Tab eventKey="invoice_details" title="Invoice Details">
                <InvoiceDetail />
            </Tab>
            <Tab eventKey="manufacturing_details" disabled={!id} title="Manufacturing Details">
                <ManufacturingDetails invoiceId={(id) ? id: null} />
            </Tab>
            <Tab eventKey="container_details" disabled={!id} title="Container Details">
                <ContainerDetails invoiceId={(id) ? id: null} />
            </Tab>
        </Tabs>
        </div>
    </>
  );
};

export default AddInvoice;
