import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { FaPrint } from "react-icons/fa6";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import axios from "axios";

const initialValue = {
  id: null,
  company_id: null,
  company: {},
  pi_year: "",
  pi_no: "",
  pi_number: "",
  pi_type: "",
  po_number: "",
  date: "",
  items: [
    {
      srno: 1,
      product_id: null,
      size: {},
      pallets: null,
      container: null,
      qty_per_box: null,
      qty_per_sq_mt: null,
      basic_rate_box: null,
      total: null,
    },
  ],
  tax: "0.1",
  containerNote: "",
  remarks: "",
  boxes: "",
  roundOff: "",
  notes: [],
  extra_note: "",
};

const ViewPurchase = () => {
  const contentToPrint = useRef(null);
  const navigate = useNavigate();
  let { id } = useParams();
  const [formValue, setFormValue] = useState(initialValue);
  const [userInfo, setUserInfo] = useState({});

  const onEdit = () => {
    axios.post(API_URL.PURCHASE_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          company_id: res.data.data.company_id,
          company: res.data.data.company,
          pi_no: res.data.data.pi_no,
          pi_year: res.data.data.pi_year,
          pi_number: res.data.data.pi_number,
          pi_type: res.data.data.pi_type,
          po_number: res.data.data.po_number,
          date: moment(res.data.data.date, "YYYY-MM-DD").format("DD-MM-YYYY"),
          items: res.data.data.items.map((element, index) => {
            return {
              srno: index + 1,
              product: element.product,
              product_id: element.product_id,
              size: {},
              pallets: element.pallet,
              container: element.container,
              qty_per_box: element.qty_per_box,
              qty_per_sq_mt: element.qty_sqmt,
              basic_rate_box: element.rate,
              total: element.amount,
              category: element?.product?.category?.name,
              hsn_code: element?.product?.hsn_code,
            };
          }),
          tax: res.data.data.tax_rate,
          tax_amount: res.data.data.tax_amount,
          containerNote: res.data.data.container_note,
          remarks: res.data.data.remark,
          boxes: res.data.data.boxes_note,
          roundOff: res.data.data.round_off,
          notes: res.data.data.notes || [],
          extra_note: res.data.data.extra_note,
          total_amount: res.data.data.total_amount,
          total_container: res.data.data.total_container,
          total_pallets: res.data.data.total_pallets,
          total_sqmt: res.data.data.total_sqmt,
          total_qty: res.data.data.total_qty,
          sub_total: res.data.data.sub_total,
        });
      }
      getMe();
    });
  };

  const getMe = () => {
    axios.post(API_URL.LOGIN_USER, {}).then((res) => {
      setUserInfo(res.data);
    });
  };

  const handlePrint = useReactToPrint({
    documentTitle: "",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  useEffect(() => {
    onEdit();
  }, [id]);

  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-danger mb-2"
          onClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        >
          <div className="d-flex align-items-center">
            <FaPrint size={15} />
            &nbsp; Print
          </div>
        </button>
        <button
          className="btn btn-secondary ms-2 mb-2"
          onClick={() => {
            navigate("/admin/purchase");
          }}
        >
          <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
        </button>
      </div>
      <div className="print-div" ref={contentToPrint}>
        <table style={{ width: "100%", border: "1px solid #000000" }}>
          <tr>
            <td
              style={{
                width: "50%",
                padding: "0px",
                position: "relative",
                borderRight: "1px solid #000000",
              }}
            >
              <img src={userInfo?.logo} width={"100%"} className="logo-img" />
            </td>
            <td style={{ width: "50%" }}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                      borderBottom: "1px solid #000000",
                    }}
                    colSpan={3}
                  >
                    Purchase Order
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000",
                      borderRight: "1px solid #000000",
                    }}
                  >
                    PO No.
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000",
                      borderRight: "1px solid #000000",
                    }}
                  >
                    PI No.
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Date
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000",
                      borderTop: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                  >
                    {formValue.po_number}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000",
                      borderTop: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                  >
                    {formValue.pi_number}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000",
                      borderTop: "1px solid #000000",
                    }}
                  >
                    {formValue.date}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "50%",
                padding: "2px",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "600",
                color: "#000",
                borderTop: "1px solid #000",
              }}
            >
              Mfg. Company Name
            </td>
            <td
              style={{
                width: "50%",
                padding: "2px",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "600",
                color: "#000",
                borderTop: "1px solid #000",
                borderLeft: "1px solid #000",
              }}
            >
              Exporter
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }}>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Company Name
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {formValue?.company?.name}
            </td>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Company Name
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {userInfo?.name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Company Address
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {formValue?.company?.address_one},<br />{" "}
              {formValue?.company?.address_two},<br />
              {formValue?.company?.city}-{formValue?.company?.pincode}, (
              {formValue?.company?.state}) India.{" "}
            </td>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Company Address
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {userInfo?.address_one},<br />{" "}
              {userInfo?.address_two && `${userInfo?.address_two}<br />,`}
              {userInfo?.city}-{userInfo?.pincode}, ({userInfo?.state}) India.{" "}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Contact Person
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {formValue?.company?.contact_person}
            </td>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Contact Person
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {userInfo?.contact_person}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Phone
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {formValue?.company?.contact_no}
            </td>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Phone
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {userInfo?.contact_no}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Email
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {formValue?.company?.email}
            </td>
            <td
              style={{
                width: "15%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              Email
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 5px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderRight: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
            >
              {userInfo?.email}
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }}>
          <tr>
            <td
              style={{
                width: "50%",
                padding: "2px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderLeft: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Product Description
            </td>
            <td
              style={{
                width: "25%",
                padding: "2px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              IEC Code
            </td>
            <td
              style={{
                width: "25%",
                padding: "2px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              HS Code
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "50%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderLeft: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.items &&
                formValue.items.length > 0 &&
                formValue.items[0].category}
            </td>
            <td
              style={{
                width: "25%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {userInfo?.iec_code}
            </td>
            <td
              style={{
                width: "25%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.items &&
                formValue.items.length > 0 &&
                formValue.items[0].hsn_code}
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }}>
          <tr>
            <td
              style={{
                width: "5%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderLeft: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Srno.
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderLeft: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Size.
            </td>
            <td
              style={{
                width: "15%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderLeft: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Design
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Design Number
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Pallets
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Container
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Qty/Box
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Qty/SqMt
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Basic Rate SQ MT
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
                verticalAlign: "middle",
              }}
            >
              Total
            </td>
          </tr>
          {formValue.items.map((element, key) => {
            return (
              <tr key={key}>
                <td
                  style={{
                    width: "5%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderLeft: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {key + 1}
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {element?.product?.size?.name}
                </td>
                <td
                  style={{
                    width: "15%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  <img src={element?.product?.image} style={{ height: 50 }} />
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  <div>{element?.product?.name}</div>
                  <div>{element?.product?.series?.name}</div>
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {element.pallets}
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {element.container}
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {element.qty_per_box}
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {element.qty_per_sq_mt}
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {element.basic_rate_box}
                </td>
                <td
                  style={{
                    width: "10%",
                    padding: "2px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    borderBottom: "1px solid #000",
                    borderRight: "1px solid #000",
                    verticalAlign: "middle",
                  }}
                >
                  {element.total}
                </td>
              </tr>
            );
          })}
          <tr>
            <td
              colSpan={4}
              style={{
                width: "40%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Total
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.total_pallets}
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.total_container}
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.total_qty}
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.total_sqmt}
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            ></td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.sub_total}
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }}>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Total Container
            </td>
            <td
              style={{
                width: "45%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.containerNote}
            </td>
            <td
              style={{
                width: "30%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Sub Total
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.sub_total}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Boxes
            </td>
            <td
              style={{
                width: "45%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.boxes}
            </td>
            <td
              style={{
                width: "30%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Tax ({formValue.tax})
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.tax_amount}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Remarks
            </td>
            <td
              style={{
                width: "45%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.remarks}
            </td>
            <td
              style={{
                width: "30%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Round Off
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.roundOff}
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Notes
            </td>
            <td
              style={{
                width: "45%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.extra_note}
            </td>
            <td
              style={{
                width: "30%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Total (FOB)
            </td>
            <td
              style={{
                width: "10%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue.total_amount}
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }}>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Notes:
              {formValue.notes.map((element, key) => {
                return (
                  <div key={key}>
                    {key + 1}.&nbsp;{element.text}
                  </div>
                );
              })}
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }}>
          <tr>
            <td
              style={{
                width: "15%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Approved By
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {formValue?.company?.name}
            </td>
            <td
              style={{
                width: "15%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Issued By
            </td>
            <td
              style={{
                width: "35%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              {userInfo?.name}
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }}>
          <tr>
            <td
              style={{
                width: "50%",
                padding: "2px 4px",
                textAlign: "left",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderLeft: "1px solid #000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            ></td>
            <td
              style={{
                width: "50%",
                padding: "2px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              <img
                src={userInfo?.signature}
                style={{
                  width: "350px",
                  height: "80px",
                }}
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default ViewPurchase;
