import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const userName = sessionStorage.getItem('user_name');
    const logout = () => {
        sessionStorage.clear();
        navigate("/", { replace: true });
    }
    return (
        <div className="header header-one hidden-print">
            <a className="mobile_btn" id="mobile_btn">
                <i className="fas fa-bars"></i>
            </a>
            <ul className="nav nav-tabs user-menu">
                <li className="nav-item dropdown">
                    <button className='btn btn-primary' onClick={logout}>Log Out</button>
                </li>
            </ul>
        </div>
    )
}

export default Header;