// const BASE_URL = "http://127.0.0.1:8000/api/";
const BASE_URL = "https://api.shreemceramica.com/api/";

export const API_URL = {
  CATEGORY_LIST: `${BASE_URL}category`,
  CATEGORY_GET: `${BASE_URL}category/show`,
  CATEGORY_SAVE: `${BASE_URL}category/store`,
  CATEGORY_UPDATE: `${BASE_URL}category/update`,
  CATEGORY_DELETE: `${BASE_URL}category/delete`,

  SUB_CATEGORY_LIST: `${BASE_URL}sub-category`,
  SUB_CATEGORY_GET: `${BASE_URL}sub-category/show`,
  SUB_CATEGORY_SAVE: `${BASE_URL}sub-category/store`,
  SUB_CATEGORY_UPDATE: `${BASE_URL}sub-category/update`,
  SUB_CATEGORY_DELETE: `${BASE_URL}sub-category/delete`,
  ADMIN_SUB_CATEGORY_BY_CATEGORY: `${BASE_URL}category/sub-category`,

  SIZE_LIST: `${BASE_URL}size`,
  SIZE_GET: `${BASE_URL}size/show`,
  SIZE_SAVE: `${BASE_URL}size/store`,
  SIZE_UPDATE: `${BASE_URL}size/update`,
  SIZE_DELETE: `${BASE_URL}size/delete`,

  SERIES_LIST: `${BASE_URL}series`,
  SERIES_GET: `${BASE_URL}series/show`,
  SERIES_SAVE: `${BASE_URL}series/store`,
  SERIES_UPDATE: `${BASE_URL}series/update`,
  SERIES_DELETE: `${BASE_URL}series/delete`,
  
  SALES_MAN_LIST: `${BASE_URL}sales-man`,
  SALES_MAN_GET: `${BASE_URL}sales-man/show`,
  SALES_MAN_SAVE: `${BASE_URL}sales-man/store`,
  SALES_MAN_UPDATE: `${BASE_URL}sales-man/update`,
  SALES_MAN_DELETE: `${BASE_URL}sales-man/delete`,

  GRADE_LIST: `${BASE_URL}grade`,
  GRADE_GET: `${BASE_URL}grade/show`,
  GRADE_SAVE: `${BASE_URL}grade/store`,
  GRADE_UPDATE: `${BASE_URL}grade/update`,
  GRADE_DELETE: `${BASE_URL}grade/delete`,

  TRANSPORT_LIST: `${BASE_URL}transport`,
  TRANSPORT_GET: `${BASE_URL}transport/show`,
  TRANSPORT_SAVE: `${BASE_URL}transport/store`,
  TRANSPORT_UPDATE: `${BASE_URL}transport/update`,
  TRANSPORT_DELETE: `${BASE_URL}transport/delete`,

  PARTY_LIST: `${BASE_URL}party`,
  PARTY_GET: `${BASE_URL}party/show`,
  PARTY_SAVE: `${BASE_URL}party/store`,
  PARTY_UPDATE: `${BASE_URL}party/update`,
  PARTY_DELETE: `${BASE_URL}party/delete`,

  COMPANY_LIST: `${BASE_URL}company`,
  COMPANY_GET: `${BASE_URL}company/show`,
  COMPANY_SAVE: `${BASE_URL}company/store`,
  COMPANY_UPDATE: `${BASE_URL}company/update`,
  COMPANY_DELETE: `${BASE_URL}company/delete`,

  PRODUCT_LIST: `${BASE_URL}product`,
  PRODUCT_BYSIZE_LIST: `${BASE_URL}product/sizes`,
  PRODUCT_GET: `${BASE_URL}product/show`,
  PRODUCT_SAVE: `${BASE_URL}product/store`,
  PRODUCT_UPDATE: `${BASE_URL}product/update`,
  PRODUCT_DELETE: `${BASE_URL}product/delete`,

  PURCHASE_LIST: `${BASE_URL}purchase`,
  PURCHASE_GET: `${BASE_URL}purchase/show`,
  PURCHASE_SAVE: `${BASE_URL}purchase/store`,
  PURCHASE_UPDATE: `${BASE_URL}purchase/update`,
  PURCHASE_DELETE: `${BASE_URL}purchase/delete`,
  PURCHASE_PO_No: `${BASE_URL}purchase/po-no`,

  PERFORMA_LIST: `${BASE_URL}sales`,
  PERFORMA_GET: `${BASE_URL}sales/show`,
  PERFORMA_SAVE: `${BASE_URL}sales/store`,
  PERFORMA_UPDATE: `${BASE_URL}sales/update`,
  PERFORMA_DELETE: `${BASE_URL}sales/delete`,
  PERFORMA_PO_No: `${BASE_URL}sales/po-no`,

  DISPATCH_LIST: `${BASE_URL}dispatch`,
  DISPATCH_GET: `${BASE_URL}dispatch/show`,
  DISPATCH_SAVE: `${BASE_URL}dispatch/store`,
  DISPATCH_DELETE: `${BASE_URL}dispatch/delete`,
  
  INVOICE_LIST: `${BASE_URL}invoice`,
  INVOICE_PRODUCT_LIST: `${BASE_URL}invoice/products`,
  INVOICE_GET: `${BASE_URL}invoice/show`,
  INVOICE_SAVE: `${BASE_URL}invoice/store`,
  INVOICE_UPDATE: `${BASE_URL}invoice/update`,
  INVOICE_DELETE: `${BASE_URL}invoice/delete`,
  INVOICE_No: `${BASE_URL}invoice/invoice-no`,
  INVOICE_COMPANY: `${BASE_URL}invoice/company`,
  INVOICE_COMPANY_SAVE: `${BASE_URL}invoice/company-save`,
  INVOICE_CONTAINER: `${BASE_URL}invoice/container`,
  INVOICE_CONTAINER_SAVE: `${BASE_URL}invoice/container-save`,
  INVOICE_CONTAINER_ADD: `${BASE_URL}invoice/add-container`,
  INVOICE_CONTAINER_DELETE: `${BASE_URL}invoice/delete-container`,

  COUNTRY_LIST: `${BASE_URL}country`,
  COUNTRY_GET: `${BASE_URL}country/show`,
  COUNTRY_SAVE: `${BASE_URL}country/store`,
  COUNTRY_UPDATE: `${BASE_URL}country/update`,
  COUNTRY_DELETE: `${BASE_URL}country/delete`,

  BANKS_LIST: `${BASE_URL}banks`,
  BANKS_GET: `${BASE_URL}banks/show`,
  BANKS_SAVE: `${BASE_URL}banks/store`,
  BANKS_UPDATE: `${BASE_URL}banks/update`,
  BANKS_DELETE: `${BASE_URL}banks/delete`,

  SETTING_SAVE: `${BASE_URL}setting/store`,
  SETTING_GET: `${BASE_URL}setting/show`,
  SETTING_PASSWORD: `${BASE_URL}setting/password`,
  LOGIN_URL: `${BASE_URL}login`,
  STATES_LIST: `${BASE_URL}states`,
  LOGIN_USER: `${BASE_URL}me`,
  UPDATE_USER: `${BASE_URL}update-user`,

  PAYMENT_LIST: `${BASE_URL}payments`,
  PAYMENT_GET: `${BASE_URL}payments/show`,
  PAYMENT_SAVE: `${BASE_URL}payments/store`,
  PAYMENT_UPDATE: `${BASE_URL}payments/update`,
  PAYMENT_DELETE: `${BASE_URL}payments/delete`,

  LEDGER_LIST: `${BASE_URL}ledgers`,
  OUTSTANDING: `${BASE_URL}ledgers/outstanding`,

  PROFILE_LIST: `${BASE_URL}profile`,
  PROFILE_GET: `${BASE_URL}profile/show`,
  PROFILE_SAVE: `${BASE_URL}profile/store`,
  PROFILE_UPDATE: `${BASE_URL}profile/update`,
  PROFILE_DELETE: `${BASE_URL}profile/delete`,

  USER_LIST: `${BASE_URL}users`,
  USER_GET: `${BASE_URL}users/show`,
  USER_SAVE: `${BASE_URL}users/store`,
  USER_UPDATE: `${BASE_URL}users/update`,
  USER_DELETE: `${BASE_URL}users/delete`,

  ROLES_LIST: `${BASE_URL}roles`,
};
