import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { API_URL } from "../../shared/constant";
import axios from "axios";
import { toast } from "react-toastify";

const AddTransport = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [formdata, setFormData] = useState({
    id: null,
    name: "",
    gst_no: "",
    city: "",
  });

  const saveGrade = () => {
    setSubmitting(true);
    if (!formdata.name || !formdata.gst_no || !formdata.city) {
      toast.error("Please Fill Out This Field", {
        autoClose: 3000,
        position: "top-center",
      });
      setError(true);
      setSubmitting(false);
      return false;
    }
    {
      setError(false);
      const pUrl = id ? API_URL.TRANSPORT_UPDATE : API_URL.TRANSPORT_SAVE;
      axios.post(pUrl, formdata).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Updated", {
              autoClose: 3000,
              position: "top-center",
            });
            setTimeout(() => {
              navigate("/admin/transport", { replace: true });
            }, 100);
          } else {
            setTimeout(() => {
              navigate("/admin/transport", { replace: true });
            }, 100);
            resetForm();
            toast.success("Saved", { autoClose: 3000, position: "top-center" });
          }
        }
        setSubmitting(false);
      });
    }
  };

  const resetForm = () => {
    setFormData({
      id: null,
      name: "",
      gst_no: "",
      city: "",
    });
  };

  const onEdit = () => {
    axios.post(API_URL.TRANSPORT_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormData({
          id: res.data.data.id,
          name: res.data.data.name ? res.data.data.name : "",
          gst_no: res.data.data.gst_no ? res.data.data.gst_no : "",
          city: res.data.data.city ? res.data.data.city : "",
        });
      }
    });
  };

  const isShowError = (value) => {
    if (
      isError &&
      (!value || value == "" || value == null || value == undefined)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    id && onEdit();
  }, [id]);
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Transport</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/transport"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label
                    className={`form-label ${
                      isShowError(formdata.name) && "required-field"
                    }`}
                  >
                    Name*
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formdata.name}
                    className="form-control"
                    id="name"
                    onChange={(event) => {
                      setFormData({ ...formdata, name: event.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label
                    className={`form-label ${
                      isShowError(formdata.gst_no) && "required-field"
                    }`}
                  >
                    GST*
                  </label>
                  <input
                    type="text"
                    name="gst_no"
                    value={formdata.gst_no}
                    className="form-control"
                    id="gst_no"
                    onChange={(event) => {
                      setFormData({ ...formdata, gst_no: event.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label
                    className={`form-label ${
                      isShowError(formdata.city) && "required-field"
                    }`}
                  >
                    City*
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formdata.city}
                    className="form-control"
                    id="city"
                    onChange={(event) => {
                      setFormData({ ...formdata, city: event.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group text-end">
                <button
                  type="submit"
                  onClick={saveGrade}
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {isSubmitting && (
                      <Oval
                        height={20}
                        width={20}
                        color="#FAF9F6"
                        wrapperClass="me-2"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    )}
                    <div>Submit</div>
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTransport;
