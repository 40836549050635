import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import Loader from "../../shared/loader";
import Swal from "sweetalert2";
import axios from "axios";

const Party = () => {
  
  const [gridData, setGridData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchParam] = useState(["name", "city", "country"]);

  const getData = () => {
    axios.post(API_URL.PARTY_LIST, {}).then(
      (res) => {
        if (res.data.status === 1) {
          setGridData(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-right",
        });
      }
    );
  };

  const deleteData = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this - " + row.name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.post(API_URL.PARTY_DELETE, { id: row.id }).then(
          (res) => {
            toast.success("Buyer Deleted Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
            getData();
          },
          (error) => {
            setLoading(false);
            toast.error("Something went wrong..", {
              autoClose: 3000,
              position: "top-right",
            });
          }
        );
      }
    });
  };

  const searchData = (items) => {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem] && item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) > -1
        );
      });
    })
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>Buyer {gridData.length > 0 && <span className="badge badge-primary">{gridData.length}</span>}</h5>
          <div className="d-flex align-items-center">
            <div className="form-group me-2 mb-0">
              <input
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="form-control search-input"
                placeholder="Search Text"
              />
            </div>
            <Link to={"/admin/party/add"}>
              <button className="btn btn-md btn-primary">
                <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-table">
            <div className="card-body">
              {loading ? (
                <Loader></Loader>
              ) : (
                <div>
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th className="index-th">#</th>
                          <th>Name</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Address</th>
                          <th className="action-th">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchData(gridData).map((row, key) => {
                          return (
                            <tr key={key}>
                              <td className="index-td">{key + 1}</td>
                              <td>{row.name}</td>
                              <td>{row.city}</td>
                              <td>{row.country}</td>
                              <td>{row.address_one}</td>
                              <td className="action-td">
                                <Link
                                  to={"/admin/party/edit/" + row.id}
                                >
                                  <button
                                    className="btn btn-sm btn-primary me-2"
                                  >
                                    <i className="fa fa-edit me-1"></i> Edit
                                  </button>
                                </Link>

                                <button
                                  onClick={() => deleteData(row)}
                                  className="btn btn-sm btn-danger me-2"
                                >
                                  <i className="fa fa-trash me-1"></i> Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Party;
