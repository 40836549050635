import React, { useEffect, useState, useRef } from "react";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import * as XLSX from "xlsx";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const selectStyles = {
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginTop: 2,
    zIndex: 999,
  }),
};

const ContainerDetails = ({ invoiceId }) => {
  const ref = useRef();
  const defaultVal = {
    id: null,
    product_id: null,
    extra_product_id: null,
    plant_name: null,
    transport_name: "",
    no_of_box: "",
    no_of_pallet: "",
    truck_no: "",
    lr_no: "",
    container_no: "",
    container_size: "",
    e_seal_no: "",
    line_seal_no: "",
    net_weight: "",
    gross_weight: "",
    extra_net_weight: "",
    extra_gross_weight: "",
    tare_weight: "",
    permissible_weight: "",
  };
  const [productDrp, setProductDrp] = useState([]);
  const [transportName, setTransportName] = useState("");
  const [isImport, setImport] = useState(false);
  // const [permissibleWeight, setPermissibleWeight] = useState("");
  const [contForm, setContForm] = useState(defaultVal);
  const [companyData, setCompanyData] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const getCompanyDetail = () => {
    axios
      .post(API_URL.INVOICE_CONTAINER, { invoice_id: invoiceId })
      .then((res) => {
        if (res.data.status === 1) {
          setCompanyData(res.data.data);
        }
      });
  };

  const getProductData = () => {
    axios
      .post(API_URL.PRODUCT_BYSIZE_LIST, { invoice_id: invoiceId })
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          let pt = res.data.data.map((raw) => {
            return {
              label: `${raw.name}`,
              value: raw.id,
              size: raw.size,
            };
          });
          setProductDrp(pt);
        }
      });
  };

  const resetForm = () => {
    setShowAdd(false);
    setContForm(defaultVal);
  };

  const deleteCont = (row) => {
    console.log(row);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(API_URL.INVOICE_CONTAINER_DELETE, {
            id: row.id,
            invoice_id: invoiceId,
          })
          .then((res) => {
            if (res?.data?.status == 1) {
              getCompanyDetail();
              toast.success("Deleted successfully.", {
                autoClose: 3000,
                position: "top-right",
              });
            } else {
              toast.error(res.data.data, {
                autoClose: 3000,
                position: "top-right",
              });
            }
          });
      }
    });
  };

  const updateValue = (fieldName, fieldValue, k) => {
    let copyData = [...companyData];
    copyData[k][fieldName] = fieldValue;
    setCompanyData(copyData);
  };

  const updateContValue = (fieldName, fieldValue, k) => {
    let copyData = { ...contForm };
    copyData[fieldName] = fieldValue;
    setContForm(copyData);
  };

  const saveContainer = () => {
    setSubmitting(true);
    setError(false);
    let isValid = true;
    if (
      !contForm.product_id ||
      !contForm.no_of_box ||
      !contForm.no_of_pallet ||
      !contForm.truck_no ||
      !contForm.lr_no ||
      !contForm.container_no ||
      !contForm.e_seal_no ||
      !contForm.line_seal_no ||
      !contForm.net_weight ||
      !contForm.gross_weight ||
      !contForm.tare_weight ||
      !contForm.permissible_weight
    ) {
      isValid = false;
    }
    if (isValid) {
      let copyData = { ...contForm };
      copyData.invoice_id = invoiceId;
      axios.post(API_URL.INVOICE_CONTAINER_ADD, copyData).then((res) => {
        if (res.data.status === 1) {
          toast.success("Container details updated successfully.", {
            autoClose: 3000,
            position: "top-right",
          });
          resetForm();
          getCompanyDetail();
          setSubmitting(false);
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
          setSubmitting(false);
        }
      });
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      let importedData = [];

      sheetData.slice(0, -1).map((element, key) => {
        if (key === 0) {
          setTransportName(element["Transport Name"]);
        }
        let cType = {
          no_of_box: element["BOX"],
          no_of_pallet: element["PALLATS"],
          truck_no: element["Truck No."],
          lr_no: element["LR No."],
          container_no: element["CONTAINER NUMBER"],
          e_seal_no: element["E SEAL NUMBER"],
          line_seal_no: element["LINE SEAL NUMBER"],
          net_weight: element["Net Weight"],
          gross_weight: element["Gross Weight"],
          tare_weight: element["Container Tare Weight"],
          permissible_weight: element["PERMISSIBLE WEIGHT AS PER CSC"],
        };
        importedData.push(cType);
      });
      setCompanyData(importedData);
      setImport(true);
    };
    reader.readAsBinaryString(file);
  };

  const saveDetails = () => {
    setSubmitting(true);
    setError(false);
    let isValid = true;
    companyData.map((element) => {
      if (
        !element.no_of_box ||
        !element.no_of_pallet ||
        !element.truck_no ||
        !element.lr_no ||
        !element.container_no ||
        !element.e_seal_no ||
        !element.line_seal_no ||
        !element.net_weight ||
        !element.gross_weight ||
        !element.tare_weight ||
        !element.permissible_weight
      ) {
        isValid = false;
      }
    });

    if (transportName && isValid) {
      axios
        .post(API_URL.INVOICE_CONTAINER_SAVE, {
          invoice_id: invoiceId,
          transport_name: transportName,
          container_details: companyData,
          net_weight: getTotalNetWeight(),
          gross_weight: getTotalGrossWeight(),
        })
        .then((res) => {
          if (res.data.status === 1) {
            toast.success("Container details updated successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
            setSubmitting(false);
            getCompanyDetail();
          } else {
            toast.error(res.data.data, {
              autoClose: 3000,
              position: "top-right",
            });
            setSubmitting(false);
          }
          ref.current.value = "";
          setImport(false);
        });
    } else {
      ref.current.value = "";
      setError(true);
      setSubmitting(false);
      setImport(false);
      toast.error("Please enter all required field's");
    }
  };

  const getProdName = (pId) => {
    let prd = productDrp.find((d) => d.value === pId);
    return prd ? prd.label : "";
  };

  const getTotalNetWeight = () => {
    const copyData = [...companyData];
    let sum = 0;
    copyData.map((element) => {
      sum = sum + (element.net_weight ? parseFloat(element.net_weight) : 0);
      sum =
        sum +
        (element.extra_net_weight ? parseFloat(element.extra_net_weight) : 0);
    });
    return sum.toFixed(2);
  };

  const getTotalGrossWeight = () => {
    const copyData = [...companyData];
    let sum = 0;
    copyData.map((element) => {
      sum = sum + (element.gross_weight ? parseFloat(element.gross_weight) : 0);
      sum =
        sum +
        (element.extra_gross_weight
          ? parseFloat(element.extra_gross_weight)
          : 0);
    });
    return sum.toFixed(2);
  };

  useEffect(() => {
    isImport && saveDetails();
  }, [isImport]);

  useEffect(() => {
    if (invoiceId) {
      getCompanyDetail();
      getProductData();
    }
  }, [invoiceId]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          {showAdd && (
            <>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Transport Name<span className="required-text">*</span>
                    </label>
                    <input
                      name="transportName"
                      type="text"
                      value={contForm.transport_name}
                      className={`form-control ${
                        isError && !contForm.transport_name && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("transport_name", e.target.value)
                      }
                      placeholder="Transport Name"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Product<span className="required-text">*</span>
                    </label>
                    <Select
                      id="product_id"
                      name="product_id"
                      options={productDrp}
                      value={
                        productDrp.find(
                          (d) => d.value === contForm.product_id
                        ) || null
                      }
                      styles={selectStyles}
                      onChange={(e) => {
                        updateContValue("product_id", e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">Extra Product</label>
                    <Select
                      id="extra_product_id"
                      name="extra_product_id"
                      options={productDrp}
                      value={
                        productDrp.find(
                          (d) => d.value === contForm.extra_product_id
                        ) || null
                      }
                      styles={selectStyles}
                      onChange={(e) => {
                        updateContValue("extra_product_id", e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      No Of Box<span className="required-text">*</span>
                    </label>
                    <input
                      name="no_of_box"
                      type="text"
                      value={contForm.no_of_box ? contForm.no_of_box : ""}
                      className={`form-control ${
                        isError && !contForm.no_of_box && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("no_of_box", e.target.value)
                      }
                      placeholder="Box"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      No Of Pallets<span className="required-text">*</span>
                    </label>
                    <input
                      name="no_of_pallet"
                      type="text"
                      value={contForm.no_of_pallet ? contForm.no_of_pallet : ""}
                      className={`form-control ${
                        isError && !contForm.no_of_pallet && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("no_of_pallet", e.target.value)
                      }
                      placeholder="Pallets"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Truck No<span className="required-text">*</span>
                    </label>
                    <input
                      name="truck_no"
                      type="text"
                      value={contForm.truck_no ? contForm.truck_no : ""}
                      className={`form-control ${
                        isError && !contForm.truck_no && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("truck_no", e.target.value)
                      }
                      placeholder="Truck No"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      LR No.<span className="required-text">*</span>
                    </label>
                    <input
                      name="lr_no"
                      type="text"
                      value={contForm.lr_no ? contForm.lr_no : ""}
                      className={`form-control ${
                        isError && !contForm.lr_no && "error"
                      }`}
                      onChange={(e) => updateContValue("lr_no", e.target.value)}
                      placeholder="LR No."
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Container No.<span className="required-text">*</span>
                    </label>
                    <input
                      name="container_no"
                      type="text"
                      value={contForm.container_no ? contForm.container_no : ""}
                      className={`form-control ${
                        isError && !contForm.container_no && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("container_no", e.target.value)
                      }
                      placeholder="Container No"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      E Seal No.<span className="required-text">*</span>
                    </label>
                    <input
                      name="e_seal_no"
                      type="text"
                      value={contForm.e_seal_no ? contForm.e_seal_no : ""}
                      className={`form-control ${
                        isError && !contForm.e_seal_no && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("e_seal_no", e.target.value)
                      }
                      placeholder="E Seal No"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Line Seal No.<span className="required-text">*</span>
                    </label>
                    <input
                      name="line_seal_no"
                      type="text"
                      value={contForm.line_seal_no ? contForm.line_seal_no : ""}
                      className={`form-control ${
                        isError && !contForm.line_seal_no && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("line_seal_no", e.target.value)
                      }
                      placeholder="Line E Seal No"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Net Weight<span className="required-text">*</span>
                    </label>
                    <input
                      name="net_weight"
                      type="text"
                      value={contForm.net_weight ? contForm.net_weight : ""}
                      className={`form-control ${
                        isError && !contForm.net_weight && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("net_weight", e.target.value)
                      }
                      placeholder="Net Weight"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Gross Weight<span className="required-text">*</span>
                    </label>
                    <input
                      name="gross_weight"
                      type="text"
                      value={contForm.gross_weight ? contForm.gross_weight : ""}
                      className={`form-control ${
                        isError && !contForm.gross_weight && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("gross_weight", e.target.value)
                      }
                      placeholder="Gross Weight"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">Extra Net Weight</label>
                    <input
                      name="extra_net_weight"
                      type="text"
                      value={
                        contForm.extra_net_weight
                          ? contForm.extra_net_weight
                          : ""
                      }
                      className={`form-control`}
                      onChange={(e) =>
                        updateContValue("extra_net_weight", e.target.value)
                      }
                      placeholder="Extra Net Weight"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">Extra Gross Weight</label>
                    <input
                      name="extra_gross_weight"
                      type="text"
                      value={
                        contForm.extra_gross_weight
                          ? contForm.extra_gross_weight
                          : ""
                      }
                      className={`form-control`}
                      onChange={(e) =>
                        updateContValue("extra_gross_weight", e.target.value)
                      }
                      placeholder="Extra Gross Weight"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Tare Weight<span className="required-text">*</span>
                    </label>
                    <input
                      name="tare_weight"
                      type="text"
                      value={contForm.tare_weight ? contForm.tare_weight : ""}
                      className={`form-control ${
                        isError && !contForm.tare_weight && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("tare_weight", e.target.value)
                      }
                      placeholder="Tare Weight"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="transportName">
                      Permissible Weight<span className="required-text">*</span>
                    </label>
                    <input
                      name="permissible_weight"
                      type="text"
                      value={
                        contForm.permissible_weight
                          ? contForm.permissible_weight
                          : ""
                      }
                      className={`form-control ${
                        isError && !contForm.permissible_weight && "error"
                      }`}
                      onChange={(e) =>
                        updateContValue("permissible_weight", e.target.value)
                      }
                      placeholder="Permissible Weight"
                    />
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  onClick={resetForm}
                  className="btn btn-info me-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={saveContainer}
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    {isSubmitting && (
                      <Oval
                        height={20}
                        width={20}
                        color="#FAF9F6"
                        wrapperClass="me-2"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    )}
                    <div>Submit</div>
                  </div>
                </button>
              </div>
            </>
          )}
          {!showAdd && (
            <>
              <div className="d-flex justify-content-end">
                <input
                  type="file"
                  name="upload"
                  ref={ref}
                  id="upload"
                  style={{ width: "300px", height: "36px" }}
                  className="form-control me-2"
                  onChange={handleFileUpload}
                />
                <button
                  type="button"
                  onClick={() => setShowAdd(true)}
                  className="btn btn-primary mb-2"
                >
                  Add Container Details
                </button>
              </div>
              <table className="table table-bordered container-table">
                <thead>
                  <tr style={{ fontSize: 12 }}>
                    <th style={{ width: 25 }}>#</th>
                    <th>Product</th>
                    <th>Extra Product</th>
                    <th style={{ width: 65 }}>Box</th>
                    <th style={{ width: 65 }}>Pallets</th>
                    <th>Truck No.</th>
                    <th style={{ width: 70 }}>LR No.</th>
                    <th>Container No.</th>
                    <th>E Seal No.</th>
                    <th>Line Seal No.</th>
                    <th style={{ width: 95 }}>Net Weight</th>
                    <th style={{ width: 95 }}>Gross Weight</th>
                    <th style={{ width: 95 }}>Tare Weight</th>
                    <th style={{ width: 95 }}>Perm. Weight</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {companyData.map((row, k) => {
                    return (
                      <tr key={k}>
                        <td>{k + 1}</td>
                        <td style={{ width: 150, whiteSpace: "normal" }}>
                          {getProdName(row.product_id)}
                        </td>
                        <td style={{ width: 150, whiteSpace: "normal" }}>
                          {getProdName(row.extra_product_id)}
                        </td>
                        <td>{row.no_of_box}</td>
                        <td>{row.no_of_pallet}</td>
                        <td>{row.truck_no}</td>
                        <td>{row.lr_no}</td>
                        <td>{row.container_no}</td>
                        <td>{row.e_seal_no}</td>
                        <td>{row.line_seal_no}</td>
                        <td>
                          {row.net_weight}{" "}
                          {row.extra_net_weight
                            ? "/ " + row.extra_net_weight
                            : ""}
                        </td>
                        <td>
                          {row.gross_weight}{" "}
                          {row.extra_gross_weight
                            ? "/ " + row.extra_gross_weight
                            : ""}
                        </td>
                        <td>{row.tare_weight}</td>
                        <td>{row.permissible_weight}</td>
                        <td>
                          {row.id > 0 ? (
                            <>
                              <button
                                onClick={() => {
                                  setContForm(row);
                                  setShowAdd(true);
                                }}
                                type="button"
                                className="btn btn-sm btn-info"
                              >
                                <i className="fe fe-edit"></i>
                              </button>
                              <button
                                onClick={() => {
                                  deleteCont(row);
                                }}
                                type="button"
                                className="btn btn-sm btn-danger"
                              >
                                <i className="fe fe-trash"></i>
                              </button>
                            </>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan="10"></td>
                    <td
                      className="text-center"
                      style={{ fontWeight: 600, fontSize: "14px" }}
                    >
                      {getTotalNetWeight()}
                    </td>
                    <td
                      className="text-center"
                      style={{ fontWeight: 600, fontSize: "14px" }}
                    >
                      {getTotalGrossWeight()}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ContainerDetails;
