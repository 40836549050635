import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import ErrorText from "../../shared/error";
import axios from "axios";

const initialValue = {
  id: null,
  name: "",
  gst_no: "",
  city: "",
  state: "",
  country: "",
  permission_no: "",
  address_one: "",
  address_two: "",
  pincode: "",
  contact_person: "",
  contact_no: "",
  email: "",
};

const AddCompany = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [formValue, setFormValue] = useState(initialValue);
  const [isError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const resetForm = () => {
    setFormValue(initialValue);
  };

  const onEdit = () => {
    axios.post(API_URL.COMPANY_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          name: res.data.data.name,
          city: res.data.data.city,
          state: res.data.data.state,
          country: res.data.data.country,
          permission_no: res.data.data.permission_no,
          address_one: res.data.data.address_one,
          address_two: res.data.data.address_two,
          pincode: res.data.data.pincode,
          contact_person: res.data.data.contact_person,
          contact_no: res.data.data.contact_no,
          email: res.data.data.email,
          gst_no: res.data.data.gst_no,
        });
      }
    });
  };

  const onSave = () => {
    setSubmitting(true);
    setError(false);
    if (
      formValue.name &&
      formValue.gst_no &&
      formValue.address_one &&
      formValue.permission_no &&
      formValue.city &&
      formValue.state &&
      formValue.country &&
      formValue.contact_person
    ) {
      const pUrl = id ? API_URL.COMPANY_UPDATE : API_URL.COMPANY_SAVE;
      axios.post(pUrl, formValue).then((res) => {
        if (res.data.status === 1) {
          if (id) {
            toast.success("Company Updated Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          } else {
            resetForm();
            toast.success("Company Created Successfully.", {
              autoClose: 3000,
              position: "top-right",
            });
          }
          setTimeout(() => {
            navigate("/admin/company", { replace: true });
          }, 100);
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
        }
        setSubmitting(false);
      });
    } else {
      setError(true);
      setSubmitting(false);
      toast.error("Please enter all required field's");
    }
  };

  const setFormData = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    id && onEdit();
  }, [id]);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{id ? "Edit" : "Add"} Company</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link to={"/admin/company"} className="btn btn-secondary">
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={(event) => event.preventDefault()}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="name">
                    Name<span className="required-text">*</span>
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formValue.name}
                    onChange={(e) => setFormData("name", e.target.value)}
                    className={`form-control ${
                      isError && formValue.name === "" && "error"
                    }`}
                    placeholder="Enter Name"
                  />
                  {isError && formValue.name === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="gst_no">
                    GST No.<span className="required-text">*</span>
                  </label>
                  <input
                    id="gst_no"
                    name="gst_no"
                    type="text"
                    value={formValue.gst_no}
                    maxLength={15}
                    onChange={(e) =>
                      setFormData("gst_no", e.target.value.toUpperCase())
                    }
                    className={`form-control ${
                      isError && !formValue.gst_no && "error"
                    }`}
                    placeholder="Enter GST Number"
                  />
                  {isError && formValue.gst_no === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="permission_no">
                  Permission No.<span className="required-text">*</span>
                  </label>
                  <input
                    id="permission_no"
                    name="permission_no"
                    type="text"
                    value={formValue.permission_no}
                    onChange={(e) => setFormData("permission_no", e.target.value)}
                    className={`form-control ${
                      isError && formValue.permission_no === "" && "error"
                    }`}
                    placeholder="Enter Permission No."
                  />
                  {isError && formValue.permission_no === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="address1">
                    Address 1<span className="required-text">*</span>
                  </label>
                  <input
                    id="address1"
                    name="address1"
                    type="text"
                    value={formValue.address_one}
                    onChange={(e) => setFormData("address_one", e.target.value)}
                    className={`form-control ${
                      isError && formValue.address_one === "" && "error"
                    }`}
                    placeholder="Enter Address 1"
                  />
                  {isError && formValue.address_one === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="address2">Address 2</label>
                  <input
                    id="address2"
                    name="address2"
                    type="text"
                    value={formValue.address_two}
                    onChange={(e) => setFormData("address_two", e.target.value)}
                    className="form-control"
                    placeholder="Enter Address 2"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="city">
                    City<span className="required-text">*</span>
                  </label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    value={formValue.city}
                    onChange={(e) => setFormData("city", e.target.value)}
                    className={`form-control ${
                      isError && formValue.city === "" && "error"
                    }`}
                    placeholder="Enter City"
                  />
                  {isError && formValue.city === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="state">
                    State<span className="required-text">*</span>
                  </label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    value={formValue.state}
                    onChange={(e) => setFormData("state", e.target.value)}
                    className={`form-control ${
                      isError && formValue.state === "" && "error"
                    }`}
                    placeholder="Enter State"
                  />
                  {isError && formValue.state === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="country">
                    Country<span className="required-text">*</span>
                  </label>
                  <input
                    id="country"
                    name="country"
                    type="text"
                    value={formValue.country}
                    onChange={(e) => setFormData("country", e.target.value)}
                    className={`form-control ${
                      isError && formValue.country === "" && "error"
                    }`}
                    placeholder="Enter Country"
                  />
                  {isError && formValue.country === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="pincode">
                    Pincode<span className="required-text">*</span>
                  </label>
                  <input
                    id="pincode"
                    name="pincode"
                    type="text"
                    value={formValue.pincode}
                    onChange={(e) => setFormData("pincode", e.target.value)}
                    className={`form-control ${
                      isError && formValue.pincode === "" && "error"
                    }`}
                    placeholder="Enter Pincode"
                  />
                  {isError && formValue.pincode === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="contact_person">
                    Contact Person<span className="required-text">*</span>
                  </label>
                  <input
                    id="contact_person"
                    name="contact_person"
                    type="text"
                    value={formValue.contact_person}
                    onChange={(e) =>
                      setFormData("contact_person", e.target.value)
                    }
                    className={`form-control ${
                      isError && formValue.contact_person === "" && "error"
                    }`}
                    placeholder="Enter Contact Person Name"
                  />
                  {isError && formValue.contact_person === "" && <ErrorText />}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="contact_no">Phone</label>
                  <input
                    id="contact_no"
                    name="contact_no"
                    type="text"
                    value={formValue.contact_no}
                    onChange={(e) => setFormData("contact_no", e.target.value)}
                    className="form-control"
                    placeholder="Enter Phone Number"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    value={formValue.email}
                    onChange={(e) => setFormData("email", e.target.value)}
                    className="form-control"
                    placeholder="Enter Email Address"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={resetForm}
                className="btn btn-secondary me-2"
              >
                Reset
              </button>
              <button
                type="submit"
                onClick={onSave}
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <div className="d-flex justify-content-between align-items-center">
                  {isSubmitting && (
                    <Oval
                      height={20}
                      width={20}
                      color="#FAF9F6"
                      wrapperClass="me-2"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                  <div>Submit</div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCompany;
