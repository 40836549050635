import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import { FaPrint } from "react-icons/fa6";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import moment from "moment";

const AddDispatch = () => {
  const contentToPrint = useRef(null);
  const [type, setType] = useState(1);
  const navigate = useNavigate();
  let { id } = useParams();
  const [userInfo, setUserInfo] = useState({});

  const selectStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: 2,
      zIndex: 999,
    }),
  };

  const [formValue, setFormValue] = useState({ invoice_id: id, items: [] });
  const [isError, setError] = useState(false);
  const [isDispatched, setIsDispatched] = useState(false);
  const [productDrp, setProductDrp] = useState([]);
  const [totalContainer, setTotalContainer] = useState([]);

  const resetForm = () => {
    setFormValue({ invoice_id: id, items: [] });
  };

  const getProductData = () => {
    axios.post(API_URL.INVOICE_PRODUCT_LIST, { invoice_id: id }).then((res) => {
      if (res?.data?.data?.length > 0) {
        let pt = res.data.data.map((raw) => {
          return {
            label: `${raw.name}`,
            value: raw.id,
            size: raw.size,
          };
        });
        setProductDrp(pt);
      }
    });
  };

  const onEdit = () => {
    axios.post(API_URL.DISPATCH_GET, { invoice_id: id }).then((res) => {
      if (res.data.status === 1 && res.data.data && res.data.data.length) {
        setIsDispatched(true);
        let copyData = { ...formValue };
        copyData.items = [];
        res.data.data.forEach((row) => {
          copyData.items.push({
            srno: 1,
            product_id: row.product_id,
            pallet: row.pallet,
            container: row.container,
            qty: row.qty,
            sqmt: row.sqmt,
            price_sqmt: row.price_sqmt,
            total: row.total,
            invoice: row.invoice,
            product: row.product,
          });
        });
        let totalCon = copyData.items.map((d) => {
          return { name: d.product.size.name };
        });

        let groupingViaCommonProperty = Object.values(
          totalCon.reduce((acc, current) => {
            acc[current.name] = acc[current.name] ?? [];
            acc[current.name].push(current);
            return acc;
          }, {})
        );
        setTotalContainer(groupingViaCommonProperty);
        setFormValue(copyData);
      } else {
        setIsDispatched(false);
        addRecord();
      }
    });
  };

  const getMe = () => {
    axios.post(API_URL.LOGIN_USER, {}).then((res) => {
      setUserInfo(res.data);
    });
  };

  const onSave = () => {
    setError(false);
    if (formValue.invoice_id && formValue.items.length) {
      axios.post(API_URL.DISPATCH_SAVE, formValue).then((res) => {
        if (res.data.status === 1) {
          toast.success("Dispatch Updated Successfully.", {
            autoClose: 3000,
            position: "top-right",
          });
          // setTimeout(() => {
          //   navigate("/admin/series", { replace: true });
          // }, 100);
          onEdit();
        } else {
          toast.error(res.data.data, {
            autoClose: 3000,
            position: "top-right",
          });
        }
      });
    } else {
      setError(true);
      toast.error("Please enter all required field's");
    }
  };

  const addRecord = () => {
    let copyData = { ...formValue };
    copyData.items.push({
      srno: 0,
      product_id: null,
      pallet: null,
      container: null,
      qty: null,
      sqmt: null,
      price_sqmt: null,
      total: null,
    });
    setFormValue(copyData);
  };

  const removeRecord = (index) => {
    let copyData = { ...formValue };
    copyData.items.splice(index, 1);
    setFormValue(copyData);
  };

  const handlePrint = useReactToPrint({
    documentTitle: "",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const getTotal = (key) => {
    let sum = 0;
    formValue.items.map((element) => {
      sum = sum + (element[key] ? parseFloat(element[key]) : 0);
    });
    return sum.toFixed(2);
  };

  useEffect(() => {
    if (id) {
      onEdit();
      getProductData();
    }
    getMe();
  }, [id]);

  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <div className="d-flex justify-content-between w-100">
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className={`btn ${
                  type === 1 ? "btn btn-danger" : "btn-outline-secondary"
                } `}
                style={{ lineHeight: "1px", height: "34px" }}
                onClick={() => setType(1)}
              >
                {id ? "Edit" : "Add"} Dispatch
              </button>
              <button
                disabled={!isDispatched}
                type="button"
                className={`btn ${
                  type === 2 ? "btn btn-danger" : "btn-outline-secondary"
                } `}
                style={{ lineHeight: "1px", height: "34px" }}
                onClick={() => setType(2)}
              >
                View
              </button>
            </div>
            <div>
              {type === 2 && (
                <button
                  className="btn btn-danger mb-2"
                  onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <FaPrint size={15} />
                    &nbsp; Print
                  </div>
                </button>
              )}
              {type === 1 && (
                <button
                  className="btn btn-secondary ms-2 mb-2"
                  onClick={() => {
                    navigate("/admin/invoices");
                  }}
                >
                  <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {type === 1 && (
        <div className="card">
          <div className="card-body">
            <div>
              <h5>Dispatch Details</h5>
              <div className="plus-plus-header">
                <div style={{ width: "5%" }}>Srno</div>
                <div style={{ width: "30%" }}>Design</div>
                <div style={{ width: "10%" }}>Pallets</div>
                <div style={{ width: "10%" }}>Container</div>
                <div style={{ width: "10%" }}>Qty/Box</div>
                <div style={{ width: "10%" }}>Total Pallet</div>
                <div style={{ width: "10%" }}>Total Box</div>
                <div style={{ width: "10%" }}>Container Number</div>
                <div style={{ width: "5%" }}>
                  <FaCirclePlus onClick={addRecord} size={28} color="#7539ff" />
                </div>
              </div>
              {formValue.items.map((element, key) => {
                return (
                  <div className="plus-plus-section" key={key}>
                    <div className="item" style={{ width: "5%" }}>
                      <input
                        id="srno"
                        name="srno"
                        className="form-control text-center"
                        type="text"
                        disabled
                        value={key + 1}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].srno = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "30%" }}>
                      <Select
                        id="product_id"
                        name="product_id"
                        options={productDrp}
                        value={
                          productDrp.find(
                            (d) => d.value === element.product_id
                          ) || null
                        }
                        styles={selectStyles}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].product_id = e.value;
                          copyData.items[key].pallet =
                            e.size.box_per_pallet_one +
                            "x" +
                            e.size.pallet_per_container_one;
                          if (
                            e.size.box_per_pallet_two &&
                            e.size.pallet_per_container_two
                          ) {
                            copyData.items[key].pallet =
                              copyData.items[key].pallet +
                              ", " +
                              e.size.box_per_pallet_two +
                              "x" +
                              e.size.pallet_per_container_two;
                          }
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="pallete"
                        name="pallete"
                        className="form-control text-center"
                        type="text"
                        disabled={!element.product_id}
                        value={element.pallet}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].pallet = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="container"
                        name="container"
                        className="form-control text-center"
                        type="text"
                        disabled={!element.product_id}
                        value={element.container}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].container = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="qty"
                        name="qty"
                        className="form-control text-center"
                        type="number"
                        disabled={!element.product_id}
                        value={element.qty}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          let value = e.target.value.replace(/\D/g, "");
                          copyData.items[key].qty = value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="sqmt"
                        name="sqmt"
                        className="form-control text-center"
                        type="text"
                        value={element.sqmt}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].sqmt = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <CurrencyInput
                        id="price_sqmt"
                        name="price_sqmt"
                        className="form-control text-center"
                        decimalsLimit={2}
                        value={element.price_sqmt}
                        onValueChange={(value, name, values) => {
                          let copyData = { ...formValue };
                          copyData.items[key].price_sqmt = value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item" style={{ width: "10%" }}>
                      <input
                        id="total"
                        name="total"
                        className="form-control text-end"
                        type="text"
                        value={element.total}
                        onChange={(e) => {
                          let copyData = { ...formValue };
                          copyData.items[key].total = e.target.value;
                          setFormValue(copyData);
                        }}
                      />
                    </div>
                    <div className="item text-center" style={{ width: "5%" }}>
                      <FaCircleMinus
                        onClick={() => {
                          removeRecord(key);
                        }}
                        size={28}
                        color="#6c757d"
                      />
                    </div>
                  </div>
                );
              })}
              <div className="d-flex justify-content-end mt-3">
                <button
                  type="button"
                  onClick={resetForm}
                  className="btn btn-secondary me-2"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  onClick={onSave}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 2 && (
        <div className="print-div" ref={contentToPrint}>
          <table
            style={{
              width: "100%",
              border: "1px solid #000000",
              borderBottom: "none",
            }}
          >
            <tr>
              <td
                style={{
                  width: "50%",
                  padding: "0px",
                  position: "relative",
                  borderRight: "1px solid #000000",
                }}
              >
                <img src={userInfo?.logo} width={"100%"} className="logo-img" />
              </td>
              <td style={{ width: "50%" }}>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000",
                        borderBottom: "1px solid #000000",
                      }}
                      colSpan={3}
                    >
                      DISPATCH DETAIL
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000",
                        borderRight: "1px solid #000000",
                      }}
                    >
                      EXPORT INOICE NO
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000",
                        borderRight: "1px solid #000000",
                      }}
                    >
                      SIZE/CONTAINER
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      LOADING DATE
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000",
                        borderTop: "1px solid #000000",
                        borderRight: "1px solid #000000",
                      }}
                    >
                      {formValue.items &&
                        formValue.items.length > 0 &&
                        formValue.items[0].invoice.invoice_no}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000",
                        borderTop: "1px solid #000000",
                        borderRight: "1px solid #000000",
                      }}
                    >
                      <div className="d-flex justify-content-center flex-warp">
                        {totalContainer.map((element, key) => {
                          return (
                            <div className="me-2" key={key}>
                              {element[0].name} - {element.length} CON,
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000",
                        borderTop: "1px solid #000000",
                      }}
                    >
                      {formValue.items &&
                        formValue.items.length > 0 &&
                        moment(
                          formValue.items[0].invoice.date,
                          "YYYY-MM-DD"
                        ).format("DD-MM-YYYY")}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table
            className="dispatch-table"
            style={{ width: "100%", border: "1px solid #000000" }}
          >
            <thead>
              <th>SRNO</th>
              <th>SIZE</th>
              <th>DESIGN NUMBER</th>
              <th>DESIGN</th>
              <th>PALLETS</th>
              <th>CONTAINER</th>
              <th>QTY/BOX</th>
              <th>TOTAL PALLET</th>
              <th>TOTAL BOX</th>
              <th>CONTAINER NUMBER</th>
            </thead>
            <tbody>
              {formValue.items.map((element, key) => {
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{element.product?.size?.name}</td>
                    <td>
                      <span style={{ display: "block" }}>
                        {element.product?.name}
                      </span>
                      <span style={{ display: "block" }}>
                        {element.product?.series?.name}
                      </span>
                    </td>
                    <td>
                      <img
                        src={element.product?.image}
                        alt="image"
                        style={{ maxWidth: "100%", height: "50px" }}
                      />
                    </td>
                    <td>{element.pallet}</td>
                    <td>{element.container}</td>
                    <td>{element.qty}</td>
                    <td>{element.sqmt}</td>
                    <td>{element.price_sqmt}</td>
                    <td>{element.total}</td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={4}>Total</td>
                <td>0</td>
                <td>{getTotal("container")}</td>
                <td>{getTotal("qty")}</td>
                <td>{getTotal("sqmt")}</td>
                <td>{getTotal("price_sqmt")}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default AddDispatch;
