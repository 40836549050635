import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../shared/constant";
import { FaPrint } from "react-icons/fa6";
import { useReactToPrint } from "react-to-print";
import { ToWords } from "to-words";
import moment from "moment";
import axios from "axios";
import Select from "react-select";

const selectStyles = {
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginTop: 2,
    zIndex: 999,
  }),
};
const initialValue = {
  id: null,
  party_id: null,
  party: {},
  consignee: {},
  company: {},
  f_year: "",
  pi_no: "",
  pi_number: "",
  pi_type: "",
  po_number: "",
  date: "",
  items: [
    {
      srno: 1,
      product_id: null,
      size: {},
      pallets: null,
      container: null,
      qty_per_box: null,
      qty_per_sq_mt: null,
      basic_rate_box: null,
      total: null,
    },
  ],
  tax: "0.1",
  igst: "0",
  exchange_rate: "0",
  total_amount: "0",
  containerNote: "",
  remarks: "",
  boxes: "",
  roundOff: "",
  notes: [],
  extra_note: "",
  port_of_loading: "",
  port_of_discharge: "",
  country_of_origin: "",
  country_of_destination: "",
  container_size: "",
  bank: {},
};

const ViewInvoices = () => {
  const contentToPrint = useRef(null);
  const navigate = useNavigate();
  let { id } = useParams();
  const [formValue, setFormValue] = useState(initialValue);
  const [mfgValue, setmfgValue] = useState([]);
  const [cantValue, setCantValue] = useState([]);
  const [containerOne, setContainerOne] = useState([]);
  const [containerTwo, setContainerTwo] = useState([]);
  const [copyContainers, setCopyContainers] = useState([]);
  const [plants, setPlants] = useState([]);
  const [mfgWeightValue, setMfgWeightValue] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [type, setType] = useState(1);
  const [hsnCodes, setHsnCodes] = useState("");
  const [plantName, setPlantName] = useState("All");
  const [isPrint, setIsPrint] = useState(true);
  const [isCustomPrint, setIsCustomPrint] = useState(true);

  const onEdit = () => {
    axios.post(API_URL.INVOICE_GET, { id: id }).then((res) => {
      if (res.data.status === 1) {
        setFormValue({
          id: res.data.data.id,
          party_id: res.data.data.party_id,
          party: res.data.data.party,
          consignee: res.data.data.consignee,
          company: res.data.data.company,
          pi_no: res.data.data.pi_no,
          f_year: res.data.data.f_year,
          pi_number: res.data.data.pi_number,
          pi_type: res.data.data.pi_type,
          po_number: res.data.data.po_number,
          invoice_no: res.data.data.invoice_no,
          invoice_gst_no: res.data.data.invoice_gst_no,
          payment_terms: res.data.data.payment_terms,
          payment_term_one: res.data.data.payment_term_one,
          payment_term_two: res.data.data.payment_term_two,
          date: moment(res.data.data.date, "YYYY-MM-DD").format("DD-MM-YYYY"),
          items: res.data.data.items.map((element, index) => {
            return {
              srno: index + 1,
              product: element.product,
              product_id: element.product_id,
              size: {},
              pallet: element.pallet,
              container: element.container,
              qty_per_box: element.qty_per_box,
              qty_sqmt: element.qty_sqmt,
              basic_rate_box: element.rate,
              total: element.amount,
              category: element?.product?.category?.name,
              hsn_code: element?.product?.hsn_code,
            };
          }),
          tax: res.data.data.tax_rate,
          igst: res.data.data.igst ? res.data.data.igst : 0,
          tax_amount: res.data.data.tax_amount,
          containerNote: res.data.data.container_note,
          remarks: res.data.data.remark,
          boxes: res.data.data.boxes_note,
          roundOff: res.data.data.round_off,
          freight: res.data.data.freight,
          notes: res.data.data.notes || [],
          extra_note: res.data.data.extra_note,
          total_amount: res.data.data.total_amount,
          total_container: res.data.data.total_container,
          total_pallets: res.data.data.total_pallets,
          total_sqmt: res.data.data.total_sqmt,
          total_qty: res.data.data.total_qty,
          sub_total: res.data.data.sub_total,
          port_of_loading: res.data.data.port_of_loading,
          port_of_discharge: res.data.data.port_of_discharge,
          country_of_origin: res.data.data.country_of_origin,
          country_of_destination: res.data.data.country_of_destination,
          container_size: res.data.data.container_size,
          bank: res.data.data.bank,
          gross_weight: res.data.data.gross_weight,
          extra_gross_weight: res.data.data.extra_gross_weight,
          net_weight: res.data.data.net_weight,
          extra_net_weight: res.data.data.extra_net_weight,
          booking_no: res.data.data.booking_no,
          file_no: res.data.data.file_no,
          permission_no: res.data.data.permission_no,
          container_size: res.data.data.container_size,
          exchange_rate: res.data.data.exchange_rate,
          total_rs: res.data.data.total_rs,
          supply_meant_for: res.data.data.supply_meant_for,
          we_shall_claim: res.data.data.we_shall_claim,
        });

        let hsnCode = [];
        res.data.data.items.forEach((row) => {
          if (hsnCode.indexOf(row.product.hsn_code) === -1) {
            hsnCode.push(row.product.hsn_code);
          }
        });
        setHsnCodes(hsnCode.toString());
      }
      getMe();
    });
    axios.post(API_URL.INVOICE_COMPANY, { invoice_id: id }).then((res) => {
      if (res.data.status === 1) {
        res.data.data.forEach((r) => {
          r.invoice_date = r.invoice_date
            ? moment(r.invoice_date, "YYYY-MM-DD").format("DD/MM/YYYY")
            : null;
        });
        setmfgValue(res.data.data);
      }
    });
    axios.post(API_URL.INVOICE_CONTAINER, { invoice_id: id }).then((res) => {
      if (res.data.status === 1) {
        setCantValue(res.data.data);
        let plant_names = ["All"];
        let compWeight = {};
        let cntOne = [];
        let cntTwo = [];
        res.data.data.forEach((row, k) => {
          if (!compWeight[row.product_id]) {
            compWeight[row.product_id] = {
              net_weight: 0,
              gross_weight: 0,
              tare_weight: 0,
              extra_net_weight: 0,
              extra_gross_weight: 0,
            };
          }
          if (row.extra_product_id && row.extra_product_id > 0) {
            if (!compWeight[row.extra_product_id]) {
              compWeight[row.extra_product_id] = {
                net_weight: 0,
                gross_weight: 0,
                tare_weight: 0,
                extra_net_weight: 0,
                extra_gross_weight: 0,
              };
            }
          }
          if (row.net_weight) {
            compWeight[row.product_id]["net_weight"] += parseFloat(
              row.net_weight
            );
            if (
              row.extra_product_id &&
              row.extra_product_id > 0 &&
              row.extra_net_weight
            ) {
              compWeight[row.extra_product_id]["extra_net_weight"] +=
                parseFloat(row.extra_net_weight);
              compWeight[row.extra_product_id]["net_weight"] += parseFloat(
                row.extra_net_weight
              );
            }
          }
          if (row.gross_weight) {
            compWeight[row.product_id]["gross_weight"] += parseFloat(
              row.gross_weight
            );
            if (
              row.extra_product_id &&
              row.extra_product_id > 0 &&
              row.extra_gross_weight
            ) {
              compWeight[row.extra_product_id]["extra_gross_weight"] +=
                parseFloat(row.extra_gross_weight);
              compWeight[row.extra_product_id]["gross_weight"] += parseFloat(
                row.extra_gross_weight
              );
            }
          }
          if (row.tare_weight) {
            compWeight[row.product_id]["tare_weight"] += parseFloat(
              row.tare_weight
            );
          }
          if (row.plant_name && plant_names.indexOf(row.plant_name) == -1) {
            plant_names.push(row.plant_name);
          }
          if (
            row.extra_plant_name &&
            plant_names.indexOf(row.extra_plant_name) == -1
          ) {
            plant_names.push(row.extra_plant_name);
          }
          if (k < res.data.data.length / 2) {
            cntOne.push(row);
          } else {
            cntTwo.push(row);
          }
        });
        if (cntTwo.length < cntOne.length) {
          cntTwo.push({
            srno: "",
            container_no: "",
            e_seal_no: "",
            line_seal_no: "",
          });
        }
        cntTwo.forEach((r, k) => {
          r.srno = cntOne.length + (k + 1);
        });
        let vals = plant_names.map((row) => {
          return { label: row, value: row };
        });
        setPlants(vals);
        setCopyContainers(res.data.data);
        setContainerOne(cntOne);
        setContainerTwo(cntTwo);
        setMfgWeightValue(compWeight);
        console.log(cntOne);
        console.log(cntTwo);
      }
    });
  };

  const changePlant = (cid) => {
    if (cid == "All") {
      setCopyContainers(cantValue);
    } else {
      let filterItems = cantValue.filter(
        (r) => r.plant_name == cid || r.extra_plant_name == cid
      );
      setCopyContainers(filterItems);
    }
  };

  const getMe = () => {
    axios.post(API_URL.LOGIN_USER, {}).then((res) => {
      setUserInfo(res.data);
    });
  };

  const docPrint = () => {
    setIsPrint(false);
  };

  const customPrint = () => {
    setIsCustomPrint(false);
  };

  useEffect(() => {
    !isPrint && handlePrint(null, () => contentToPrint.current);
  }, [isPrint]);

  useEffect(() => {
    !isCustomPrint && handlePrint(null, () => contentToPrint.current);
  }, [isCustomPrint]);

  const handlePrint = useReactToPrint({
    documentTitle: "",
    onBeforePrint: () => {
      console.log("before printing...");
    },
    onAfterPrint: () => {
      setIsPrint(true);
      setIsCustomPrint(true);
    },
    removeAfterPrint: true,
  });

  const displayAmountInWord = () => {
    if (formValue.total_amount) {
      const toWords = new ToWords();
      return toWords.convert(parseFloat(formValue.total_amount));
    }
  };

  const getTotalBox = () => {
    let sum = 0;
    cantValue.map((element) => {
      sum = sum + (element.no_of_box ? parseFloat(element.no_of_box) : 0);
    });
    return sum.toFixed(0);
  };

  const getTotalPallet = () => {
    let sum = 0;
    cantValue.map((element) => {
      sum = sum + (element.no_of_pallet ? parseFloat(element.no_of_pallet) : 0);
    });
    return sum.toFixed(2);
  };

  const twoDigit = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2);
    }
    return value;
  };

  const getPlantName = (element) => {
    if (plantName == "All") {
      let pName = element.plant_name;
      if (element.extra_plant_name) {
        pName = pName + " / " + element.extra_plant_name;
      }
      return pName;
    } else {
      if (plantName == element.plant_name) {
        return element.plant_name;
      } else {
        return element.extra_plant_name;
      }
    }
  };

  const getNetWeightName = (element) => {
    if (plantName == "All") {
      let pName = element.net_weight;
      if (element.extra_net_weight) {
        pName = pName + " / " + element.extra_net_weight;
      }
      return pName;
    } else {
      if (plantName == element.plant_name) {
        return element.net_weight;
      } else {
        return element.extra_net_weight;
      }
    }
  };

  const getGrossWeightName = (element) => {
    if (plantName == "All") {
      let pName = element.gross_weight;
      if (element.extra_gross_weight) {
        pName = pName + " / " + element.extra_gross_weight;
      }
      return pName;
    } else {
      if (plantName == element.plant_name) {
        return element.gross_weight;
      } else {
        return element.extra_gross_weight;
      }
    }
  };

  const getTotalNetWeight = () => {
    const copyData = [...copyContainers];
    let sum = 0;
    copyData.map((element) => {
      if (plantName == "All") {
        sum = sum + (element.net_weight ? parseFloat(element.net_weight) : 0);
        sum =
          sum +
          (element.extra_net_weight ? parseFloat(element.extra_net_weight) : 0);
      } else {
        if (plantName == element.plant_name) {
          sum = sum + (element.net_weight ? parseFloat(element.net_weight) : 0);
        } else {
          sum =
            sum +
            (element.extra_net_weight
              ? parseFloat(element.extra_net_weight)
              : 0);
        }
      }
    });
    return sum.toFixed(2);
  };

  const getTotalGrossWeight = () => {
    const copyData = [...copyContainers];
    let sum = 0;
    copyData.map((element) => {
      if (plantName == "All") {
        sum =
          sum + (element.gross_weight ? parseFloat(element.gross_weight) : 0);
        sum =
          sum +
          (element.extra_gross_weight
            ? parseFloat(element.extra_gross_weight)
            : 0);
      } else {
        if (plantName == element.plant_name) {
          sum =
            sum + (element.gross_weight ? parseFloat(element.gross_weight) : 0);
        } else {
          sum =
            sum +
            (element.extra_gross_weight
              ? parseFloat(element.extra_gross_weight)
              : 0);
        }
      }
    });
    return sum.toFixed(2);
  };

  useEffect(() => {
    onEdit();
  }, [id]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            className={`btn ${
              type === 1 ? "btn btn-danger" : "btn-outline-secondary"
            } `}
            style={{ lineHeight: "1px", height: "34px" }}
            onClick={() => setType(1)}
          >
            EXPORT INVOICE
          </button>
          <button
            type="button"
            className={`btn ${
              type === 2 ? "btn btn-danger" : "btn-outline-secondary"
            } `}
            style={{ lineHeight: "1px", height: "34px" }}
            onClick={() => setType(2)}
          >
            PACKING LIST
          </button>
          <button
            type="button"
            className={`btn ${
              type === 3 ? "btn btn-danger" : "btn-outline-secondary"
            } `}
            style={{ lineHeight: "1px", height: "34px" }}
            onClick={() => setType(3)}
          >
            EXPORT INVOICE ANNEXURE
          </button>
          <button
            type="button"
            className={`btn ${
              type === 4 ? "btn btn-danger" : "btn-outline-secondary"
            } `}
            style={{ lineHeight: "1px", height: "34px" }}
            onClick={() => setType(4)}
          >
            VGM FORM
          </button>
          <button
            type="button"
            className={`btn ${
              type === 5 ? "btn btn-danger" : "btn-outline-secondary"
            } `}
            style={{ lineHeight: "1px", height: "34px" }}
            onClick={() => setType(5)}
          >
            VGM ANNEXURE
          </button>
          <button
            type="button"
            className={`btn ${
              type === 6 ? "btn btn-danger" : "btn-outline-secondary"
            } `}
            style={{ lineHeight: "1px", height: "34px" }}
            onClick={() => setType(6)}
          >
            Data Entry
          </button>
        </div>
        <div>
          {(type === 1 || type === 2) && (
            <button
              className="btn btn-dark mb-2 me-2"
              onClick={() => {
                docPrint();
              }}
            >
              <div className="d-flex align-items-center">
                <FaPrint size={15} />
                &nbsp; Doc Print
              </div>
            </button>
          )}
          {type === 6 ? (
            <button
              className="btn btn-danger mb-2"
              onClick={() => {
                customPrint();
              }}
            >
              <div className="d-flex align-items-center">
                <FaPrint size={15} />
                &nbsp; Print
              </div>
            </button>
          ) : (
            <button
              className="btn btn-danger mb-2"
              onClick={() => {
                handlePrint(null, () => contentToPrint.current);
              }}
            >
              <div className="d-flex align-items-center">
                <FaPrint size={15} />
                &nbsp; Print
              </div>
            </button>
          )}

          <button
            className="btn btn-secondary ms-2 mb-2"
            onClick={() => {
              navigate("/admin/invoices");
            }}
          >
            <i className="fa fa-backward me-2" aria-hidden="true"></i>Back
          </button>
        </div>
      </div>
      {type === 1 && (
        <div className="print-div" ref={contentToPrint}>
          <div className="invoice-print-table">
            <div className="page-title">EXPORT INVOICE</div>
            <div className="regular-text border-bottom text-center py-1">
              {formValue?.supply_meant_for}
            </div>
            <div className="d-flex border-bottom">
              <div
                style={{ width: "40%", borderRight: "1px solid black" }}
                className="px-2 py-1"
              >
                <div className="small-text">EXPORTER / SHIPPER :</div>
                <div className="text-center">
                  <div className="bold-text">{userInfo?.name}</div>
                  <div className="regular-text">
                    {userInfo?.address_one},<br />{" "}
                    {userInfo?.address_two && `${userInfo?.address_two},`}
                  </div>
                  <div className="bold-text">
                    {userInfo?.city}-{userInfo?.pincode}, ({userInfo?.state})
                    India.
                  </div>
                  <div className="regular-text">
                    Contact Number: {userInfo?.contact_no}
                  </div>
                </div>
              </div>
              <div style={{ width: "60%" }}>
                <div className="regular-text-bg border-bottom text-center">
                  Bill of Supply / Export Invoice No.& GST Invoice No. & Date :
                </div>
                <div className="d-flex border-bottom">
                  <div style={{ width: "75%" }} className="border-end">
                    <div>
                      <div className="d-flex">
                        <div
                          style={{ width: "30%" }}
                          className="bold-text border-end px-2 border-bottom"
                        >
                          Export Invoice No.
                        </div>
                        <div
                          style={{ width: "70%" }}
                          className="regular-text px-2 border-bottom"
                        >
                          {formValue.invoice_no}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div
                          style={{ width: "30%" }}
                          className="bold-text border-end px-2"
                        >
                          GST Invoice No.
                        </div>
                        <div
                          style={{ width: "70%" }}
                          className="regular-text px-2"
                        >
                          {formValue.invoice_gst_no}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <div className="bold-text text-center border-bottom">
                      Date
                    </div>
                    <div className="bold-text text-center">
                      {formValue.date}
                    </div>
                  </div>
                </div>
                <div className="small-text px-2">Payment Terms :</div>
                <div
                  className="bold-text text-center py-1"
                  style={{ marginTop: "-15px" }}
                >
                  <div>{formValue.payment_term_one}</div>
                  <div>{formValue.payment_term_two}</div>
                </div>
              </div>
            </div>
            <div className="d-flex border-bottom">
              <div style={{ width: "40%", borderRight: "1px solid black" }}>
                <div className="px-2 py-1 border-bottom">
                  <div className="small-text">Consignee :</div>
                  <div className="text-center">
                    <div className="bold-text">{formValue?.party?.name}</div>
                    <div className="regular-text">
                      {formValue?.party?.address_one},<br />
                      {formValue?.party?.address_two &&
                        `${formValue?.party?.address_two}`}
                    </div>
                    <div className="bold-text">
                      {formValue?.party?.city}, ({formValue?.party?.state})
                      {formValue?.party?.country}
                    </div>
                    <div className="regular-text">
                      Contact Number: {formValue?.party?.contact_no}
                    </div>
                  </div>
                </div>
                <div className="d-flex border-bottom">
                  <div style={{ width: "100%" }}>
                    <div className="d-flex">
                      <div
                        style={{ width: "50%" }}
                        className="regular-text border-bottom border-end py-1 px-2"
                      >
                        Country of Origin of Goods
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="bold-text border-bottom py-1 px-2"
                      >
                        {formValue.country_of_origin}
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div
                        style={{ width: "50%" }}
                        className="regular-text border-end py-1 px-2"
                      >
                        Country of Final Destination
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="bold-text py-1 px-2"
                      >
                        {formValue.country_of_destination}
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div
                        style={{ width: "50%" }}
                        className="border-end py-1 px-2"
                      >
                        <div className="small-text">Vessel & Voyage</div>
                        <div className="text-center bold-text"></div>
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="bold-text py-1 px-2"
                      >
                        <div className="small-text">Port of Loading</div>
                        <div className="text-center bold-text">
                          {formValue.port_of_loading}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div
                        style={{ width: "50%" }}
                        className="border-end py-1 px-2"
                      >
                        <div className="small-text">Port of Discharge</div>
                        <div className="text-center bold-text">
                          {formValue.port_of_discharge}
                        </div>
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="bold-text py-1 px-2"
                      >
                        <div className="small-text">Place of Delivery</div>
                        <div className="text-center bold-text">
                          {formValue.port_of_discharge}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "60%" }}>
                <div className="small-text px-2 border-bottom">
                  Buyer (if other than consignee): NOTIFY PARTY
                  {formValue.consignee && formValue.consignee.name && (
                    <div className="text-center">
                      <div className="bold-text">
                        {formValue?.consignee?.name}
                      </div>
                      <div className="regular-text">
                        {formValue?.consignee?.address_one},<br />
                        {formValue?.consignee?.address_two &&
                          `${formValue?.consignee?.address_two}`}
                      </div>
                      <div className="bold-text">
                        {formValue?.consignee?.city}, (
                        {formValue?.consignee?.state})
                        {formValue?.consignee?.country}
                      </div>
                      <div className="regular-text">
                        Contact Number: {formValue?.consignee?.contact_no}
                      </div>
                    </div>
                  )}
                </div>
                <div className="regular-text-bg border-bottom text-center">
                  Bank Details
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "20%" }}
                    className="bold-text border-end py-1 px-2 fz12"
                  >
                    Account Name
                  </div>
                  <div
                    style={{ width: "80%" }}
                    className="bold-text py-1 px-2 fz12"
                  >
                    {formValue?.bank?.account_name}
                  </div>
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "20%" }}
                    className="bold-text border-end py-1 px-2 fz12"
                  >
                    Bank Name
                  </div>
                  <div
                    style={{ width: "80%" }}
                    className="bold-text py-1 px-2 fz12"
                  >
                    {formValue?.bank?.bank_name}
                  </div>
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "20%" }}
                    className="bold-text border-end py-1 px-2 fz12"
                  >
                    Bank Address
                  </div>
                  <div
                    style={{ width: "80%" }}
                    className="bold-text py-1 px-2 fz12"
                  ></div>
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "20%" }}
                    className="bold-text border-end py-1 px-2 fz12"
                  >
                    Account Number
                  </div>
                  <div
                    style={{ width: "80%" }}
                    className="bold-text py-1 px-2 fz12"
                  >
                    {formValue?.bank?.account_number}
                  </div>
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "20%" }}
                    className="bold-text border-end py-1 px-2 fz12"
                  >
                    Swift Code
                  </div>
                  <div
                    style={{ width: "80%" }}
                    className="bold-text py-1 px-2 fz12"
                  >
                    {formValue?.bank?.bank_code}
                  </div>
                </div>
                <div className="d-flex">
                  <div style={{ width: "33%" }} className="border-end">
                    <div className="bold-text py-1 px-2 fz12 border-bottom">
                      BILL OF LADING NO.
                    </div>
                  </div>
                  <div style={{ width: "33%" }} className="border-end">
                    <div className="bold-text py-1 px-2 fz12 border-bottom">
                      S.BILL NO
                    </div>
                  </div>
                  <div style={{ width: "34%" }}>
                    <div className="bold-text  py-1 px-2 fz12 border-bottom">
                      DTD
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex border-bottom">
              <div className="d-flex" style={{ width: "40%" }}>
                <div
                  className="regular-text-bg text-center border-end center-align"
                  style={{ width: "30%" }}
                >
                  Marks & Nos./ Container No
                </div>
                <div
                  className="regular-text-bg text-center border-end center-align"
                  style={{ width: "70%" }}
                >
                  Description of Goods
                </div>
              </div>
              <div className="d-flex" style={{ width: "60%" }}>
                <div
                  className="regular-text-bg text-center border-end"
                  style={{ width: "45%" }}
                >
                  <div className="border-bottom">Quantity</div>
                  <div className="d-flex">
                    <div
                      className="text-center border-end"
                      style={{ width: "50%" }}
                    >
                      Boxes
                    </div>
                    <div className="text-center" style={{ width: "50%" }}>
                      Sq. Mtr (M2)
                    </div>
                  </div>
                </div>
                <div
                  className="regular-text-bg text-center border-end center-align"
                  style={{ width: "25%" }}
                >
                  Rate in USD ( $ per Sq.Mtr)
                </div>
                <div
                  className="regular-text-bg text-center  center-align"
                  style={{ width: "30%" }}
                >
                  Amount in USD $
                </div>
              </div>
            </div>
            {formValue.items.map((element, key) => {
              return (
                <div className="d-flex border-bottom" key={key}>
                  <div className="d-flex border-end" style={{ width: "40%" }}>
                    <div className="bold-text text-center w-100">
                      {key === 0 && (
                        <div
                          className="bold-text px-2 border-bottom"
                          style={{ textAlign: "left" }}
                        >
                          {formValue.total_container} X{" "}
                          {formValue.container_size}
                        </div>
                      )}
                      <div>
                        Manufacture :-{" "}
                        {isPrint ? element?.product?.company?.name : ""}
                      </div>
                      <div>
                        {element?.product?.category?.name}{" "}
                        {element?.product?.series &&
                          `(${element?.product?.series?.name})`}{" "}
                      </div>
                      <div>
                        Size - {element?.product?.size?.name} = 1 Box ={" "}
                        {element?.product?.size?.pcs_per_box} PCS ={" "}
                        {element.product?.size?.sqmt_area} Sq. Mtr
                      </div>
                      <div>HSN CODE - {element?.product?.hsn_code}</div>
                    </div>
                  </div>
                  <div className="d-flex" style={{ width: "60%" }}>
                    <div
                      className="regular-text text-center border-end center-align"
                      style={{ width: "22.4%" }}
                    >
                      {element.qty_per_box}
                    </div>
                    <div
                      className="regular-text text-center border-end center-align"
                      style={{ width: "22.6%" }}
                    >
                      {twoDigit(element.qty_sqmt)}
                    </div>
                    <div
                      className="regular-text text-center border-end center-align"
                      style={{ width: "25%" }}
                    >
                      ${twoDigit(element.basic_rate_box)}
                    </div>
                    <div
                      className="regular-text text-center center-align"
                      style={{ width: "30%" }}
                    >
                      ${twoDigit(element.total)}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="d-flex border-bottom">
              <div className="d-flex border-end" style={{ width: "40%" }}>
                <div className="bold-text text-center w-100">Total</div>
              </div>
              <div className="d-flex" style={{ width: "60%" }}>
                <div
                  className="regular-text text-center border-end center-align"
                  style={{ width: "22.4%" }}
                >
                  {formValue.total_qty}
                </div>
                <div
                  className="regular-text text-center border-end center-align"
                  style={{ width: "22.6%" }}
                >
                  {twoDigit(formValue.total_sqmt)}
                </div>
                <div
                  className="regular-text text-center border-end center-align"
                  style={{ width: "25%" }}
                ></div>
                <div
                  className="regular-text text-center center-align"
                  style={{ width: "30%" }}
                >
                  ${twoDigit(formValue.sub_total)}
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex" style={{ width: "60%" }}>
                <div style={{ width: "25%" }}>
                  <div className="regular-text-bg text-center border-end center-align border-bottom">
                    Exchange Rate
                  </div>
                  <div className="regular-text text-center border-end center-align border-bottom">
                    {isPrint ? twoDigit(formValue.exchange_rate) : "0.00"}
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div className="regular-text-bg text-center border-end center-align border-bottom">
                    F.O.B. Value
                  </div>
                  <div className="regular-text text-center border-end center-align border-bottom">
                    ${twoDigit(formValue.total_amount)}
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div className="regular-text-bg text-center border-end center-align border-bottom">
                    Value In Rs.
                  </div>
                  <div className="regular-text text-center border-end center-align border-bottom">
                    {isPrint ? twoDigit(formValue.total_rs) : "0.00"}
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div className="regular-text-bg text-center border-end center-align border-bottom">
                    IGST
                  </div>
                  <div className="regular-text text-center border-end center-align border-bottom">
                    {isPrint
                      ? formValue.igst
                        ? ((formValue.total_rs * formValue.igst) / 100).toFixed(
                            2
                          )
                        : 0
                      : "0.00"}
                  </div>
                </div>
              </div>
              <div className="d-flex" style={{ width: "40%" }}>
                <div style={{ width: "55%" }}>
                  <div className="regular-text text-center border-end center-align border-bottom">
                    Round Up
                  </div>
                  <div className="regular-text text-center border-end center-align border-bottom">
                    Occean Fright
                  </div>
                </div>
                <div style={{ width: "45%" }}>
                  <div className="regular-text text-center  center-align border-bottom">
                    ${twoDigit(formValue.roundOff)}
                  </div>
                  <div className="regular-text text-center  center-align border-bottom">
                    ${twoDigit(formValue.freight || "0.00")}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div
                style={{ width: "20%" }}
                className="bold-text text-center center-align border-end border-bottom"
              >
                Amount Payable (Inwords )
              </div>
              <div
                style={{ width: "50%" }}
                className="bold-text px-2 py-2 border-end border-bottom fz12"
              >
                {displayAmountInWord()}
              </div>
              <div
                style={{ width: "12%" }}
                className="bold-text text-center center-align border-end border-bottom"
              >
                TOTAL USD
              </div>
              <div
                style={{ width: "18%" }}
                className="bold-text text-center center-align border-bottom"
              >
                ${twoDigit(formValue.total_amount)}
              </div>
            </div>
            {isPrint ? (
              <div className="bold-text text-center border-bottom fz12">
                Export Under LUT : File No. : {formValue.file_no} ,{" "}
                {formValue.supply_meant_for}
              </div>
            ) : null}
            {isPrint && (
              <div className="bold-text text-center border-bottom fz12">
                {formValue.we_shall_claim}
              </div>
            )}
            {isPrint ? (
              <>
                {" "}
                <div className="regular-text-bg text-center border-bottom">
                  Manufacturing Details
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "30%" }}
                    className="bold-text text-center border-end"
                  >
                    Manufactur Name
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="bold-text text-center border-end"
                  >
                    GST Number
                  </div>
                  <div
                    style={{ width: "10%" }}
                    className="bold-text text-center border-end"
                  >
                    Invoice No.
                  </div>
                  <div
                    style={{ width: "10%" }}
                    className="bold-text text-center border-end"
                  >
                    Invoice Dt.
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="bold-text text-center border-end"
                  >
                    EPCG Lic. Number
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="bold-text text-center"
                  >
                    EPCG Lic. Date
                  </div>
                </div>
                {mfgValue.map((element, key) => {
                  return (
                    <div className="d-flex border-bottom" key={key}>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.company_name}
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.company_gst}
                      </div>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.invoice_no}
                      </div>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.invoice_date}
                      </div>
                      <div
                        style={{ width: "15%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.epcg_lic_number}
                      </div>
                      <div
                        style={{ width: "15%" }}
                        className="regular-text text-center"
                      >
                        {element.epcg_date}
                      </div>
                    </div>
                  );
                })}{" "}
              </>
            ) : null}
            <div className="regular-text-bg text-center border-bottom">
              Container Details
            </div>
            <div className="d-flex border-bottom">
              <div className="w-50 d-flex">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  S.No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  Container No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  E Seal No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  Line Seal No.
                </div>
              </div>
              <div className="w-50 d-flex">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  S.No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  Container No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  E Seal No.
                </div>
                <div style={{ width: "30%" }} className="bold-text text-center">
                  Line Seal No.
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="w-50">
                {containerOne.map((element, key) => {
                  return (
                    <div className="d-flex border-bottom w-100" key={key}>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {key + 1}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.container_no}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.e_seal_no}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.line_seal_no}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-50">
                {containerTwo.map((element, key) => {
                  return (
                    <div className="d-flex border-bottom w-100" key={key}>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {(element.container_no && element.srno) ? element.srno : "-"}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.container_no ? element.container_no : "-"}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.e_seal_no ? element.e_seal_no : "-"}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.line_seal_no ? element.line_seal_no : "-"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex">
              <div style={{ width: "70%" }} className="bold-text border-end">
                <div className="bold-text border-bottom px-2">
                  NET WEIGHT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {getTotalNetWeight()}
                </div>
                <div className="bold-text border-bottom px-2">
                  GROSS WEIGHT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {getTotalGrossWeight()}
                </div>
                <div className="bold-text border-bottom px-2">
                  GST Registration No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {userInfo?.gst_no}
                </div>
                <div className="bold-text border-bottom px-2">
                  Import Export Code No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {userInfo?.iec_code} DT. 01/02/2022
                </div>
                <div className="small-text px-2">
                  Declaration : We declare that this Performa Invoice shows the
                  actual price of the Goods described and that all particualrs
                  are true and correct
                </div>
              </div>
              <div
                style={{ width: "30%", padding: "2px" }}
                className="bold-text text-center"
              >
                <img
                  src={userInfo?.signature}
                  style={{
                    maxWidth: "100%",
                    height: "80px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 2 && (
        <div className="print-div" ref={contentToPrint}>
          <div className="invoice-print-table">
            <div className="page-title">PACKING LIST</div>
            <div className="regular-text border-bottom text-center py-1">
              {formValue?.supply_meant_for}
            </div>
            <div className="d-flex border-bottom">
              <div
                style={{ width: "40%", borderRight: "1px solid black" }}
                className="px-2 py-1"
              >
                <div className="small-text">EXPORTER / SHIPPER :</div>
                <div className="text-center">
                  <div className="bold-text">{userInfo?.name}</div>
                  <div className="regular-text">
                    {userInfo?.address_one},<br />{" "}
                    {userInfo?.address_two && `${userInfo?.address_two},`}
                  </div>
                  <div className="bold-text">
                    {userInfo?.city}-{userInfo?.pincode}, ({userInfo?.state})
                    India.
                  </div>
                  <div className="regular-text">
                    Contact Number: {userInfo?.contact_no}
                  </div>
                </div>
              </div>
              <div style={{ width: "60%" }}>
                <div className="regular-text-bg border-bottom text-center">
                  Bill of Supply / Export Invoice No.& GST Invoice No. & Date :
                </div>
                <div className="d-flex border-bottom">
                  <div style={{ width: "75%" }} className="border-end">
                    <div>
                      <div className="d-flex">
                        <div
                          style={{ width: "30%" }}
                          className="bold-text border-end px-2 border-bottom"
                        >
                          Export Invoice No.
                        </div>
                        <div
                          style={{ width: "70%" }}
                          className="regular-text px-2 border-bottom"
                        >
                          {formValue.invoice_no}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div
                          style={{ width: "30%" }}
                          className="bold-text border-end px-2"
                        >
                          GST Invoice No.
                        </div>
                        <div
                          style={{ width: "70%" }}
                          className="regular-text px-2"
                        >
                          {formValue.invoice_gst_no}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <div className="bold-text text-center border-bottom">
                      Date
                    </div>
                    <div className="bold-text text-center">
                      {formValue.date}
                    </div>
                  </div>
                </div>
                <div className="small-text px-2">Payment Terms :</div>
                <div
                  className="bold-text text-center py-1"
                  style={{ marginTop: "-15px" }}
                >
                  <div>{formValue.payment_term_one}</div>
                  <div>{formValue.payment_term_two}</div>
                </div>
              </div>
            </div>
            <div className="d-flex border-bottom">
              <div
                style={{ width: "40%", borderRight: "1px solid black" }}
                className="px-2 py-1"
              >
                <div className="small-text">Consignee :</div>
                <div className="text-center">
                  <div className="bold-text">{formValue?.party?.name}</div>
                  <div className="regular-text">
                    {formValue?.party?.address_one},<br />
                    {formValue?.party?.address_two &&
                      `${formValue?.party?.address_two}`}
                  </div>
                  <div className="bold-text">
                    {formValue?.party?.city}, ({formValue?.party?.state})
                    {formValue?.party?.country}
                  </div>
                  <div className="regular-text">
                    Contact Number: {formValue?.party?.contact_no}
                  </div>
                </div>
              </div>
              <div style={{ width: "60%" }}>
                <div className="small-text px-2">
                  Buyer (if other than consignee): NOTIFY PARTY
                  {formValue.consignee && formValue.consignee.name && (
                    <div className="text-center">
                      <div className="bold-text">
                        {formValue?.consignee?.name}
                      </div>
                      <div className="regular-text">
                        {formValue?.consignee?.address_one},<br />
                        {formValue?.consignee?.address_two &&
                          `${formValue?.consignee?.address_two}`}
                      </div>
                      <div className="bold-text">
                        {formValue?.consignee?.city}, (
                        {formValue?.consignee?.state})
                        {formValue?.consignee?.country}
                      </div>
                      <div className="regular-text">
                        Contact Number: {formValue?.consignee?.contact_no}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex border-bottom">
              <div style={{ width: "40%" }} className="border-end">
                <div className="d-flex">
                  <div
                    style={{ width: "50%" }}
                    className="regular-text border-bottom border-end py-1 px-2"
                  >
                    Country of Origin of Goods
                  </div>
                  <div
                    style={{ width: "50%" }}
                    className="bold-text border-bottom py-1 px-2"
                  >
                    {formValue.country_of_origin}
                  </div>
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "50%" }}
                    className="regular-text border-end py-1 px-2"
                  >
                    Country of Final Destination
                  </div>
                  <div style={{ width: "50%" }} className="bold-text py-1 px-2">
                    {formValue.country_of_destination}
                  </div>
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "50%" }}
                    className="border-end py-1 px-2"
                  >
                    <div className="small-text">Vessel & Voyage</div>
                    <div className="text-center bold-text"></div>
                  </div>
                  <div style={{ width: "50%" }} className="bold-text py-1 px-2">
                    <div className="small-text">Port of Loading</div>
                    <div className="text-center bold-text">
                      {formValue.port_of_loading}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    style={{ width: "50%" }}
                    className="border-end py-1 px-2"
                  >
                    <div className="small-text">Port of Discharge </div>
                    <div className="text-center bold-text">
                      {formValue.port_of_discharge}
                    </div>
                  </div>
                  <div style={{ width: "50%" }} className="bold-text py-1 px-2">
                    <div className="small-text">Place of Delivery</div>
                    <div className="text-center bold-text">
                      {formValue.port_of_discharge}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "60%" }} className="d-flex">
                <div style={{ width: "50%" }} className="border-end">
                  <div className="bold-text border-bottom py-1 px-2">
                    BILL OF LADING NO.
                  </div>
                </div>
                <div style={{ width: "25%" }} className="border-end">
                  <div className="bold-text border-bottom py-1 px-2">
                    S.BILL NO
                  </div>
                </div>
                <div style={{ width: "25%" }} className="border-end">
                  <div className="bold-text border-bottom py-1 px-2">DTD</div>
                </div>
              </div>
            </div>
            <div className="d-flex border-bottom">
              <div className="d-flex" style={{ width: "40%" }}>
                <div
                  className="regular-text-bg text-center border-end center-align"
                  style={{ width: "30%" }}
                >
                  Marks & Nos./ Container No
                </div>
                <div
                  className="regular-text-bg text-center border-end center-align"
                  style={{ width: "70%" }}
                >
                  Description of Goods
                </div>
              </div>
              <div className="d-flex" style={{ width: "60%" }}>
                <div
                  className="regular-text-bg text-center border-end"
                  style={{ width: "45%" }}
                >
                  <div className="border-bottom">Quantity</div>
                  <div className="d-flex">
                    <div
                      className="text-center border-end"
                      style={{ width: "50%" }}
                    >
                      Boxes
                    </div>
                    <div className="text-center" style={{ width: "50%" }}>
                      Sq. Mtr (M2)
                    </div>
                  </div>
                </div>
                <div
                  className="regular-text-bg text-center border-end center-align"
                  style={{ width: "15%" }}
                >
                  No Of Pallets
                </div>
                <div
                  className="regular-text-bg text-center border-end center-align"
                  style={{ width: "20%" }}
                >
                  Net Weight (In Kg)
                </div>
                <div
                  className="regular-text-bg text-center center-align"
                  style={{ width: "20%" }}
                >
                  Gross Weight (In Kg.)
                </div>
              </div>
            </div>
            {formValue.items.map((element, key) => {
              return (
                <div className="d-flex border-bottom" key={key}>
                  <div className="d-flex border-end" style={{ width: "40%" }}>
                    <div className="bold-text text-center w-100">
                      {key === 0 && (
                        <div
                          className="bold-text px-2 border-bottom"
                          style={{ textAlign: "left" }}
                        >
                          {formValue.total_container} X{" "}
                          {formValue.container_size}
                        </div>
                      )}
                      <div>
                        Manufacture :-{" "}
                        {isPrint ? element?.product?.company?.name : ""}
                      </div>
                      <div>
                        {element?.product?.category?.name}{" "}
                        {element?.product?.series &&
                          `(${element?.product?.series?.name})`}{" "}
                      </div>
                      <div>
                        Size - {element?.product?.size?.name} = 1 Box ={" "}
                        {element?.product?.size?.pcs_per_box} PCS ={" "}
                        {element.product?.size?.sqmt_area} Sq. Mtr
                      </div>
                      <div>HSN CODE - {element?.product?.hsn_code}</div>
                    </div>
                  </div>
                  <div className="d-flex" style={{ width: "60%" }}>
                    <div
                      className="regular-text text-center border-end center-align"
                      style={{ width: "22.4%" }}
                    >
                      {element.qty_per_box}
                    </div>
                    <div
                      className="regular-text text-center border-end center-align"
                      style={{ width: "22.6%" }}
                    >
                      {element.qty_sqmt}
                    </div>
                    <div
                      className="regular-text text-center border-end center-align"
                      style={{ width: "15%" }}
                    >
                      {element.pallet}
                    </div>
                    <div
                      className="regular-text text-center border-end center-align"
                      style={{ width: "20%" }}
                    >
                      {mfgWeightValue[element.product_id]
                        ? mfgWeightValue[element.product_id]["net_weight"]
                        : null}
                    </div>
                    <div
                      className="regular-tex text-center center-align"
                      style={{ width: "20%" }}
                    >
                      {mfgWeightValue[element.product_id]
                        ? mfgWeightValue[element.product_id]["gross_weight"]
                        : null}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="d-flex border-bottom">
              <div className="d-flex border-end" style={{ width: "40%" }}>
                <div className="bold-text text-center w-100">Total</div>
              </div>
              <div className="d-flex" style={{ width: "60%" }}>
                <div
                  className="regular-text text-center border-end center-align"
                  style={{ width: "22.4%" }}
                >
                  {formValue.total_qty}
                </div>
                <div
                  className="regular-text text-center border-end center-align"
                  style={{ width: "22.6%" }}
                >
                  {formValue.total_sqmt}
                </div>
                <div
                  className="regular-text text-center border-end center-align"
                  style={{ width: "15%" }}
                >
                  {formValue.total_pallets}
                </div>
                <div
                  className="regular-text text-center border-end center-align"
                  style={{ width: "20%" }}
                >
                  {getTotalNetWeight()}
                </div>
                <div
                  className="regular-tex text-center center-align"
                  style={{ width: "20%" }}
                >
                  {getTotalGrossWeight()}
                </div>
              </div>
            </div>
            {isPrint ? (
              <div className="bold-text text-center border-bottom">
                Export Under LUT : File No. : {formValue.file_no} ,{" "}
                {formValue?.supply_meant_for}
              </div>
            ) : null}
            <div className="bold-text text-center border-bottom">
              {formValue.we_shall_claim}
            </div>
            {isPrint ? (
              <>
                <div className="regular-text-bg text-center border-bottom">
                  Manufacturing Details
                </div>
                <div className="d-flex border-bottom">
                  <div
                    style={{ width: "30%" }}
                    className="bold-text text-center border-end"
                  >
                    Manufactur Name
                  </div>
                  <div
                    style={{ width: "20%" }}
                    className="bold-text text-center border-end"
                  >
                    GST Number
                  </div>
                  <div
                    style={{ width: "10%" }}
                    className="bold-text text-center border-end"
                  >
                    Invoice No.
                  </div>
                  <div
                    style={{ width: "10%" }}
                    className="bold-text text-center border-end"
                  >
                    Invoice Dt.
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="bold-text text-center border-end"
                  >
                    EPCG Lic. Number
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="bold-text text-center"
                  >
                    EPCG Lic. Date
                  </div>
                </div>
                {mfgValue.map((element, key) => {
                  return (
                    <div className="d-flex border-bottom" key={key}>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.company_name}
                      </div>
                      <div
                        style={{ width: "20%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.company_gst}
                      </div>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.invoice_no}
                      </div>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.invoice_date}
                      </div>
                      <div
                        style={{ width: "15%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.epcg_lic_number}
                      </div>
                      <div
                        style={{ width: "15%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.epcg_date}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
            <div className="regular-text-bg text-center border-bottom">
              Container Details
            </div>
            <div className="d-flex border-bottom">
              <div className="w-50 d-flex">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  S.No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  Container No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  E Seal No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  Line Seal No.
                </div>
              </div>
              <div className="w-50 d-flex">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  S.No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  Container No.
                </div>
                <div
                  style={{ width: "30%" }}
                  className="bold-text text-center border-end"
                >
                  E Seal No.
                </div>
                <div style={{ width: "30%" }} className="bold-text text-center">
                  Line Seal No.
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="w-50">
                {containerOne.map((element, key) => {
                  return (
                    <div className="d-flex border-bottom w-100" key={key}>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {key + 1}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.container_no}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.e_seal_no}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.line_seal_no}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-50">
                {containerTwo.map((element, key) => {
                  return (
                    <div className="d-flex border-bottom w-100" key={key}>
                      <div
                        style={{ width: "10%" }}
                        className="regular-text text-center border-end"
                      >
                        {(element.container_no && element.srno) ? element.srno : "-"}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.container_no ? element.container_no : "-"}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.e_seal_no ? element.e_seal_no : "-"}
                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="regular-text text-center border-end"
                      >
                        {element.line_seal_no ? element.line_seal_no : "-"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex">
              <div style={{ width: "70%" }} className="bold-text border-end">
                <div className="small-text border-bottom px-2">
                  GOODS ARE PACKED IN CORRUGATED BOX THEN PUT IN WOODEN PALLET
                  AND WRAPPING BY STRECH FILM
                </div>
                <div className="bold-text border-bottom px-2">
                  GST Registration No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {userInfo?.gst_no}
                </div>
                <div className="bold-text border-bottom px-2">
                  Import Export Code No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {userInfo?.iec_code} DT. 01/02/2022
                </div>
                <div className="bold-text px-2">
                  CERTIFIED THAT GOODS ARE INDIAN ORIGIN
                </div>
              </div>
              <div
                style={{ width: "30%", padding: "2px" }}
                className="bold-text text-center"
              >
                <img
                  src={userInfo?.signature}
                  style={{
                    maxWidth: "100%",
                    height: "80px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 3 && (
        <div className="print-div" ref={contentToPrint}>
          <div className="invoice-print-table">
            <div className="page-title">
              OFFICE OF THE SUPERINTENDENT OF CENTRAL GST
            </div>
            <div className="d-flex py-2 px-2 justify-content-between border-bottom">
              <div>
                Range: <span className="bold-text">VI MORBI (WV0401)</span>
              </div>
              <div>
                Division: : <span className="bold-text">II-MORBI (WV04)</span>
              </div>
              <div>
                Commissinorate : <span className="bold-text">RAJKOT (WV)</span>
              </div>
            </div>
            <div className="small-text text-center py-2 px-2 border-bottom">
              EXAMINATION REPORT FOR SELF SEALED CONTAINER (
              <span className="small-text-bold">PERMISSION NO.</span>
              {formValue.permission_no}, AS PER C.B.E.C.CIRCULAR NO.26/2017 -
              Customs Dt. 01.07.2017 & 36/2017 - Customs Dt. 28.08.17 read with
              P.N.32/2017/CCP/JMR Dated 10/07/2017.
            </div>
            <div className="d-flex py-2 px-2 justify-content-between border-bottom">
              <div>
                C. No.: <span className="bold-text"></span>
              </div>
              <div>
                Date : <span className="bold-text"></span>
              </div>
              <div>
                Shipping Bill No. : <span className="bold-text"></span>
              </div>
              <div>
                Date : <span className="bold-text"></span>
              </div>
            </div>
            <div className="p-1 bold-text text-center border-bottom">
              EXAMINATION REPORT FOR FACTORY SEALED PACKAGES / CONTAINER
            </div>
            <div className="py-2">
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  1
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Name of the Exporter
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  {userInfo?.name}
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  2
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  (a) Import Export Code No.
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  {userInfo?.iec_code}
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  GST Registration No.:
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  {userInfo?.gst_no}
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (b) Branch Code
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text"></div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (c) BIN (PAN based Business)
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text"></div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  3
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Name of the Manufacturer{" "}
                  <small>(if different from the exporter)</small>
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  {mfgValue.length > 0 && <>{formValue?.company?.name}</>}
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  4
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Factory Address
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  {formValue?.company?.address_one}{" "}
                  {formValue?.company?.address_two}, {formValue?.company?.city},{" "}
                  {formValue?.company?.state}, {formValue?.company?.pincode}
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  5
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Date of Examination
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  {formValue.date}
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  6
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Name & Designation of the Examining
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Shri
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  officer inspection / EO / PO
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Inspector of Central GST ,
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  7
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Name & Designation of the Supervising
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Shri
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  officer Appraiser / Superintendent
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Superintendent of Central GST ,
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  8
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  (a) Name of the Commissinorate Divi. / Range
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div
                  style={{ width: "40%" }}
                  className="bold-text d-flex justify-content-between"
                >
                  <div>RAJKOT ,</div>
                  <div>Divi : MORBI,</div>
                  <div>Range : V- MORBI</div>
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (b) Location Code
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div
                  style={{ width: "40%" }}
                  className="bold-text d-flex justify-content-between"
                >
                  <div>17</div> <div>1709</div> <div>170905</div>
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  9
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Particulars of Export Invoice
                </div>
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text"></div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (a) Export Invoice No
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Inv. No. {formValue.invoice_no}, Date-{formValue.date}
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (b) Total Number of Packages
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  {formValue.total_qty} &nbsp;&nbsp;Box
                  &nbsp;&nbsp;=&nbsp;&nbsp; {formValue.total_sqmt}{" "}
                  &nbsp;&nbsp;Sq.Mtr (M2) In &nbsp;&nbsp;=&nbsp;&nbsp;{" "}
                  {formValue.total_pallets} &nbsp;&nbsp;Pallets
                  <div>
                    Net Weight &nbsp;:&nbsp; {getTotalNetWeight()}Kg.
                    &nbsp;&nbsp;Gross Weight&nbsp;&nbsp;:&nbsp;&nbsp;
                    {getTotalGrossWeight()}Kg.
                  </div>
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (c) Name and Address of the Consignee
                  <br />
                  (Abroad)
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  <div className="bold-text">{formValue?.party?.name}</div>
                  <div className="regular-text">
                    {formValue?.party?.address_one},<br />
                    {formValue?.party?.address_two &&
                      `${formValue?.party?.address_two}`}
                  </div>
                  <div className="bold-text">
                    {formValue?.party?.city}, ({formValue?.party?.state})
                    {formValue?.party?.country}
                  </div>
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  10
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  (a) In the description of the goods, the quantity
                  <br />
                  and their value as per the particulars
                  <br />
                  furnished in the export invoice.
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Yes
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (b) Where sample is drawn for being foreword
                  <br />
                  to Port of Export
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text"></div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (c) If yes the number of the seal of the Packing
                  <br />
                  containing the sample
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  N.A.
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  11
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  Central Excise / Customs Seal No
                </div>
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text"></div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  11
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  (a) For Non Containerised Cargo Seal No.
                  <br />
                  No. of Packages
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  N.A.
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "40%" }} className="bold-text">
                  (b) For Containerised Cargo
                </div>
                <div style={{ width: "5%" }} className="text-center">
                  :
                </div>
                <div style={{ width: "40%" }} className="bold-text">
                  No. of Pack.stuffed in to the cont
                </div>
              </div>
              <div className="d-flex py-1 px-3">
                <table style={{ border: "1px solid", width: "100%" }}>
                  <thead>
                    <tr className="regular-text-bg border-bottom">
                      <th className="text-center">S. No</th>
                      <th className="text-center">Container No.</th>
                      <th className="text-center">E Seal No.</th>
                      <th className="text-center">Line Seal No.</th>
                      <th className="text-center">Size</th>
                      <th className="text-center">Boxes</th>
                      <th className="text-center">Pallet</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cantValue.map((element, key) => {
                      return (
                        <tr key={key} className="border-bottom">
                          <td className="text-center border-end">{key + 1}</td>
                          <td className="text-center border-end">
                            {element.container_no}
                          </td>
                          <td className="text-center border-end">
                            {element.e_seal_no}
                          </td>
                          <td className="text-center border-end">
                            {element.line_seal_no}
                          </td>
                          <td className="text-center border-end">
                            {formValue.container_size}
                          </td>
                          <td className="text-center border-end">
                            {element.no_of_box}
                          </td>
                          <td className="text-center">
                            {element.no_of_pallet}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="border-bottom">
                      <td colSpan={2} className="text-center border-end">
                        HS Code: {hsnCodes}
                      </td>
                      <td colSpan={3} className="text-center border-end">
                        TOTAL
                      </td>
                      <td className="text-center border-end">
                        {getTotalBox()}
                      </td>
                      <td className="text-center">{getTotalPallet()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  12
                </div>
                <div style={{ width: "95%" }} className="regular-text">
                  One Time stuffing Permission No.: {formValue.permission_no}
                  <br />
                  Still Valid Isuued by by Asstt. commissioner (Tech) of custom
                  - Jamnagar
                  <br />
                  <div className="bold-text">
                    Export Under LUT : File No. : {formValue.file_no} ,{" "}
                    {formValue.supply_meant_for}
                  </div>
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center">
                  13
                </div>
                <div style={{ width: "95%" }} className="regular-text">
                  Examined the Export goods coverd under this Invoice,
                  Description of the Goods, with reference to
                  <br />
                  <div className="bold-text">{formValue.we_shall_claim}</div>
                </div>
              </div>
              <div className="d-flex py-1">
                <div style={{ width: "5%" }} className="text-center"></div>
                <div style={{ width: "25%" }} className="bold-text">
                  For, {userInfo?.name}
                  <br />
                  <img
                    src={userInfo?.signature}
                    style={{
                      maxWidth: "100%",
                      height: "80px",
                    }}
                  />
                  Signature of the Exporter
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 4 && (
        <div className="print-div" ref={contentToPrint}>
          <div className="invoice-print-table">
            <div className="page-title">
              INFORMATION ABOUT VERIFIED GROSS MASS OF CONTAINER
            </div>
            <div>
              <div className="d-flex py-1 border-bottom">
                <div style={{ width: "10%" }} className="text-center bold-text">
                  Sr No.
                </div>
                <div style={{ width: "45%" }} className="text-center bold-text">
                  Details of information
                </div>
                <div style={{ width: "45%" }} className="text-center bold-text">
                  Particulars
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  1*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Name of the shipper
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  {userInfo?.name}
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  2*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Shipper Registration /License no.( IEC No/CIN No)**
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  IEC NO. {userInfo?.iec_code}
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  3*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Name and designation of official of the shipper authorized to
                  sign document
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  PRATIK PATEL / AUTHORISED PERSON
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  4*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  24 x 7 contact details of authorized official of shipper
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  +91 90999 86926
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  5*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Container No.
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  AS PER ATTACHED SHEET
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  6*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Container Size ( TEU/FEU/other)
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  {formValue.total_container} X {formValue.container_size}
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  7*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Maximum permissible weight of container as per the CSC plate
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  AS PER ATTACHED SHEET
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  8*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Weighbridge registration no. & Address of Weighbridge
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  AS PER OUR INVOICE
                  <br />
                  MORBI-(GUJARAT)
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  9*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Verified gross mass of container (method-1/method-2)
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  AS PER ATTACHED SHEET
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  10*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Date and time of weighing
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  {formValue.date}
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  11*
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Weighing slip no.
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  AS PER ATTACHED SHEET
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  12
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  Type (Normal/Reefer/Hazardous/others)
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  NORMAL
                </div>
              </div>
              <div className="d-flex border-bottom">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  13
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  If Hazardous UN NO.IMDG class
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  N/A
                </div>
              </div>
              <div className="d-flex">
                <div
                  style={{ width: "10%" }}
                  className="bold-text text-center border-end"
                >
                  14
                </div>
                <div
                  style={{ width: "45%" }}
                  className="px-2 bold-text border-end"
                >
                  BOOKING NO.
                </div>
                <div style={{ width: "45%" }} className="px-2 bold-text">
                  {formValue.booking_no}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 5 && (
        <div className="print-div" ref={contentToPrint}>
          <div className="invoice-print-table">
            <div className="page-title">As per attachment sheet</div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr className="border-bottom">
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    SR. No.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Booking no.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Container no.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    VGM (KGS)
                    <br />
                    (CARGO + PAACCKING+ TARE WEIGHT)
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    WEIGHING SLIP NO/ TIME
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center"
                  >
                    MAX PERMISSIBLE WEIGHT AS PER CSC
                  </th>
                </tr>
              </thead>
              <tbody>
                {cantValue.map((element, key) => {
                  return (
                    <tr className="border-bottom" key={key}>
                      <td className="bold-text text-center border-end">
                        {key + 1}
                      </td>
                      {key === 0 && (
                        <td
                          style={{ verticalAlign: "middle" }}
                          rowSpan={cantValue.length}
                          className="bold-text text-center border-end"
                        >
                          <div className="p-2 d-flex justify-content-center align-items-center">
                            {formValue.booking_no}
                          </div>
                        </td>
                      )}
                      <td className="bold-text text-center border-end">
                        {element.container_no}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.gross_weight && element.tare_weight ? (
                          <span>
                            {element.extra_gross_weight
                              ? parseFloat(element.gross_weight) +
                                parseFloat(element.extra_gross_weight)
                              : element.gross_weight}{" "}
                            + {element.tare_weight} ={" "}
                            {element.extra_gross_weight
                              ? parseFloat(element.gross_weight) +
                                parseFloat(element.extra_gross_weight) +
                                parseFloat(element.tare_weight)
                              : parseFloat(element.gross_weight) +
                                parseFloat(element.tare_weight)}{" "}
                          </span>
                        ) : null}
                      </td>
                      <td className="bold-text text-center border-end">
                        AS PER SLIP
                      </td>
                      <td className="bold-text text-center">
                        {element.permissible_weight}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex py-1">
              <div style={{ width: "73%" }} className="text-center"></div>
              <div style={{ width: "25%" }} className="bold-text">
                For, {userInfo?.name}
                <br />
                <img
                  src={userInfo?.signature}
                  style={{
                    maxWidth: "100%",
                    height: "80px",
                  }}
                />
                Signature of the Exporter
              </div>
              <div style={{ width: "2%" }} className="text-center"></div>
            </div>
          </div>
        </div>
      )}
      {type === 6 && (
        <div className="print-div" ref={contentToPrint}>
          {isCustomPrint && (
            <div style={{ maxWidth: 300, marginTop: 20, marginBottom: 20 }}>
              <Select
                id="plantName"
                name="plantName"
                options={plants}
                value={plants.find((d) => d.value === plantName) || null}
                styles={selectStyles}
                onChange={(e) => {
                  changePlant(e.value);
                  setPlantName(e.value);
                }}
              />
            </div>
          )}
          <div className="invoice-print-table">
            <table style={{ width: "100%" }}>
              <thead>
                <tr className="border-bottom">
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    SR. No.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Plant
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Transport Name
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Box
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Pallets
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Truck No.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    LR No.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Container No.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    E Seal No.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Line Seal No.
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center border-end"
                  >
                    Net Weight
                  </th>
                  <th
                    style={{ verticalAlign: "middle" }}
                    className="bold-text text-center"
                  >
                    Gross Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                {copyContainers.map((element, key) => {
                  return (
                    <tr className="border-bottom" key={key}>
                      <td className="bold-text text-center border-end">
                        {key + 1}
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className="bold-text text-center border-end"
                      >
                        {getPlantName(element)}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.transport_name}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.no_of_box}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.no_of_pallet}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.truck_no}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.lr_no}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.container_no}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.e_seal_no}
                      </td>
                      <td className="bold-text text-center border-end">
                        {element.line_seal_no}
                      </td>
                      <td className="bold-text text-center border-end">
                        {getNetWeightName(element)}
                      </td>
                      <td className="bold-text text-center">
                        {getGrossWeightName(element)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="border-end" colSpan={10}></td>
                  <td className="bold-text text-center border-end">
                    {getTotalNetWeight()}
                  </td>
                  <td className="bold-text text-center">
                    {getTotalGrossWeight()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewInvoices;
